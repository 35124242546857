<template>
    <div class="main-wrapper">
        <layout-headeradmin></layout-headeradmin>
        <layout-sidebar></layout-sidebar>
        <!-- Page Wrapper -->
        <div class="page-wrapper">
            <div class="content container-fluid">
                
                <!-- Page Header -->
                <div class="page-header">
                    <div class="row">
                        <div class="col">
                            <h3 class="page-title">Mon Compte</h3>
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/admin/index">Tableau de bord</router-link></li>
                                <li class="breadcrumb-item active">Mon Compte</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- /Page Header -->
                
                <div class="row">
                    <div class="col-md-12">
                        <div class="profile-header">
                            <div class="row align-items-center">
                                <div class="col-auto profile-image">
                                    <a href="javascript:;">
                                        <img class="rounded-circle" alt="Image utilisateur" src="@/assets/img/admin/profiles/avatar-12.jpg">
                                    </a>
                                </div>
                                <div class="col ml-md-n2 profile-user-info">
                                    <h4 class="user-name mb-0">{{ user.nom + ' '+ user.prenom}}</h4>
                                    <h6 class="text-muted">{{ user.email }}</h6>
                                    <!-- <div class="pb-3"><i class="fa fa-map-marker"></i> Florida, United States</div> -->
                                    <!-- <div class="about-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div> -->
                                </div>
                                <div class="col-auto profile-btn">
                                </div>
                            </div>
                        </div>
                        <div class="profile-menu">
                            <ul class="nav nav-tabs nav-tabs-solid">
                                <li class="nav-item">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#per_details_tab">Mes informations</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#password_tab">Mot de passe</a>
                                </li>
                            </ul>
                        </div>	
                        <div class="tab-content profile-tab-cont">
                            
                            <!-- Personal Details Tab -->
                            <div class="tab-pane fade show active" id="per_details_tab">
                            
                                <!-- Personal Details -->
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <h5 class="card-title d-flex justify-content-between">
                                                    <span>Mes informations personnelle</span> 
                                                    <a class="edit-link" data-bs-toggle="modal" href="#edit_personal_details"><i class="fa fa-edit mr-1"></i>Modifier</a>
                                                </h5>
                                                <div class="row">
                                                    <p class="col-sm-2 text-muted mb-0 mb-sm-3">Non et prenom</p>
                                                    <p class="col-sm-10">{{ user.nom + ' '+ user.prenom}}</p>
                                                </div>
                                                <div class="row">
                                                    <p class="col-sm-2 text-muted mb-0 mb-sm-3">Date de naissance</p>
                                                    <p class="col-sm-10">{{ user.date_naissance }}</p>
                                                </div>
                                                <div class="row">
                                                    <p class="col-sm-2 text-muted mb-0 mb-sm-3">Email</p>
                                                    <p class="col-sm-10">{{ user.email }}</p>
                                                </div>
                                                <div class="row">
                                                    <p class="col-sm-2 text-muted mb-0 mb-sm-3">Téléphone</p>
                                                    <p class="col-sm-10">{{ user.telephone }}</p>
                                                </div>
                                                <!-- <div class="row">
                                                    <p class="col-sm-2 text-muted mb-0">Address</p>
                                                    <p class="col-sm-10 mb-0">4663  Agriculture Lane,<br>
                                                    Miami,<br>
                                                    Florida - 33165,<br>
                                                    United States.</p>
                                                </div> -->
                                            </div>
                                        </div>
                                    
                                        
                                    </div>

                                
                                </div>
                                <!-- /Personal Details -->

                            </div>
                            <!-- /Personal Details Tab -->
                            
                            <!-- Change Password Tab -->
                            <div id="password_tab" class="tab-pane fade">
                            
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Change Password</h5>
                                        <div class="row">
                                            <div class="col-md-10 col-lg-6">
                                                <form>
                                                    <div class="form-group">
                                                        <label>Old Password</label>
                                                        <input type="password" class="form-control">
                                                    </div>
                                                    <div class="form-group">
                                                        <label>New Password</label>
                                                        <input type="password" class="form-control">
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Confirm Password</label>
                                                        <input type="password" class="form-control">
                                                    </div>
                                                    <button class="btn btn-primary" type="submit">Save Changes</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /Change Password Tab -->
                            
                        </div>
                    </div>
                </div>
            
            </div>			
        </div>
        <!-- /Page Wrapper -->
        	<!-- Edit Details Modal -->
		<div class="modal fade" id="edit_personal_details" aria-hidden="true" role="dialog">
			<div class="modal-dialog modal-dialog-centered" role="document" >
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Information personnelle</h5>
						<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<form>
							<div class="row form-row">
								<div class="col-12 col-sm-6">
									<div class="form-group">
										<label>Nom</label>
										<input type="text" class="form-control" value="Allen">
									</div>
								</div>
								<div class="col-12 col-sm-6">
									<div class="form-group">
										<label>Prénom</label>
										<input type="text"  class="form-control" value="Davis">
									</div>
								</div>
								<div class="col-12">
									<div class="form-group">
										<label>Date de naissance</label>
										<div class="cal-icon">
											<input type="text" class="form-control" value="24-07-1983">
										</div>
									</div>
								</div>
								<div class="col-12 col-sm-6">
									<div class="form-group">
										<label>Email</label>
										<input type="email" class="form-control" value="allendavis@example.com">
									</div>
								</div>
								<div class="col-12 col-sm-6">
									<div class="form-group">
										<label>Téléphone</label>
										<input type="text" value="+1 202-555-0125" class="form-control">
									</div>
								</div>
								<!-- <div class="col-12">
									<h5 class="form-title"><span>Address</span></h5>
								</div>
								<div class="col-12">
									<div class="form-group">
									<label>Address</label>
										<input type="text" class="form-control" value="4663 Agriculture Lane">
									</div>
								</div> -->
								<!-- <div class="col-12 col-sm-6">
									<div class="form-group">
										<label>City</label>
										<input type="text" class="form-control" value="Miami">
									</div>
								</div>
								<div class="col-12 col-sm-6">
									<div class="form-group">
										<label>State</label>
										<input type="text" class="form-control" value="Florida">
									</div>
								</div>
								<div class="col-12 col-sm-6">
									<div class="form-group">
										<label>Zip Code</label>
										<input type="text" class="form-control" value="22434">
									</div>
								</div>
								<div class="col-12 col-sm-6">
									<div class="form-group">
										<label>Country</label>
										<input type="text" class="form-control" value="United States">
									</div>
								</div> -->
							</div>
							<button type="submit" class="btn btn-primary btn-block">Enregistrer</button>
						</form>
					</div>
				</div>
			</div>
		</div>
		<!-- /Edit Details Modal -->
        </div>
</template>
<script setup>
    const user = JSON.parse(localStorage.getItem('userInfo'))
    console.log(user);
</script>