<template>
  <!-- Featured Courses four -->
  <section class="section featured-course-four">
    <div class="container">
      <div class="side-four-right">
        <img src="@/assets/img/img-3.png" alt="" />
      </div>
      <div class="side-four">
        <img src="@/assets/img/img-2.png" alt="" />
      </div>
      <div class="section-header h-four text-center aos" data-aos="fade-up">
        <span>What’s New</span>
        <h2>Featured Courses</h2>
        <p class="sub-title">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget aenean
          accumsan bibendum gravida maecenas augue elementum et neque.
          Suspendisse imperdiet.
        </p>
      </div>
      <div class="featured-course-new aos" data-aos="fade-up">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="featured-course-face">
              <div class="featured-course-design">
                <div class="course-info d-flex align-items-center">
                  <div class="course-bg-left">
                    <img src="@/assets/img/course-bg-1.png" alt="" />
                  </div>
                  <div class="course-bg-right">
                    <img src="@/assets/img/course-bg-2.png" alt="" />
                  </div>
                  <div class="rating rating-star">
                    <i class="fas fa-star filled"></i>
                    <span class="d-inline-block average-rating rate-point"
                      >4.4<span class="rate-star-point">(15)</span></span
                    >
                  </div>
                  <div class="course-price">
                    <h4>$400</h4>
                  </div>
                </div>
                <div class="featured-course-four-head text-center">
                  <h2>
                    <router-link to="/mentee/mentor-search">UI/UX Design</router-link>
                  </h2>
                </div>
                <div class="view-student">
                  <ul class="view-student-four justify-content-center">
                    <li><i class="fas fa-users"></i></li>
                    <li>50 Students</li>
                    <li><i class="fas fa-file-alt"></i></li>
                    <li>85</li>
                  </ul>
                </div>
              </div>
              <div class="course-footer-text text-center">
                <h4>
                  <router-link to="/mentee/mentor-search"
                    >Information About UI/UX Design Degree</router-link
                  >
                </h4>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="featured-course-face">
              <div class="featured-course-design">
                <div class="course-info d-flex align-items-center">
                  <div class="course-bg-left">
                    <img src="@/assets/img/course-bg-1.png" alt="" />
                  </div>
                  <div class="course-bg-right">
                    <img src="@/assets/img/course-bg-2.png" alt="" />
                  </div>
                  <div class="rating rating-star">
                    <i class="fas fa-star filled"></i>
                    <span class="d-inline-block average-rating rate-point"
                      >4.2<span class="rate-star-point">(15)</span></span
                    >
                  </div>
                  <div class="course-price">
                    <h4>Free</h4>
                  </div>
                </div>
                <div class="featured-course-four-head text-center">
                  <h2>
                    <router-link to="/mentee/mentor-search">Photography</router-link>
                  </h2>
                </div>
                <div class="view-student">
                  <ul class="view-student-four justify-content-center">
                    <li><i class="fas fa-users"></i></li>
                    <li>40 Students</li>
                    <li><i class="fas fa-file-alt"></i></li>
                    <li>65</li>
                  </ul>
                </div>
              </div>
              <div class="course-footer-text text-center">
                <h4>
                  <router-link to="/mentee/mentor-search"
                    >Photography Crash Course for Photographer</router-link
                  >
                </h4>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="featured-course-face">
              <div class="featured-course-design">
                <div class="course-info d-flex align-items-center">
                  <div class="course-bg-left">
                    <img src="@/assets/img/course-bg-1.png" alt="" />
                  </div>
                  <div class="course-bg-right">
                    <img src="@/assets/img/course-bg-2.png" alt="" />
                  </div>
                  <div class="rating rating-star">
                    <i class="fas fa-star filled"></i>
                    <span class="d-inline-block average-rating rate-point"
                      >3.4<span class="rate-star-point">(15)</span></span
                    >
                  </div>
                  <div class="course-price">
                    <h4>$200</h4>
                  </div>
                </div>
                <div class="featured-course-four-head text-center">
                  <h2>
                    <router-link to="/mentee/mentor-search">React </router-link>
                  </h2>
                </div>
                <div class="view-student">
                  <ul class="view-student-four justify-content-center">
                    <li><i class="fas fa-users"></i></li>
                    <li>30 Students</li>
                    <li><i class="fas fa-file-alt"></i></li>
                    <li>75</li>
                  </ul>
                </div>
              </div>
              <div class="course-footer-text text-center">
                <h4>
                  <router-link to="/mentee/mentor-search"
                    >React – The Complete Guide (React Router)
                  </router-link>
                </h4>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="featured-course-face">
              <div class="featured-course-design">
                <div class="course-info d-flex align-items-center">
                  <div class="course-bg-left">
                    <img src="@/assets/img/course-bg-1.png" alt="" />
                  </div>
                  <div class="course-bg-right">
                    <img src="@/assets/img/course-bg-2.png" alt="" />
                  </div>
                  <div class="rating rating-star">
                    <i class="fas fa-star filled"></i>
                    <span class="d-inline-block average-rating rate-point"
                      >4.3<span class="rate-star-point">(15)</span></span
                    >
                  </div>
                  <div class="course-price">
                    <h4>$100</h4>
                  </div>
                </div>
                <div class="featured-course-four-head text-center">
                  <h2>
                    <router-link to="/mentee/mentor-search">Marketing</router-link>
                  </h2>
                </div>
                <div class="view-student">
                  <ul class="view-student-four justify-content-center">
                    <li><i class="fas fa-users"></i></li>
                    <li>40 Students</li>
                    <li><i class="fas fa-file-alt"></i></li>
                    <li>80</li>
                  </ul>
                </div>
              </div>
              <div class="course-footer-text text-center">
                <h4>
                  <router-link to="/mentee/mentor-search"
                    >Email & Affiliate Marketing Mastermind</router-link
                  >
                </h4>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="featured-course-face">
              <div class="featured-course-design">
                <div class="course-info d-flex align-items-center">
                  <div class="course-bg-left">
                    <img src="@/assets/img/course-bg-1.png" alt="" />
                  </div>
                  <div class="course-bg-right">
                    <img src="@/assets/img/course-bg-2.png" alt="" />
                  </div>
                  <div class="rating rating-star">
                    <i class="fas fa-star filled"></i>
                    <span class="d-inline-block average-rating rate-point"
                      >4.5<span class="rate-star-point">(15)</span></span
                    >
                  </div>
                  <div class="course-price">
                    <h4>Free</h4>
                  </div>
                </div>
                <div class="featured-course-four-head text-center">
                  <h2>
                    <router-link to="/mentee/mentor-search">Java</router-link>
                  </h2>
                </div>
                <div class="view-student">
                  <ul class="view-student-four justify-content-center">
                    <li><i class="fas fa-users"></i></li>
                    <li>50 Students</li>
                    <li><i class="fas fa-file-alt"></i></li>
                    <li>90</li>
                  </ul>
                </div>
              </div>
              <div class="course-footer-text text-center">
                <h4>
                  <router-link to="/mentee/mentor-search"
                    >Java (Beginner) Programming Tutorials</router-link
                  >
                </h4>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="featured-course-face">
              <div class="featured-course-design">
                <div class="course-info d-flex align-items-center">
                  <div class="course-bg-left">
                    <img src="@/assets/img/course-bg-1.png" alt="" />
                  </div>
                  <div class="course-bg-right">
                    <img src="@/assets/img/course-bg-2.png" alt="" />
                  </div>
                  <div class="rating rating-star">
                    <i class="fas fa-star filled"></i>
                    <span class="d-inline-block average-rating rate-point"
                      >4.4<span class="rate-star-point">(15)</span></span
                    >
                  </div>
                  <div class="course-price">
                    <h4>$400</h4>
                  </div>
                </div>
                <div class="featured-course-four-head text-center">
                  <h2>
                    <router-link to="/mentee/mentor-search">Python</router-link>
                  </h2>
                </div>
                <div class="view-student">
                  <ul class="view-student-four justify-content-center">
                    <li><i class="fas fa-users"></i></li>
                    <li>50 Students</li>
                    <li><i class="fas fa-file-alt"></i></li>
                    <li>85</li>
                  </ul>
                </div>
              </div>
              <div class="course-footer-text text-center">
                <h4>
                  <router-link to="/mentee/mentor-search"
                    >Complete Python Bootcamp: Go from zero to
                    hero.</router-link
                  >
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="explore-more text-center aos" data-aos="fade-up">
        <router-link to="/mentee/mentor-search" class="btn bg-explore"
          >Show All Categories</router-link
        >
      </div>
    </div>
  </section>
  <!-- /Featured Courses four -->
</template>
