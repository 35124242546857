<template>
    <!-- Tags -->
        <div class="card tags-widget">
            <div class="card-header">
                <h4 class="card-title">Tags</h4>
            </div>
            <div class="card-body">
                <ul class="tags">
                    <li><a href="javascript:;" class="tag">HTML</a></li>
                    <li><a href="javascript:;" class="tag">Css</a></li>
                    <li><a href="javascript:;" class="tag">Java Script</a></li>
                    <li><a href="javascript:;" class="tag">Jquery</a></li>
                    <li><a href="javascript:;" class="tag">Wordpress</a></li>
                    <li><a href="javascript:;" class="tag">Php</a></li>
                    <li><a href="javascript:;" class="tag">Angular js</a></li>
                    <li><a href="javascript:;" class="tag">React js</a></li>
                    <li><a href="javascript:;" class="tag">Vue js</a></li>
                    <li><a href="javascript:;" class="tag">Photoshop</a></li>
                    <li><a href="javascript:;" class="tag">Ajax</a></li>
                    <li><a href="javascript:;" class="tag">Json</a></li>
                    <li><a href="javascript:;" class="tag">C</a></li>
                    <li><a href="javascript:;" class="tag">C++</a></li>
                    <li><a href="javascript:;" class="tag">Vb</a></li>
                    <li><a href="javascript:;" class="tag">Vb.net</a></li>
                    <li><a href="javascript:;" class="tag">Asp.net</a></li>
                </ul>
            </div>
        </div>
    <!-- /Tags -->
</template>