<template>
  <div class="call-page">
    <div class="main-wrapper">
      <layout-header1></layout-header1>
      <div class="content">
        <div class="container-fluid">
          <div class="call-wrapper">
            <div class="call-main-row">
              <div class="call-main-wrapper">
                <div class="call-view">
                  <div class="call-window">
                    <div class="fixed-header">
                      <div class="navbar">
                        <div class="user-details">
                          <div class="float-start user-img">
                            <router-link
                              class="avatar avatar-sm me-2"
                              to="/mentee/mentor-profile"
                              title="Jonathan Doe"
                            >
                              <img
                                src="@/assets/img/website/user/user.jpg"
                                alt="User Image"
                                class="rounded-circle"
                              />
                              <span class="status online"></span>
                            </router-link>
                          </div>
                          <div class="user-info float-start">
                            <router-link to="/mentee/mentor-profile">
                              <span>Jonathan Doe</span>
                            </router-link>
                            <span class="last-seen">Online</span>
                          </div>
                        </div>
                        <ul class="nav float-end custom-menu">
                          <li class="nav-item dropdown dropdown-action">
                            <a
                              href="javascript:;"
                              class="nav-link dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i class="fa fa-cog"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a href="javascript:void(0)" class="dropdown-item">Settings</a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="call-contents">
                      <div class="call-content-wrap">
                        <div class="user-video">
                          <video
                            id="local-video"
                            ref="localVideo"
                            autoplay
                            playsinline
                            v-show="!showLoader && videoEnabled"
                          ></video>
                          <div v-if="showLoader" class="loader-overlay">
                            <div class="loader"></div>
                          </div>
                        </div>
                        <!-- <div class="my-video">
                          <ul>
                            <li>
                              <div class="participant-initials">JD</div>
                            </li>
                            <li>
                              <div class="participant-initials">AB</div>
                            </li>
                          </ul>
                        </div> -->
                        
                        <div class="my-video">
                          <ul>
                            <li>
                              <img
                                src="@/assets/img/website/user/user.jpg"
                                class="img-fluid"
                                alt="User Image"
                              />
                            </li>
                            <li>
                              <img
                                src="@/assets/img/website/user/user.jpg"
                                class="img-fluid"
                                alt="User Image"
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="call-footer">
                      <div class="call-icons">
                        <span class="call-duration">{{ callDuration }}</span>
                        <ul class="call-items">
                          <li class="call-item" v-show="!videoEnabled">
                            <a
                              href="#"
                              title="Démarrer la vidéo"
                              data-placement="top"
                              data-bs-toggle="tooltip"
                              @click.prevent="startCall"
                            >
                              <i class="fas fa-play camera"></i>
                            </a>
                          </li>
                          <li class="call-item">
                            <a
                              href="#"
                              title="Enregistrer la vidéo"
                              data-placement="top"
                              data-bs-toggle="tooltip"
                              @click.prevent="recordVideo"
                            >
                              <i class="fas fa-file-video"></i>
                            </a>
                          </li>
                          <li class="call-item">
                            <a
                              href="#"
                              title="Dessiner sur l'écran"
                              data-placement="top"
                              data-bs-toggle="tooltip"
                              @click.prevent="startAnnotationVideo"
                            >
                              <i class="fas fa-file-edit"></i>
                            </a>
                          </li>
                          <li class="call-item">
                            <a
                              href="#"
                              title="Activez / Désactivez la caméra video"
                              data-placement="top"
                              data-bs-toggle="tooltip"
                              @click.prevent="toggleVideo"
                            >
                              <i
                                :class="{
                                  'fas fa-video camera': videoEnabled,
                                  'fas fa-video-slash camera': !videoEnabled
                                }"
                              ></i>
                            </a>
                          </li>
                          <li class="call-item">
                            <a
                              href="#"
                              title="Fermer le micro"
                              data-placement="top"
                              data-bs-toggle="tooltip"
                              @click.prevent="toggleAudio"
                            >
                              <i
                                :class="{
                                  'fa fa-microphone microphone': audioEnabled,
                                  'fa fa-microphone-slash microphone': !audioEnabled
                                }"
                              ></i>
                            </a>
                          </li>
                          <li class="call-item">
                            <a
                              href=""
                              title="Inviter un utilisateur"
                              data-placement="top"
                              data-bs-toggle="tooltip"
                            >
                              <i class="fa fa-user-plus"></i>
                            </a>
                          </li>
                          <li class="call-item">
                            <a
                              href=""
                              title="Agrandir en plein écran"
                              data-placement="top"
                              data-bs-toggle="tooltip"
                              @click.prevent="toggleFullScreen"
                            >
                              <i class="fas fa-expand full-screen"></i>
                            </a>
                          </li>
                          <li class="call-item">
                            <a
                              href=""
                              title="Zoom l'écran"
                              data-placement="top"
                              data-bs-toggle="tooltip"
                              @click.prevent="zoomVideo"
                            >
                              <i class="fas fa-magnifying-glass"></i>
                            </a>
                          </li>
                          <li class="call-item">
                            <a
                              href=""
                              title="Tourner la caméra"
                              data-placement="top"
                              data-bs-toggle="tooltip"
                              @click.prevent="rotateCamera"
                            >
                              <i class="fas fa-arrows-rotate"></i>
                            </a>
                          </li>
                        </ul>
                        <div class="end-call">
                          <a href="#" @click.prevent="endCall">
                            <i class="material-icons">call_end</i>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div v-if="isFullScreen" class="full-screen-overlay">
                      <button @click="toggleFullScreen" class="close-full-screen">×</button>
                      <video
                        id="full-screen-video"
                        ref="fullScreenVideo"
                        autoplay
                        playsinline
                      ></video>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import io from 'socket.io-client';
import Peer from 'simple-peer';

export default {
  data() {
    return {
      videoEnabled: false,
      audioEnabled: true,
      callDuration: '00:00',
      localStream: null,
      remoteStream: null,
      callTimer: null,
      showLoader: true,
      isFullScreen: false,
      zoomLevel: 1,
      currentCamera: 'user',
      socket: null,
      peer: null,
    };
  },
  mounted() {
    this.initSocket();
  },
  methods: {
    initSocket() {
      this.socket = io('http://localhost:3000');
      this.socket.on('connect', () => {
        console.log('Connecté au serveur socket.io');
        this.initializeWebRTC();
      });
      this.socket.on('webrtc-signal', (data) => {
        if (this.peer) {
          this.peer.signal(data);
        }
      });
    },
    initializeWebRTC() {
      this.socket.emit('initiate-webrtc', this.socket.id);
    },
    async initLocalVideo() {
      try {
        this.localStream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: this.currentCamera },
          audio: true,
        });
        this.$refs.localVideo.srcObject = this.localStream;
        this.showLoader = false;

        this.peer = new Peer({
          initiator: true,
          trickle: false,
          stream: this.localStream,
        });

        this.peer.on('signal', (data) => {
          this.socket.emit('webrtc-signal', data);
        });

        this.peer.on('stream', (stream) => {
          this.remoteStream = stream;
          // Ajouter le code pour afficher le flux vidéo distant
        });
      } catch (error) {
        console.error('Error accessing media devices.', error);
      }
    },
    startCall() {
      this.showLoader = true;
      this.initLocalVideo();
      this.startCallTimer();
      this.videoEnabled = true;
    },
    toggleVideo() {
      if (this.localStream) {
        this.localStream.getVideoTracks().forEach((track) => (track.enabled = !track.enabled));
        this.videoEnabled = !this.videoEnabled;
      }
    },
    toggleAudio() {
      if (this.localStream) {
        this.localStream.getAudioTracks().forEach((track) => (track.enabled = !track.enabled));
        this.audioEnabled = !this.audioEnabled;
      }
    },
    zoomVideo() {
      this.zoomLevel = this.zoomLevel === 1 ? 1.5 : 1;
      this.$refs.localVideo.style.transform = `scale(${this.zoomLevel})`;
    },
    rotateCamera() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        this.currentCamera = this.currentCamera === 'user' ? 'environment' : 'user';
        this.initLocalVideo();
      }
    },
    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
      if (this.isFullScreen) {
        this.$refs.fullScreenVideo.srcObject = this.localStream;
      } else {
        this.$refs.localVideo.srcObject = this.localStream;
      }
    },
    startCallTimer() {
      let seconds = 0;
      let minutes = 0;
      this.callTimer = setInterval(() => {
        seconds++;
        if (seconds >= 60) {
          seconds = 0;
          minutes++;
        }
        this.callDuration = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      }, 1000);
    },
    stopCallTimer() {
      clearInterval(this.callTimer);
      this.callTimer = null;
    },
    endCall() {
      this.closeStreams();
      this.stopCallTimer();
      this.showLoader = false;
      this.videoEnabled = false;
      this.$router.push('/pages/video-call');
    },
    closeStreams() {
      if (this.localStream) {
        this.localStream.getTracks().forEach((track) => track.stop());
      }
      if (this.remoteStream) {
        this.remoteStream.getTracks().forEach((track) => track.stop());
      }
      if (this.peer) {
        this.peer.destroy();
      }
      this.socket.disconnect();
    },
  },
};
</script>
<style scoped>
.user-video {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1500px;
  background: black;
  overflow: hidden;
}
.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
.my-video {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.my-video ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.my-video li {
  margin-bottom: 10px;
}
.participant-initials {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: blue;
  color: white;
  font-size: 24px;
  font-weight: bold;
  border-radius: 50%;
}
.fixed-header, .call-footer {
  background: white;
}
.call-icons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.call-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 10px;
}
.call-item a {
  color: black;
  font-size: 24px;
}
/* .end-call a {
  color: red;
  font-size: 24px;
} */
.full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.full-screen-overlay video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.close-full-screen {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  z-index: 10000;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>

