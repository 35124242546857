<template>
  <button @click="createCall" class="btn view-btn">Appeler</button>
</template>

<script>
import { io } from 'socket.io-client';

const socket = io('http://192.168.1.66:3000');

export default {
  methods: {
    createCall() {
      const callerId = Math.random().toString(36).substring(7);
      socket.emit('initiateCall', { callerId });
      this.$router.push({ name: 'CallInterface', params: { callerId } });
    }
  }
};
</script>
