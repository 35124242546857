<template>
  <!-- New Course subscribe -->
  <section class="new-course great-option">
    <div class="bg-img">
      <img
        src="@/assets/img/great-option-01.png"
        class="img-fluid bg-option"
        alt=""
      />
      <img
        src="@/assets/img/great-option-02.png"
        class="img-fluid bg-option-two"
        alt=""
      />
    </div>
    <div class="container">
      <div class="head-join-us">
        <div class="row">
          <div class="col-lg-12">
            <div class="great-option-three text-center">
              <h2>Find Your Next Great Opportunity!</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                vitae neque metus. Vivamus consectetur ultricies
              </p>
              <div class="reg-log-btn">
                <router-link to="/pages/register" class="reg-btn"
                  >Register Now</router-link
                >
                <router-link to="/pages/login" class="log-btn">Sign In</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /New Course -->
</template>
