<template>
  <!-- Faq -->
  <section class="faq-four-sec faq-five-sec">
    <div class="container">
      <div class="faq-sec-five sec-six-faq pt-0">
        <div class="heder-eight aos" data-aos="fade-up">
          <div class="section-head-eight">
            <h2>Frequently Asked <span>Questions</span></h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
              aenean accumsan bibendum
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-md-12">
                <div class="faq-card">
                  <div
                    class="panel-group"
                    id="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div class="faq-tab">
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingOne">
                          <p class="panel-title">
                            <a
                              class="collapsed"
                              data-bs-toggle="collapse"
                              data-bs-parent="#accordion"
                              href="#collapseOne"
                              aria-expanded="false"
                              aria-controls="collapseOne"
                            >
                              <span>01</span> Is the regular license the same
                              thing as an editorial license?
                            </a>
                          </p>
                        </div>
                        <div
                          id="collapseOne"
                          class="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordion"
                        >
                          <p>
                            Do you want to move on next step? Choose your most
                            popular leaning mentors, it will help you to achieve
                            your professional goals.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="faq-tab">
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingTwo">
                          <p class="panel-title">
                            <a
                              class="collapsed"
                              data-bs-toggle="collapse"
                              data-bs-parent="#accordion"
                              href="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              <span>02</span>Is the regular license the same
                              thing as an editorial license?
                            </a>
                          </p>
                        </div>
                        <div
                          id="collapseTwo"
                          class="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordion"
                        >
                          <p>
                            Do you want to move on next step? Choose your most
                            popular leaning mentors, it will help you to achieve
                            your professional goals.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="faq-tab">
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingThree">
                          <p class="panel-title">
                            <a
                              class="collapsed"
                              data-bs-toggle="collapse"
                              data-bs-parent="#accordion"
                              href="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              <span>03</span>Is the regular license the same
                              thing as an editorial license?
                            </a>
                          </p>
                        </div>
                        <div
                          id="collapseThree"
                          class="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordion"
                        >
                          <p>
                            Do you want to move on next step? Choose your most
                            popular leaning mentors, it will help you to achieve
                            your professional goals.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="faq-tab">
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFour">
                          <p class="panel-title">
                            <a
                              class="collapsed"
                              data-bs-toggle="collapse"
                              data-bs-parent="#accordion"
                              href="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              <span>04</span>Is the regular license the same
                              thing as an editorial license?
                            </a>
                          </p>
                        </div>
                        <div
                          id="collapseFour"
                          class="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordion"
                        >
                          <p>
                            Do you want to move on next step? Choose your most
                            popular leaning mentors, it will help you to achieve
                            your professional goals.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Faq -->
</template>
