<template>
  <div class="call-page">
    <div class="main-wrapper">
      <layout-header1></layout-header1>
      <div class="content">
        <div class="container-fluid">
          <div class="call-wrapper">
            <div class="call-main-row">
              <div class="call-main-wrapper">
                <div class="call-view" ref="callView">
                  <div ref="jitsiContainer" id="jitsi-container" ></div>
                  <div class="canvas-container" ref="canvasContainer">
                  <canvas
                    id="annotation-canvas"
                    ref="annotationCanvas"
                    ></canvas>
                  </div>
                  <button
                    v-if="isMeetingActive"
                    ref="toggleAnnotationBtn"
                    @click="toggleDrawingMode"
                    id="toggle-annotation-btn"
                  >
                    Activer les annotations
                  </button>
                  <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_BASE_URL } from '@/config';
import { io } from 'socket.io-client';

export default {
  props: {
    domain: {
      type: String,
      default: 'www.kuyela.com'
    },
  },
  data() {
    return {
      jitsiApi: null,
      canvas: null,
      socket: null,
      isMeetingActive: true,
      errorMessage: '',
    };
  },
  mounted() {
    console.log('Component mounté');
    this.loadScripts()
      .then(() => {
        console.log('Les scripts sont chargés');
        if (!window.JitsiMeetExternalAPI) throw new Error('Jitsi Meet External API not loaded');
        if (!window.fabric) throw new Error('Fabric.js not loaded');
        this.$nextTick(() => {
          console.log('Coche suivante, intégration du widget Jitsi');
          this.embedJitsiWidget();
          this.initFabricCanvas();
          this.initSocket();
          window.addEventListener('resize', this.resizeCanvas);
          this.resizeCanvas();
        });
      })
      .catch((error) => {
        console.error('Erreur lors du chargement des scripts :', error);
        this.errorMessage = 'Erreur lors du chargement des scripts nécessaires. Veuillez vérifier votre connexion réseau.';
      });
      this.replaceVideoTrackInJitsi();

  },
  beforeUnmount() {
    // this.removeJitsiWidget();
    if (this.socket) this.socket.disconnect();
    window.removeEventListener('resize', this.resizeCanvas);
    console.log('Composant avant démontage');
  },
  methods: {
    loadScripts() {
      return Promise.all([
        this.loadScript(`https://${this.domain}/external_api.js`),
        this.loadScript('https://cdnjs.cloudflare.com/ajax/libs/fabric.js/4.5.0/fabric.min.js'),
      ]).then(() => {
        console.log('Tous les scripts chargés');
      }).catch((error) => {
        console.error('Erreur lors du chargement des scripts:', error);
      });
    },
    loadScript(src) {
      return new Promise((resolve, reject) => {
        const scriptEl = document.createElement('script');
        scriptEl.src = src;
        scriptEl.async = true;
        scriptEl.onload = resolve;
        scriptEl.onerror = reject;
        document.head.appendChild(scriptEl);
        console.log(`Loading script: ${src}`);
      });
    },
    embedJitsiWidget() {
      const options = {
        roomName: this.$route.params.roomName,
        parentNode: this.$refs.jitsiContainer,
      };
      this.jitsiApi = new window.JitsiMeetExternalAPI(this.domain, options);
      this.jitsiApi.addEventListener('videoConferenceJoined', () => {
        this.captureVideoStream();
      });
      this.jitsiApi.addEventListener('error', this.handleJitsiError);
      console.log('Widget Jitsi intégré');
    },

    handleJitsiError(error) {
      console.error('Jitsi error:', error);
      this.errorMessage = `Jitsi error: ${error}`;
    },
    onMeetingStarted() {
      console.log('Meeting started');
      this.isMeetingActive = true;
      this.initFabricCanvas();
      this.socket.emit('meetingStarted');
    },
    onMeetingEnded() {
      console.log('Meeting ended');
      this.isMeetingActive = false;
      if (this.canvas) {
        this.canvas.isDrawingMode = false;
        this.canvas.clear();
      }
      const toggleButton = this.$refs.toggleAnnotationBtn;
      if (toggleButton) {
        toggleButton.textContent = 'Activer les annotations';
      }
      this.socket.emit('meetingEnded');
    },
    initSocket() {
      console.log('Initializing socket');
      this.socket = io(API_BASE_URL);
      this.socket.on('connect', () => {
        console.log('Connecté au serveur Socket.io');
      });
      this.socket.on('drawing', (data) => {
        this.handleData(data);
      });
      this.socket.on('meetingStarted', () => {
        console.log('Meeting started (socket event)');
        this.isMeetingActive = true;
        this.initFabricCanvas();
      });
      this.socket.on('meetingEnded', () => {
        console.log('Meeting ended (socket event)');
        this.isMeetingActive = false;
        if (this.canvas) {
          this.canvas.isDrawingMode = false;
          this.canvas.clear();
        }
        const toggleButton = this.$refs.toggleAnnotationBtn;
        if (toggleButton) {
          toggleButton.textContent = 'Activer les annotations';
        }
      });
      this.socket.on('message', (data) => {
        const message = JSON.parse(data);
        console.log(message.message);
      });
      this.socket.on('error', (error) => {
        console.error('Socket.io error:', error);
        this.errorMessage = `Socket.io error: ${error}`;
      });
    },
    initFabricCanvas() {
      this.$nextTick(() => {
        const canvasElement = this.$refs.annotationCanvas;

        if (!canvasElement) {
          console.error('Élément de canevas introuvable');
          return;
        }
        console.log('Initialisation du canevas Fabric');
        this.canvas = new window.fabric.Canvas(canvasElement);
        this.canvas.isDrawingMode = true;
        this.canvas.getElement().style.pointerEvents = 'none';
        console.log('Fabric canvas initialisée');

        this.canvas.on('path:created', (event) => {
          const path = event.path.toObject(['left', 'top', 'width', 'height', 'path']);

          // Normalisation des coordonnées du dessin
          const normalizedPath = {
            path: path.path.map(point => ({
              x: point[1] / this.canvas.width,
              y: point[2] / this.canvas.height,
            })),
            left: path.left / this.canvas.width,
            top: path.top / this.canvas.height,
            width: path.width / this.canvas.width,
            height: path.height / this.canvas.height,
            canvasWidth: this.canvas.width,
            canvasHeight: this.canvas.height,
          };

          // Afficher les coordonnées dans la console
          console.log(`Objet ID: ${'Avant la normalisation'}`);
          console.log(`Position: (${path.left.toFixed(2)}, ${path.top.toFixed(2)})`);
          console.log(`Dimensions: ${path.width.toFixed(2)} x ${path.height.toFixed(2)}`);

          // const data = {
          //   path: path.toObject(),
          // };

          //this.socket.emit('drawing', normalizedPath);
          //console.log('Chemin créé et envoyé via socket avec normalisation');
        });
      });
    },
    
    async captureVideoStream() {
      setTimeout(() => {
        try {
          // Accéder à l'iframe via ref
          const jitsiIframe = this.$refs.jitsiContainer.querySelector('iframe');
          console.log(jitsiIframe);

          // Vérifier si l'iframe est bien chargée
          if (!jitsiIframe || !jitsiIframe.contentWindow || !jitsiIframe.contentWindow.document) {
            console.error('L\'iframe Jitsi Meet n\'est pas disponible.');
            return;
          }

          // Accéder au document de l'iframe
          const iframeDocument = jitsiIframe.contentWindow.document;

          // Sélectionner l'élément vidéo à l'intérieur de l'iframe
          const videoElement = iframeDocument.querySelector('video');

          if (videoElement) {
            console.log('Élément vidéo trouvé:', videoElement);
            return videoElement;
          } else {
            console.log('Aucun élément vidéo trouvé dans l\'iframe.');
          }
        } catch (error) {
          console.error('Erreur lors de l\'accès à l\'élément vidéo:', error);
        }
    
      }, 5000); // Attente de 3 secondes
    },
    async captureAnnotatedVideo() {
      try {
          const screenStream = await navigator.mediaDevices.getDisplayMedia({
              video: true,
              audio: false
          });
          return screenStream;
      } catch (err) {
          console.error("Erreur lors de la capture de l'écran : ", err);
          return null;
      }
    },

    async getAnnotatedVideoTrack() {
      const annotatedStream = await captureAnnotatedVideo();
      return annotatedStream ? annotatedStream.getVideoTracks()[0] : null;
    },

    async replaceVideoTrackInJitsi() {
        const newTrack = await getAnnotatedVideoTrack();
        console.log('newTrack', newTrack);
        
        
        if (newTrack) {
            api.getIFrame().contentWindow.JitsiMeetJS.app.conference.getLocalTracks().then(localTracks => {
                const videoTrack = localTracks.find(track => track.getType() === 'video');

                if (videoTrack) {
                    videoTrack.replaceTrack(newTrack);
                } else {
                    console.error("Aucune piste vidéo trouvée à remplacer.");
                }
            });
        } else {
            console.error("Impossible d'obtenir la piste vidéo annotée.");
        }
    },

    drawVideoToCanvas() {
      const ctx = this.$refs.annotationCanvas.getContext('2d');
      ctx.drawImage(video, 0, 0, this.canvas.width, this.canvas.height);
      requestAnimationFrame(draw);
    },
    handleData(data) {
      if (this.canvas && data.path) {
        const scaleX = this.canvas.width / data.canvasWidth;
        const scaleY = this.canvas.height / data.canvasHeight;

        // Dénormalisation des coordonnées du dessin
        const denormalizedPath = {
          path: data.path.map(point => ({
            x: point.x * this.canvas.width,
            y: point.y * this.canvas.height,
          })),
          left: data.left * this.canvas.width,
          top: data.top * this.canvas.height,
          width: data.width * this.canvas.width,
          height: data.height * this.canvas.height,
        };

        // Reconstitution de l'objet de dessin
        const fabricPath = new window.fabric.Path(denormalizedPath.path.map(point => `L ${point.x} ${point.y}`).join(' '), {
          left: denormalizedPath.left,
          top: denormalizedPath.top,
          width: denormalizedPath.width,
          height: denormalizedPath.height,
        });

        // Afficher les coordonnées dans la console
        console.log(`Objet ID: ${'Apres la denormalisation'}`);
        console.log(`Position: (${fabricPath.left.toFixed(2)}, ${fabricPath.top.toFixed(2)})`);
        console.log(`Dimensions: ${fabricPath.width.toFixed(2)} x ${fabricPath.height.toFixed(2)}`);

        this.canvas.add(fabricPath);
        this.canvas.renderAll();
        console.log('Données traitées et ajoutées au canevas avec dénormalisation');
      }
    },
    resizeCanvas() {
      this.$nextTick(() => {
        const container = this.$refs.callView;
        const videoElement = this.$refs.jitsiContainer;
        const annotationCanvas = this.$refs.annotationCanvas;
        console.log(videoElement);
        console.log(annotationCanvas);


        if (this.canvas && container && annotationCanvas) {
            // Obtenez les nouvelles dimensions de la vidéo
        const newWidth = videoElement.clientWidth;
        const newHeight = videoElement.clientHeight;

        // Obtenez les dimensions actuelles du canevas
        const currentWidth = this.canvas.getWidth();
        const currentHeight = this.canvas.getHeight();

        // Calculez les ratios de redimensionnement
        const widthRatio = newWidth / currentWidth;
        const heightRatio = newHeight / currentHeight;


        

        // Redimensionnez le canevas
        annotationCanvas.style.width = `${newWidth}px`;
        annotationCanvas.style.height = `${newHeight}px`;
        this.canvas.setWidth(newWidth);
        this.canvas.setHeight(newHeight);

        // Redimensionnez et repositionnez les objets existants sur le canevas
        this.resizeCanvasObjects(widthRatio, heightRatio);

        this.canvas.renderAll();
      }
      });
    },
    resizeCanvasObjects(widthRatio, heightRatio) {
      this.canvas.getObjects().forEach((obj) => {
        // Utilisez le ratio le plus petit pour conserver les proportions des objets
        const scaleRatio = Math.min(widthRatio, heightRatio);
        
        // Ajustez la taille des objets
        obj.set({
          scaleX: obj.scaleX * widthRatio,
          scaleY: obj.scaleY * heightRatio,
          width: obj.width * widthRatio,
          height: obj.height * heightRatio,
        });

        // Ajustez la position des objets
        obj.set({
          left: obj.left * widthRatio,
          top: obj.top * heightRatio,
        });

        obj.setCoords(); // Met à jour les coordonnées de l'objet

        // Afficher les coordonnées dans la console
        console.log(`Objet ID: ${obj.id || 'Apres rezise'}`);
        console.log(`Position: (${obj.left.toFixed(2)}, ${obj.top.toFixed(2)})`);
        console.log(`Dimensions: ${obj.width.toFixed(2)} x ${obj.height.toFixed(2)}`);
        console.log(`ScaleX: ${scaleRatio.toFixed(2)}, ScaleY: ${scaleRatio.toFixed(2)}`);
      });
    },
    // Pour ajouter un objet sur le canevas
    addObjectToCanvas(obj) {
      const videoElement = this.$refs.jitsiContainer;

      // Obtenez les dimensions du canevas
      const canvasWidth = this.canvas.getWidth();
      const canvasHeight = this.canvas.getHeight();

      // Calculer les coordonnées normalisées
      const normalizedLeft = (obj.left / videoElement.clientWidth) * canvasWidth;
      const normalizedTop = (obj.top / videoElement.clientHeight) * canvasHeight;

      // Afficher les coordonnées de l'objet dans la console
      console.log(`Objet ajouté ID: ${fabricObj.id || 'Non défini'}`);
      console.log(`Position: (${fabricObj.left.toFixed(2)}, ${fabricObj.top.toFixed(2)})`);
      console.log(`Dimensions: ${fabricObj.width.toFixed(2)} x ${fabricObj.height.toFixed(2)}`);

      // Ajouter l'objet au canevas avec les coordonnées normalisées
      this.canvas.add(new window.fabric.Object({
        ...obj,
        left: normalizedLeft,
        top: normalizedTop,
      }));
    },
    toggleDrawingMode() {
      if (this.canvas) {
        this.canvas.isDrawingMode = !this.canvas.isDrawingMode;
        const canvasContainer = this.$refs.canvasContainer;
        if (canvasContainer) {
          canvasContainer.style.pointerEvents = this.canvas.isDrawingMode ? 'auto' : 'none';
        }
        const toggleButton = this.$refs.toggleAnnotationBtn;
        if (toggleButton) {
          toggleButton.textContent = this.canvas.isDrawingMode ? 'Désactiver les annotations' : 'Activer les annotations';
        }
        console.log(`Mode dessin activé: ${this.canvas.isDrawingMode}`);
      } else {
        console.error("Le canevas n'est pas initialisé");
      }
    },
    captureCanvasStream() {
      const canvasStream = this.$refs.annotationCanvas.captureStream(30);
      return canvasStream;
    },
    async addCustomVideoTrackToJitsi() {
      const canvasStream = this.captureCanvasStream();

      const videoTrack = canvasStream.getVideoTracks()

      // const videoTrack = await JitsiMeetJS.creatLocalTracks({
      //   videoStream: canvasStream,
      //   constraints: {
      //     video: true,
      //   }
      // });

      // Ajoutz le track video à la conférence Jitsi
      this.jitsiApi.executeCommand('replaceParticipantVideoTrack', videoTrack[0]);
    }
  },
};
</script>

<style scoped>
.call-view {
  position: relative;
  width: 100%;
  height: 600px;
}

#jitsi-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.canvas-container {
  pointer-events: none;
}

#annotation-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  /* pointer-events: none;  */
}

#toggle-annotation-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1000;
}
</style>