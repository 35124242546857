<template>
  <div class="main-wrapper main-wrapper-seven">
    <layout-header-seven />
    <HomeBannerSeven />
    <WorkSeven />
    <MentorSeven />
    <PathSeven />
    <ProfileSeven />
    <TestimonialSeven />
    <StatisticsSeven />
    <BlogSeven />
    <FaqSeven />
    <layout-footer-seven />
  </div>
</template>
