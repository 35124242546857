<template>
  <div class="main-wrapper">
    <layout-headeradmin></layout-headeradmin>
    <layout-sidebar></layout-sidebar>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <!-- Page Header -->
        <div class="page-header">
          <div class="row">
            <div class="col-sm-6">
              <h3 class="page-title">Invoice settings</h3>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/admin/index">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">Tax Settings</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- /Page Header -->

        <div class="row">
          <div class="col-xl-3 col-md-4">
            <div class="widget settings-menu">
              <ul>
                <li class="nav-item">
                  <router-link to="/admin/invoices-settings" class="nav-link">
                    <i class="feather-git-commit"></i>
                    <span>General Settings</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/admin/tax-settings" class="nav-link active">
                    <i class="feather feather-bookmark"></i> <span>Tax Settings</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/admin/bank-settings" class="nav-link">
                    <i class="fas fa-university"></i> <span>Bank Settings</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-xl-9 col-md-8">
            <div class="card invoices-settings-card">
              <div class="card-header">
                <h5 class="card-title">Tax Settings</h5>
              </div>
              <div class="card-body">
                <!-- Form -->
                <form action="#" class="invoices-settings-form">
                  <div class="row align-items-center form-group">
                    <label
                      for="name"
                      class="col-sm-3 col-form-label input-label"
                      >Enable Tax</label
                    >
                    <div class="col-sm-9">
                      <label class="custom_check">
                        <input type="checkbox" name="invoice" />
                        <span class="checkmark"></span> Check to enable tax on
                        invoice
                      </label>
                    </div>
                  </div>
                  <div class="row align-items-center form-group">
                    <label
                      for="email"
                      class="col-sm-3 col-form-label input-label"
                      >Tax Percentage</label
                    >
                    <div class="col-sm-9">
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="row align-items-center form-group">
                    <label
                      for="phone"
                      class="col-sm-3 col-form-label input-label"
                      >GSTIN Number</label
                    >
                    <div class="col-sm-9">
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="invoice-setting-btn text-end">
                    <button type="submit" class="btn cancel-btn me-2">
                      Cancel
                    </button>
                    <button type="submit" class="btn btn-primary">
                      Save Changes
                    </button>
                  </div>
                </form>
                <!-- /Form -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
</template>
