<template>
  <div id="meet" ref="jitsiContainer"></div>
</template>

<script>
import  JitsiMeetJS  from 'lib-jitsi-meet';

  export default {
    name: 'JitsiMeet',
    props: {
      roomName: {
        type: String,
        required: true,
      },
      displayName: {
        type: String,
        default: 'Participant',
      },
      configOverwrite: {
        type: Object,
        default: () => ({}),
      },
      interfaceConfigOverwrite: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        jitsiAPI: null,
      };
    },
    mounted() {
      this.initJitsiMeet();
    },
    beforeDestroy() {
      if (this.jitsiAPI) {
        this.jitsiAPI.dispose();
      }
    },
    methods: {
      initJitsiMeet() {
        const domain = 'meet.jit.si';
        const options = {
          roomName: this.roomName,
          width: '100%',
          height: '100%',
          parentNode: this.$refs.jitsiContainer,
          configOverwrite: this.configOverwrite,
          interfaceConfigOverwrite: this.interfaceConfigOverwrite,
        };

        this.jitsiAPI = new JitsiMeetJS.JitsiMeetExternalAPI(domain, options);

        this.jitsiAPI.addEventListener('videoConferenceJoined', () => {
          this.jitsiAPI.executeCommand('displayName', this.displayName);
        });

        this.jitsiAPI.addEventListener('participantLeft', () => {
          console.log('Participant left');
        });

        this.jitsiAPI.addEventListener('readyToClose', () => {
          console.log('Jitsi Meet closed');
        });
      },
    },
  };
</script>