<template>
  <div class="call-page">
    <div class="main-wrapper">
      <layout-header1></layout-header1>
      <!-- Page Content -->
      <div class="content">
        <div class="container-fluid">
          <!-- Call Wrapper -->
          <div class="call-wrapper">
            <div class="call-main-row">
              <div class="call-main-wrapper">
                <div class="call-view">
                  <div class="call-window">
                    <!-- Afficher un message contextuel -->
                    <div v-if="joinRequests.length">
                      <p>Vous avez un appel entrant de {{ callerId }}. Veuillez y répondre!</p>
                      <button @click="answerCall">Répondre à l’appel</button>
                    </div> 
                    <!-- Call Header -->
                    <div class="fixed-header">
                      <div class="navbar">
                        <div class="user-details">
                          <div class="float-start user-img">
                            <router-link
                              class="avatar avatar-sm me-2"
                              to="/mentee/mentor-profile"
                              title="Jonathan Doe"
                            >
                              <img
                                src="@/assets/img/website/user/user.jpg"
                                alt="User Image"
                                class="rounded-circle"
                              />
                              <span class="status online"></span>
                            </router-link>
                          </div>
                          <div class="user-info float-start">
                            <router-link to="/mentee/mentor-profile"
                              ><span>{{  callerId }}</span></router-link
                            >
                            <span class="last-seen">Online</span>
                          </div>
                        </div>
                        <ul class="nav float-end custom-menu">
                          <li class="nav-item dropdown dropdown-action">
                            <a
                              href="javascript:;"
                              class="nav-link dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              ><i class="fa fa-cog"></i
                            ></a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a href="javascript:void(0)" class="dropdown-item"
                                >Settings</a
                              >
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <!-- /Call Header -->

                    <!-- Call Contents -->
                    <div class="call-contents">
                      <div class="call-content-wrap">
                        <div class="user-video">
                          <video class="remote" id="remote-video" ref="remoteVideo" autoplay>
                          </video>
                          <canvas class="annotation-canvas" ref="annotationCanvas"></canvas>
                        </div>
                        <div class="my-video">
                          <ul>
                            <li>
                              <video class="local" id="local-video" ref="localVideo" autoplay
                                playsinline width="300" height="300">
                              </video>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Call Contents -->

                    <!-- Call Footer -->
                    <div class="call-footer">
                      <div class="call-icons">
                        <span class="call-duration">{{ callDuration }}</span>
                        <ul class="call-items">
                          <li class="call-item">
                            <a
                              href="#"
                              title="Appeler"
                              data-placement="top"
                              data-bs-toggle="tooltip"
                              @click.prevent="initiateCall"
                            >
                              <i class="fas fa-phone camera"></i>
                            </a>
                          </li>                          
                          <li class="call-item">
                            <a
                              href="#"
                              title="Enable Video"
                              data-placement="top"
                              data-bs-toggle="tooltip"
                              @click.prevent="toggleVideo"
                            >
                              <i class="fas fa-video camera"></i>
                            </a>
                          </li>
                          <li class="call-item">
                            <a
                              href=""
                              title="Mute Audio"
                              data-placement="top"
                              data-bs-toggle="tooltip"
                              @click.prevent="toggleMute"
                            >
                              <i class="fa fa-microphone microphone"></i>
                            </a>
                          </li>
                          <li class="call-item">
                            <a
                              href=""
                              title="Add User"
                              data-placement="top"
                              data-bs-toggle="tooltip"
                            >
                              <i class="fa fa-user-plus"></i>
                            </a>
                          </li>
                          <li class="call-item">
                            <a
                              href=""
                              title="Full Screen"
                              data-placement="top"
                              data-bs-toggle="tooltip"
                            >
                              <i class="fas fa-arrows-alt-v full-screen"></i>
                            </a>
                          </li>
                          <li class="call-item">
                            <a href="#" title="Annoter" data-placement="top" data-bs-toggle="tooltip" @click.prevent="toggleAnnotation">
                              <i class="fas fa-pencil-alt"></i>
                            </a>
                          </li>
                        </ul>
                        <div class="end-call">
                          <a href="#"
                            @click.prevent="endCall"
                            v-if="callInProgress"
                          >
                            <i class="material-icons">call_end</i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <!-- /Call Footer -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Call Wrapper -->
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
</template>
<script>
import { API_BASE_URL } from '@/config';
import SimplePeer from 'simple-peer';
import { io } from 'socket.io-client';

export default {
  name: 'video-call',
  data() {
    return {
      socket: null,
      localStream: null,
      peer: null,
      roomId: '',
      joined: false,
      joinRequests: []
    }
  },
  mounted() {
    this.requestMediaPermissions();
    this.socket = io(API_BASE_URL);
    this.socket.on('signal', this.handleSignal);
    this.socket.on('join-approved', this.createPeer);
    this.roomId = this.$route.params.calleeId;
  },
  methods: {
    async requestMediaPermissions() {
      try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
          this.localStream = stream;
          this.$refs.localVideo.srcObject = stream;
      } catch (error) {
          console.error('Erreur lors de l’accès aux périphériques multimédias:', error);
          alert('Erreur lors de l’accès aux périphériques multimédias: ' + error.message);
      }
    },
    createPeer() {
      this.peer = new SimplePeer({
        initiator: true,
        trickle: false,
        stream: this.localStream,
      });

      this.peer.on('signal', data => {
        this.$socket.emit('signal', data);
      });

      this.peer.on('stream', stream => {
        this.$refs.remoteVideo.srcObject = stream;
      });
    },
    handleSignal(data) {
      if (this.peer) {
        this.peer.signal(data);
      }
    },
    joinRoom() {
      if (this.roomId) {
        this.$socket.emit('join-room', this.roomId);
        this.joined = true;
      }
    },
    handleJoinRequest(socketId) {
      this.joinRequests.push(socketId);
    },
    approveJoin(socketId) {
      this.$socket.emit('approve-join', socketId);
      this.joinRequests = this.joinRequests.filter(id => id !== socketId);
    },
  },
  created() {
    this.socket.on('join-request', (socketId) => {
      console.log('La demande d’adhésion est arrivée', socketId);
      this.handleJoinRequest(socketId);
    });
  }

}
</script>
