<template>
  <div class="main-wrapper">
    <layout-header2></layout-header2>
    <breadcrumb></breadcrumb>
    <!-- Page Content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
            <div class="stickysidebar">
              <sidebar></sidebar>
            </div>
          </div>
          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="row">
              <div class="col-md-12 col-lg-4 dash-board-list blue">
                <div class="dash-widget">
                  <div class="circle-bar">
                    <div class="icon-col">
                      <i class="fas fa-users"></i>
                    </div>
                  </div>
                  <div class="dash-widget-info">
                    <h3>23</h3>
                    <h6>Appels reçus</h6>
                  </div>
                </div>
              </div>

              <div class="col-md-12 col-lg-4 dash-board-list yellow">
                <div class="dash-widget">
                  <div class="circle-bar">
                    <div class="icon-col">
                      <i class="fas fa-calendar-check"></i>
                    </div>
                  </div>
                  <div class="dash-widget-info">
                    <h3>13</h3>
                    <h6>Appels traités</h6>
                  </div>
                </div>
              </div>

              <div class="col-md-12 col-lg-4 dash-board-list pink">
                <div class="dash-widget">
                  <div class="circle-bar">
                    <div class="icon-col">
                      <i class="fas fa-wallet"></i>
                    </div>
                  </div>
                  <div class="dash-widget-info">
                    <h3>10</h3>
                    <h6>Appels en attente</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h4 class="mb-4">Professionnels affilier</h4>
                <div class="card card-table">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-hover table-center mb-0">
                        <thead>
                          <tr>
                            <th>Professionnels</th>
                            <th>Centres</th>
                            <th class="text-center">Statut</th>
                            <th class="text-center">Accéder</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="affilier in affiliers" :key="affilier.id">
                            <td>
                              <h2 class="table-avatar">
                                <router-link
                                  to="/mentee/mentor-profile"
                                  class="avatar avatar-sm me-2"
                                  ><img
                                    class="avatar-img rounded-circle"
                                    :src="
                                      require(`@/assets/img/website/user/${affilier.nom}`)
                                    "
                                    alt="User Image"
                                /></router-link>
                                <router-link to="/mentee/mentor-profile">
                                  {{ affilier.nom
                                  }}<span> {{ affilier.nom }}</span></router-link
                                >
                              </h2>
                            </td>
                            <td>{{ affilier.nom }}</td>
                            <td class="text-center">
                              <span
                                v-if="affilier.nom == 'pending'"
                                class="pending"
                                >{{ affilier.nom }}</span
                              >
                              <span
                                v-else-if="affilier.nom == 'accepted'"
                                class="accept"
                                >{{ affilier.nom }}</span
                              >
                              <span
                                v-else-if="affilier.nom == 'rejected'"
                                class="reject"
                                >{{ affilier.nom }}</span
                              >
                            </td>
                            <td class="text-center">
                              <router-link
                                to="/mentee/mentor-profile"
                                class="btn btn-sm bg-info-light"
                                ><i class="far fa-eye"></i> View</router-link
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <layout-footer></layout-footer>
  </div>
</template>

<script>
import mentees from "@/assets/json/website/mentees.json";
import { API_BASE_URL } from "@/config";
import store from "@/store";
export default {
  data() {
    return {
      currentUserId: store.getters.id,
      affiliers: [],
      mentees: mentees,
    };
  },
  methods: {
    async loadAffiliations() {
      const response = await fetch(`${API_BASE_URL}/affiliations/${this.currentUserId}`);
      const data = await response.json();
      this.affiliers = data.affiliations;
      console.log(this.affiliers);
      
    }
  },
  mounted() {
    this.loadAffiliations();
  },
  
};
</script>
