<template>
    			<!-- Student Place -->
			<section class="student-place">
				<div class="container">
					<div class="row align-items-center">
						<div class="col-lg-3">
							<div class="student-place-img">
								<img src="@/assets/img/student-bg-01.png" class="img-fluid" alt="">
							</div>
						</div>
						<div class="col-lg-6">
							<div class="heder-eight aos " data-aos="fade-up">
								<div class="section-head-eight student-place-title mb-0">
									<h2>25K+ Students Are In  <span> One Place </span></h2>
									<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
										Eget aenean accumsan bibendum </p>
										<div class="student-join-btn">
											<router-link to="/pages/login">Join Now</router-link>
										</div>
								</div>
							</div>
						</div>
						<div class="col-lg-3">
							<div class="student-place-img">
								<img src="@/assets/img/student-bg-02.png" class="img-fluid" alt="">
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- Student Place -->
    </template>