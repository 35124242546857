<template>
  <div class="main-wrapper">
    <layout-header2></layout-header2>
    <breadcrumb></breadcrumb>
    <!-- Page Content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
            <div class="stickysidebar">
              <sidebar></sidebar>
            </div>
          </div>
          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="row">
              <div class="col-md-12 col-lg-4 dash-board-list blue">
                <div class="dash-widget">
                  <div class="circle-bar">
                    <div class="icon-col">
                      <i class="fas fa-users"></i>
                    </div>
                  </div>
                  <div class="dash-widget-info">
                    <h3>23</h3>
                    <h6>Appels reçus</h6>
                  </div>
                </div>
              </div>

              <div class="col-md-12 col-lg-4 dash-board-list yellow">
                <div class="dash-widget">
                  <div class="circle-bar">
                    <div class="icon-col">
                      <i class="fas fa-calendar-check"></i>
                    </div>
                  </div>
                  <div class="dash-widget-info">
                    <h3>13</h3>
                    <h6>Appels traités</h6>
                  </div>
                </div>
              </div>

              <div class="col-md-12 col-lg-4 dash-board-list pink">
                <div class="dash-widget">
                  <div class="circle-bar">
                    <div class="icon-col">
                      <i class="fas fa-wallet"></i>
                    </div>
                  </div>
                  <div class="dash-widget-info">
                    <h3>10</h3>
                    <h6>Appels en attente</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h4 class="mb-4">Liste des professionnels</h4>
                <div class="card card-table">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-hover table-center mb-0">
                        <thead>
                          <tr>
                            <th>Nom et prénom</th>
                            <th>Centres</th>
                            <th class="text-center">Statut</th>
                            <th class="text-center">Accéder</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in items" :key="item.id">
                            <td>
                              <h2 class="table-avatar">
                                <router-link
                                  to="/mentee/mentor-profile"
                                  class="avatar avatar-sm me-2"
                                  ><img
                                    class="avatar-img rounded-circle"
                                    :src="getProfileImageUrl(item.photo)"
                                    alt="Avatar du professionnel"
                                /></router-link>
                                <router-link to="/mentee/mentor-profile">
                                  {{ item.nom +' '+ item.prenom
                                  }}<span> {{ item.email }}</span></router-link
                                >
                              </h2>
                            </td>
                            <td>{{  }}</td>
                            <td class="text-center">
                              <!-- <span
                                v-if="item.affiliation_status == 'En attente'"
                                class="pending"
                                >{{ item.affiliation_status }}</span
                              >
                              <span
                                v-else-if="item.affiliation_status == 'accepté'"
                                class="accept"
                                >{{ item.affiliation_status }}</span
                              >
                              <span
                                v-else-if="item.affiliation_status == 'rejeté'"
                                class="reject"
                                >{{ item.affiliation_status }}</span
                              > -->
                            </td>
                            <td class="">
                              <router-link
                                to="/mentee/mentor-profile"
                                class="btn btn-sm bg-success-light mx-2"
                                ><i class="far fa-eye"></i> </router-link
                              >
                               <button v-if="item.status == 'none'" class="btn btn-sm bg-primary-light" @click="sendRequest(item.id)">
                                demander une affiliation
                              </button>
                              <div v-else-if="item.status === 'affiliated'">
                                <button class="btn btn-sm bg-success-light" @click="startConversation(item.id)">Démarrer la conversation</button>
                                <button class="btn btn-sm bg-danger-light" @click="removeAffiliation(item.id)">Rétire l'affiliation</button>
                              </div>
                              <button v-else-if="item.status === 'requestSent'" class="btn btn-sm bg-warning-light" disabled>
                                Affiliation En attente
                              </button>
                              <div v-else-if="item.status === 'requestReceived'">
                                <button class="btn btn-sm bg-success-light" @click="acceptRequest(item.requestId)">Accepter l'affiliation</button>
                                <button class="btn btn-sm bg-danger-light mx-2" @click="rejectRequest(item.requestId)">Rejeter l'affiliation</button>
                              </div> 
                              
                              <!-- <button v-if="item.status === 'affiliated'" class="btn btn-sm bg-success-light" @click="startConversation(item.id)">Démarrer la conversation</button> -->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <layout-footer></layout-footer>
  </div>
</template>

<script>
import { API_BASE_URL } from '@/config';
import * as firestoreService from '@/database/firestore'
import store from '@/store';
import { io } from 'socket.io-client';

export default {
  data() {
    return {
      socket: io(API_BASE_URL),
      items: [],
      currentUserId: String(store.getters.id),
      isLoading: true,
      error: null,
      defaultProfileImage: require('@/assets/img/user/1.png'),
      affiliationRequests: [],  // Ajoutez ceci pour stocker les demandes d'affiliation
      isProfessional: false,
    };
  },
  created() {
    // Initialiser Socket.io et Toast
    this.socket = io(API_BASE_URL);
    // this.toast = useToast();

    // Écouter les événements de Socket.io
    this.socket.on('affiliationRequestReceived', this.handleFriendRequestReceived);
    this.socket.on('affiliationRequestAccepted', this.handleFriendRequestAccepted);
    this.socket.on('affiliationRequestRejected', this.handleFriendRequestRejected);
    this.socket.on('affiliationRemoved', this.handleFriendRemoved);
    this.socket.on('error', this.handleSocketError);

    // Charger les utilisateurs
    this.fetchUsers();
  },
  methods: {
    getProfileImageUrl(profileImage) {
      return profileImage !== null  ? `${API_BASE_URL}/uploads/photos/${profileImage}` : this.defaultProfileImage;
    },
    async fetchUsers() {
      const response = await fetch(`${API_BASE_URL}/users/${this.currentUserId}`);
      this.items = await response.json();
      console.log('les users', this.items);
      
    },
    async startConversation(professionalId) {
      const currentUserId = String(store.getters.id); // Id de l'utilisateur courant
      const professional = this.items.find(pro => pro.id === professionalId);

      if (!professional) {
        console.error('Utilisateur professionnel non trouvé');
        return;
      }

      // Formatage des données utilisateur pour vue-advanced-chat
			const formattedUser = {
				_id: String(professional.id),
				username: professional.nom +' '+ professional.prenom,
				avatar: professional.photo || '',  // Utilisez 'photoUrl' ou un autre champ pour l'avatar
			};

      const currentUser = {
        _id: currentUserId,
        username: store.getters.nom + ' ' + store.getters.prenom,  
        avatar: store.getters.photo     
      };
  
      try {
        console.log('formattedUser ', formattedUser);
        console.log('CurrentUser ', currentUser);
        
        // // Vérifiez si l'utilisateur existe déjà
        const userExists = await firestoreService.checkUserExists(currentUser._id);
          
        if (!userExists) {
          // Ajouter l'utilisateur s'il n'existe pas encore
          await firestoreService.addIdentifiedUser(currentUser._id, currentUser);
        } else {
          console.log(`L'utilisateur ${currentUserId.username} existe déjà.`);
        }
        console.log('test');
        
        await firestoreService.addIdentifiedUser(formattedUser._id, formattedUser);
        console.log('user', formattedUser._id);
        
        
        // Vérifiez si la salle existe déjà
        const existingRooms = await firestoreService.getUserRooms(currentUserId, formattedUser._id);

        if (existingRooms.data.length > 0) {
          console.log('La salle existe déjà.', existingRooms.data[0].id);
          // Redirige vers le chat en passant l'ID de la salle existante
          this.$router.push({ name: 'chat', params: { roomId: existingRooms.data[0].id } });
        } else {
          // La salle n'existe pas, donc nous la créons
          const roomRef = await firestoreService.addRoom({
            users: [currentUser._id, formattedUser._id],
            lastUpdated: new Date(),
          });

          console.log('Salle créée avec succès.');
          // Redirige vers le chat en passant l'ID de la nouvelle salle
          this.$router.push({ name: 'chat', params: { roomId: roomRef.id } });
        }
      } catch (error) {
        console.error('Erreur lors de la création de la conversation', error);
      }
    },

    sendRequest(receiverId) {
      this.socket.emit('sendAffiliationRequest', { senderId: this.currentUserId, receiverId });
    },
    acceptRequest(requestId) {
      this.socket.emit('acceptAffiliationRequest', { requestId });
    },
    rejectRequest(requestId) {
      this.socket.emit('rejectAffiliationRequest', { requestId });
    },
    removeAffiliation(affiliatedId) {
      console.log('affilierId', affiliatedId);
      this.socket.emit('removeAffiliation', { userId: this.currentUserId, affiliatedId });
    },
    handleFriendRequestReceived(data) {
      alert(`Vous avez reçu une demande d'ami de ${data.senderId}`);
      this.fetchUsers();
    },
    handleFriendRequestAccepted(data) {
      alert('Votre demande d\'ami a été acceptée');
      this.fetchUsers();
    },
    handleFriendRequestRejected(data) {
      alert('Votre demande d\'ami a été rejetée');
      this.fetchUsers();
    },
    handleFriendRemoved(data) {
      alert('Vous avez été retiré de la liste d\'amis');
      this.fetchUsers();
    },
    handleSocketError(error) {
      alert(`Erreur: ${error.message}`);
    }

  },
};
</script>
