<template>
  <div class="main-wrapper">
    <layout-header />
    <HomeBanner />
    <layout-footer />
  </div>
	<!-- <div class="map-page">
		<div class="main-wrapper">
      <layout-header />
      <CentreMap />
      <layout-footer />
		</div>
	</div> -->
</template>

<script>

</script>
