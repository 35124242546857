<template>
    			<!-- Most Trending  Courses -->
			<section class="section trending-courses trending-courses-three popular-course-three popular-course-five">
				<div class="container">
					<div class="aos " data-aos="fade-up">
						<div class="heder-eight aos " data-aos="fade-up">
							<div class="section-head-eight">
								<h2>Our Popular <span>Courses</span></h2>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget aenean accumsan bibendum </p>
							</div>
						</div>
					</div>
					<ul class="nav nav-pills course-eight-tab" id="pills-tab-three" role="tablist">
						<li class="nav-item" role="presentation">
						<button class="nav-link active" id="pills-allcoursetwo-tab" data-bs-toggle="pill" data-bs-target="#pills-allcoursetwo" type="button" role="tab" aria-controls="pills-allcoursetwo" aria-selected="true">All Courses</button>
						</li>
						<li class="nav-item" role="presentation">
						<button class="nav-link" id="pills-animationtwo-tab" data-bs-toggle="pill" data-bs-target="#pills-animationtwo" type="button" role="tab" aria-controls="pills-animationtwo" aria-selected="false" tabindex="-1">Animation</button>
						</li>
						<li class="nav-item" role="presentation">
							<button class="nav-link" id="pills-webtwo-tab" data-bs-toggle="pill" data-bs-target="#pills-webtwo" type="button" role="tab" aria-controls="pills-webtwo" aria-selected="false">Web Development</button>
						</li>
						<li class="nav-item" role="presentation">
							<button class="nav-link" id="pills-securitytwo-tab" data-bs-toggle="pill" data-bs-target="#pills-securitytwo" type="button" role="tab" aria-controls="pills-securitytwo" aria-selected="false" tabindex="-1">SEO & Security</button>
						</li>
						<li class="nav-item" role="presentation">
							<button class="nav-link" id="pills-salestwo-tab" data-bs-toggle="pill" data-bs-target="#pills-salestwo" type="button" role="tab" aria-controls="pills-salestwo" aria-selected="false" tabindex="-1">Sales</button>
						</li>
					</ul>
					<div class="tab-content course-tab-six" id="pills-tabContent-three">
						<div class="tab-pane fade active show" id="pills-allcoursetwo" role="tabpanel" aria-labelledby="pills-allcoursetwo-tab">
							<div class="row align-items-center justify-content-center">
								<div class="col-lg-4 col-md-6">
									<div class="course-box course-box-five course-box-eight">
										<div class="product">
											<div class="product-img trend-course">
												<a href="javascript:void(0);">
													<img class="img-fluid" alt="" src="@/assets/img/course/trending-course-05.jpg" width="600" height="300">
												</a>
											</div>
											<div class="product-content">
												<div class="card-mark">
													<span class="mark">Marketing</span>
													<span class="popular">Popular</span>
												</div>
												<div class="rating rate-star rating-star-five">	
													<span class="average-rating rate-point ">
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														5.0 <b> (2566)</b>
													</span>
													<div class="feature-texts-eight">
														<h5><router-link to="/mentee/mentor-profile">Brittany Garcia</router-link></h5>
														<div class="rating-img">
															<img src="@/assets/img/user/profile-01.jpg" alt="">
														</div>
													</div>
												</div>
												<h3 class="title"><a href="javascript:void(0);">Learn CorelDraw – Interactive Tutorial</a></h3>
												<div class="lesson-student-five">
													<div class="student-five">
														<span><img src="@/assets/img/icon/user-icon-05.svg" alt="">800 Students</span>
													</div>
													<div class="lesson-five">
														<span><img src="@/assets/img/icon/book-content-icon.svg" alt="">60 Lessons</span>
													</div>
												</div>
												<div class="rating rating-star rating-two rating-five align-items-center justify-content-between">	
													<div class="course-btn">
														<span class="line-through">$40.00</span>
														<span>$30.00</span>
													</div>
													<span class="card-arrow">
														<a href="javascript:void(0);"><img src="@/assets/img/icon/white-arrow-up.svg" alt=""></a>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-4 col-md-6">
									<div class="course-box course-box-five course-box-eight">
										<div class="product">
											<div class="product-img trend-course">
												<a href="javascript:void(0);">
													<img class="img-fluid" alt="" src="@/assets/img/course/trending-course-15.jpg" width="600" height="300">
												</a>
											</div>
											<div class="product-content">
												<div class="card-mark">
													<span class="mark">Marketing</span>
													<span class="new">New</span>
												</div>
												<div class="rating rate-star rating-star-five">	
													<span class="average-rating rate-point ">
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														4.8 <b> (2470)</b>
													</span>
													<div class="feature-texts-eight">
														<h5><router-link to="/mentee/mentor-profile">Tyrone Roberts</router-link></h5>
														<div class="rating-img">
															<img src="@/assets/img/user/profile-02.jpg" alt="">
														</div>
													</div>
												</div>
												<h3 class="title"><a href="javascript:void(0);">Interview Question: Why Dont You Have a Degree?</a></h3>
												<div class="lesson-student-five">
													<div class="student-five">
														<span><img src="@/assets/img/icon/user-icon-05.svg" alt="">850 Students</span>
													</div>
													<div class="lesson-five">
														<span><img src="@/assets/img/icon/book-content-icon.svg" alt="">55 Lessons</span>
													</div>
												</div>
												<div class="rating rating-star rating-two rating-five align-items-center justify-content-between">	
													<div class="course-btn">
														<span class="line-through">$40.00</span>
														<span>$34.00</span>
													</div>
													<span class="card-arrow">
														<a href="javascript:void(0);"><img src="@/assets/img/icon/white-arrow-up.svg" alt=""></a>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-4 col-md-6">
									<div class="course-box course-box-five course-box-eight">
										<div class="product">
											<div class="product-img trend-course">
												<a href="javascript:void(0);">
													<img class="img-fluid" alt="" src="@/assets/img/course/trending-course-16.jpg" width="600" height="300">
												</a>
											</div>
											<div class="product-content">
												<div class="card-mark">
													<span class="mark">Marketing</span>
												</div>
												<div class="rating rate-star rating-star-five">	
													<span class="average-rating rate-point ">
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														4.5 <b> (2500)</b>
													</span>
													<div class="feature-texts-eight">
														<h5><router-link to="/mentee/mentor-profile">Remora Jaine</router-link></h5>
														<div class="rating-img">
															<img src="@/assets/img/user/profile-03.jpg" alt="">
														</div>
													</div>
												</div>
												<h3 class="title"><a href="javascript:void(0);">Learn CorelDraw – Interactive Tutorial</a></h3>
												<div class="lesson-student-five">
													<div class="student-five">
														<span><img src="@/assets/img/icon/user-icon-05.svg" alt="">700 Students</span>
													</div>
													<div class="lesson-five">
														<span><img src="@/assets/img/icon/book-content-icon.svg" alt="">50 Lessons</span>
													</div>
												</div>
												<div class="rating rating-star rating-two rating-five align-items-center justify-content-between">	
													<div class="course-btn">
														<span class="line-through">$40.00</span>
														<span>$36.00</span>
													</div>
													<span class="card-arrow">
														<a href="javascript:void(0);"><img src="@/assets/img/icon/white-arrow-up.svg" alt=""></a>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="tab-pane fade" id="pills-animationtwo" role="tabpanel" aria-labelledby="pills-animationtwo-tab">
							<div class="row align-items-center justify-content-center">
								<div class="col-lg-4 col-md-6">
									<div class="course-box course-box-five course-box-eight">
										<div class="product">
											<div class="product-img trend-course">
												<a href="javascript:void(0);">
													<img class="img-fluid" alt="" src="@/assets/img/course/trending-course-17.jpg" width="600" height="300">
												</a>
											</div>
											<div class="product-content">
												<div class="card-mark">
													<span class="mark">Marketing</span>
													<span class="popular">Popular</span>
												</div>
												<div class="rating rate-star rating-star-five">	
													<span class="average-rating rate-point ">
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														5.0 <b> (2566)</b>
													</span>
													<div class="feature-texts-eight">
														<h5><router-link to="/mentee/mentor-profile">Brittany Garcia</router-link></h5>
														<div class="rating-img">
															<img src="@/assets/img/user/profile-01.jpg" alt="">
														</div>
													</div>
												</div>
												<h3 class="title"><a href="javascript:void(0);">Learn CorelDraw – Interactive Tutorial</a></h3>
												<div class="lesson-student-five">
													<div class="student-five">
														<span><img src="@/assets/img/icon/user-icon-05.svg" alt="">750 Students</span>
													</div>
													<div class="lesson-five">
														<span><img src="@/assets/img/icon/book-content-icon.svg" alt="">50 Lessons</span>
													</div>
												</div>
												<div class="rating rating-star rating-two rating-five align-items-center justify-content-between">	
													<div class="course-btn">
														<span class="line-through">$40.00</span>
														<span>$34.00</span>
													</div>
													<span class="card-arrow">
														<a href="javascript:void(0);"><img src="@/assets/img/icon/white-arrow-up.svg" alt=""></a>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-4 col-md-6">
									<div class="course-box course-box-five course-box-eight">
										<div class="product">
											<div class="product-img trend-course">
												<a href="javascript:void(0);">
													<img class="img-fluid" alt="" src="@/assets/img/course/trending-course-18.jpg" width="600" height="300">
												</a>
											</div>
											<div class="product-content">
												<div class="card-mark">
													<span class="mark">Marketing</span>
													<span class="new">New</span>
												</div>
												<div class="rating rate-star rating-star-five">	
													<span class="average-rating rate-point ">
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														5.0 <b> (2566)</b>
													</span>
													<div class="feature-texts-eight">
														<h5><router-link to="/mentee/mentor-profile">Tyrone Roberts</router-link></h5>
														<div class="rating-img">
															<img src="@/assets/img/user/profile-02.jpg" alt="">
														</div>
													</div>
												</div>
												<h3 class="title"><a href="javascript:void(0);">Interview Question: Why Dont You Have a Degree?</a></h3>
												<div class="lesson-student-five">
													<div class="student-five">
														<span><img src="@/assets/img/icon/user-icon-05.svg" alt="">840 Students</span>
													</div>
													<div class="lesson-five">
														<span><img src="@/assets/img/icon/book-content-icon.svg" alt="">65 Lessons</span>
													</div>
												</div>
												<div class="rating rating-star rating-two rating-five align-items-center justify-content-between">	
													<div class="course-btn">
														<span class="line-through">$40.00</span>
														<span>$33.00</span>
													</div>
													<span class="card-arrow">
														<a href="javascript:void(0);"><img src="@/assets/img/icon/white-arrow-up.svg" alt=""></a>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-4 col-md-6">
									<div class="course-box course-box-five course-box-eight">
										<div class="product">
											<div class="product-img trend-course">
												<a href="javascript:void(0);">
													<img class="img-fluid" alt="" src="@/assets/img/course/trending-course-19.jpg" width="600" height="300">
												</a>
											</div>
											<div class="product-content">
												<div class="card-mark">
													<span class="mark">Marketing</span>
												</div>
												<div class="rating rate-star rating-star-five">	
													<span class="average-rating rate-point ">
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														5.0 <b> (2566)</b>
													</span>
													<div class="feature-texts-eight">
														<h5><router-link to="/mentee/mentor-profile">Remora Jaine</router-link></h5>
														<div class="rating-img">
															<img src="@/assets/img/user/profile-03.jpg" alt="">
														</div>
													</div>
												</div>
												<h3 class="title"><a href="javascript:void(0);">Learn CorelDraw – Interactive Tutorial</a></h3>
												<div class="lesson-student-five">
													<div class="student-five">
														<span><img src="@/assets/img/icon/user-icon-05.svg" alt="">700 Students</span>
													</div>
													<div class="lesson-five">
														<span><img src="@/assets/img/icon/book-content-icon.svg" alt="">40 Lessons</span>
													</div>
												</div>
												<div class="rating rating-star rating-two rating-five align-items-center justify-content-between">	
													<div class="course-btn">
														<span class="line-through">$40.00</span>
														<span>$35.00</span>
													</div>
													<span class="card-arrow">
														<a href="javascript:void(0);"><img src="@/assets/img/icon/white-arrow-up.svg" alt=""></a>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="tab-pane fade" id="pills-webtwo" role="tabpanel" aria-labelledby="pills-webtwo-tab">
							<div class="row align-items-center justify-content-center">
								<div class="col-lg-4 col-md-6">
									<div class="course-box course-box-five course-box-eight">
										<div class="product">
											<div class="product-img trend-course">
												<a href="javascript:void(0);">
													<img class="img-fluid" alt="" src="@/assets/img/course/trending-course-05.jpg" width="600" height="300">
												</a>
											</div>
											<div class="product-content">
												<div class="card-mark">
													<span class="mark">Marketing</span>
													<span class="popular">Popular</span>
												</div>
												<div class="rating rate-star rating-star-five">	
													<span class="average-rating rate-point ">
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														5.0 <b> (2566)</b>
													</span>
													<div class="feature-texts-eight">
														<h5><router-link to="/mentee/mentor-profile">Brittany Garcia</router-link></h5>
														<div class="rating-img">
															<img src="@/assets/img/user/profile-01.jpg" alt="">
														</div>
													</div>
												</div>
												<h3 class="title"><a href="javascript:void(0);">Learn CorelDraw – Interactive Tutorial</a></h3>
												<div class="lesson-student-five">
													<div class="student-five">
														<span><img src="@/assets/img/icon/user-icon-05.svg" alt="">800 Students</span>
													</div>
													<div class="lesson-five">
														<span><img src="@/assets/img/icon/book-content-icon.svg" alt="">60 Lessons</span>
													</div>
												</div>
												<div class="rating rating-star rating-two rating-five align-items-center justify-content-between">	
													<div class="course-btn">
														<span class="line-through">$40.00</span>
														<span>$36.00</span>
													</div>
													<span class="card-arrow">
														<a href="javascript:void(0);"><img src="@/assets/img/icon/white-arrow-up.svg" alt=""></a>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-4 col-md-6">
									<div class="course-box course-box-five course-box-eight">
										<div class="product">
											<div class="product-img trend-course">
												<a href="javascript:void(0);">
													<img class="img-fluid" alt="" src="@/assets/img/course/trending-course-06.jpg" width="600" height="300">
												</a>
											</div>
											<div class="product-content">
												<div class="card-mark">
													<span class="mark">Marketing</span>
													<span class="new">New</span>
												</div>
												<div class="rating rate-star rating-star-five">	
													<span class="average-rating rate-point ">
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														4.7 <b> (2466)</b>
													</span>
													<div class="feature-texts-eight">
														<h5><router-link to="/mentee/mentor-profile">Tyrone Roberts</router-link></h5>
														<div class="rating-img">
															<img src="@/assets/img/user/profile-02.jpg" alt="">
														</div>
													</div>
												</div>
												<h3 class="title"><a href="javascript:void(0);">Interview Question: Why Dont You Have a Degree?</a></h3>
												<div class="lesson-student-five">
													<div class="student-five">
														<span><img src="@/assets/img/icon/user-icon-05.svg" alt="">650 Students</span>
													</div>
													<div class="lesson-five">
														<span><img src="@/assets/img/icon/book-content-icon.svg" alt="">50 Lessons</span>
													</div>
												</div>
												<div class="rating rating-star rating-two rating-five align-items-center justify-content-between">	
													<div class="course-btn">
														<span class="line-through">$40.00</span>
														<span>$32.00</span>
													</div>
													<span class="card-arrow">
														<a href="javascript:void(0);"><img src="@/assets/img/icon/white-arrow-up.svg" alt=""></a>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-4 col-md-6">
									<div class="course-box course-box-five course-box-eight">
										<div class="product">
											<div class="product-img trend-course">
												<a href="javascript:void(0);">
													<img class="img-fluid" alt="" src="@/assets/img/course/trending-course-07.jpg" width="600" height="300">
												</a>
											</div>
											<div class="product-content">
												<div class="card-mark">
													<span class="mark">Marketing</span>
												</div>
												<div class="rating rate-star rating-star-five">	
													<span class="average-rating rate-point ">
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														5.0 <b> (2566)</b>
													</span>
													<div class="feature-texts-eight">
														<h5><router-link to="/mentee/mentor-profile">Remora Jaine</router-link></h5>
														<div class="rating-img">
															<img src="@/assets/img/user/profile-03.jpg" alt="">
														</div>
													</div>
												</div>
												<h3 class="title"><a href="javascript:void(0);">Learn CorelDraw – Interactive Tutorial</a></h3>
												<div class="lesson-student-five">
													<div class="student-five">
														<span><img src="@/assets/img/icon/user-icon-05.svg" alt="">850 Students</span>
													</div>
													<div class="lesson-five">
														<span><img src="@/assets/img/icon/book-content-icon.svg" alt="">55 Lessons</span>
													</div>
												</div>
												<div class="rating rating-star rating-two rating-five align-items-center justify-content-between">	
													<div class="course-btn">
														<span class="line-through">$40.00</span>
														<span>$35.00</span>
													</div>
													<span class="card-arrow">
														<a href="javascript:void(0);"><img src="@/assets/img/icon/white-arrow-up.svg" alt=""></a>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="tab-pane fade" id="pills-securitytwo" role="tabpanel" aria-labelledby="pills-securitytwo-tab">
							<div class="row align-items-center justify-content-center">
								<div class="col-lg-4 col-md-6">
									<div class="course-box course-box-five course-box-eight">
										<div class="product">
											<div class="product-img trend-course">
												<a href="javascript:void(0);">
													<img class="img-fluid" alt="" src="@/assets/img/course/trending-course-17.jpg" width="600" height="300">
												</a>
											</div>
											<div class="product-content">
												<div class="card-mark">
													<span class="mark">Marketing</span>
													<span class="popular">Popular</span>
												</div>
												<div class="rating rate-star rating-star-five">	
													<span class="average-rating rate-point ">
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														5.0 <b> (2566)</b>
													</span>
													<div class="feature-texts-eight">
														<h5><router-link to="/mentee/mentor-profile">Remora Jaine</router-link></h5>
														<div class="rating-img">
															<img src="@/assets/img/user/profile-01.jpg" alt="">
														</div>
													</div>
												</div>
												<h3 class="title"><a href="javascript:void(0);">Learn CorelDraw – Interactive Tutorial</a></h3>
												<div class="lesson-student-five">
													<div class="student-five">
														<span><img src="@/assets/img/icon/user-icon-05.svg" alt="">800 Students</span>
													</div>
													<div class="lesson-five">
														<span><img src="@/assets/img/icon/book-content-icon.svg" alt="">60 Lessons</span>
													</div>
												</div>
												<div class="rating rating-star rating-two rating-five align-items-center justify-content-between">	
													<div class="course-btn">
														<span class="line-through">$40.00</span>
														<span>$36.00</span>
													</div>
													<span class="card-arrow">
														<a href="javascript:void(0);"><img src="@/assets/img/icon/white-arrow-up.svg" alt=""></a>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-4 col-md-6">
									<div class="course-box course-box-five course-box-eight">
										<div class="product">
											<div class="product-img trend-course">
												<a href="javascript:void(0);">
													<img class="img-fluid" alt="" src="@/assets/img/course/trending-course-18.jpg" width="600" height="300">
												</a>
											</div>
											<div class="product-content">
												<div class="card-mark">
													<span class="mark">Marketing</span>
													<span class="new">New</span>
												</div>
												<div class="rating rate-star rating-star-five">	
													<span class="average-rating rate-point ">
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														5.0 <b> (2566)</b>
													</span>
													<div class="feature-texts-eight">
														<h5><router-link to="/mentee/mentor-profile">Brittany Garcia</router-link></h5>
														<div class="rating-img">
															<img src="@/assets/img/user/profile-01.jpg" alt="">
														</div>
													</div>
												</div>
												<h3 class="title"><a href="javascript:void(0);">Interview Question: Why Dont You Have a Degree?</a></h3>
												<div class="lesson-student-five">
													<div class="student-five">
														<span><img src="@/assets/img/icon/user-icon-05.svg" alt="">850 Students</span>
													</div>
													<div class="lesson-five">
														<span><img src="@/assets/img/icon/book-content-icon.svg" alt="">50 Lessons</span>
													</div>
												</div>
												<div class="rating rating-star rating-two rating-five align-items-center justify-content-between">	
													<div class="course-btn">
														<span class="line-through">$40.00</span>
														<span>$34.00</span>
													</div>
													<span class="card-arrow">
														<a href="javascript:void(0);"><img src="@/assets/img/icon/white-arrow-up.svg" alt=""></a>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-4 col-md-6">
									<div class="course-box course-box-five course-box-eight">
										<div class="product">
											<div class="product-img trend-course">
												<a href="javascript:void(0);">
													<img class="img-fluid" alt="" src="@/assets/img/course/trending-course-19.jpg" width="600" height="300">
												</a>
											</div>
											<div class="product-content">
												<div class="card-mark">
													<span class="mark">Marketing</span>
												</div>
												<div class="rating rate-star rating-star-five">	
													<span class="average-rating rate-point ">
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														5.0 <b> (2566)</b>
													</span>
													<div class="feature-texts-eight">
														<h5><router-link to="/mentee/mentor-profile">Tyrone Roberts</router-link></h5>
														<div class="rating-img">
															<img src="@/assets/img/user/profile-01.jpg" alt="">
														</div>
													</div>
												</div>
												<h3 class="title"><a href="javascript:void(0);">Learn CorelDraw – Interactive Tutorial</a></h3>
												<div class="lesson-student-five">
													<div class="student-five">
														<span><img src="@/assets/img/icon/user-icon-05.svg" alt="">650 Students</span>
													</div>
													<div class="lesson-five">
														<span><img src="@/assets/img/icon/book-content-icon.svg" alt="">45 Lessons</span>
													</div>
												</div>
												<div class="rating rating-star rating-two rating-five align-items-center justify-content-between">	
													<div class="course-btn">
														<span class="line-through">$40.00</span>
														<span>$37.00</span>
													</div>
													<span class="card-arrow">
														<a href="javascript:void(0);"><img src="@/assets/img/icon/white-arrow-up.svg" alt=""></a>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="tab-pane fade" id="pills-salestwo" role="tabpanel" aria-labelledby="pills-salestwo-tab">
							<div class="row align-items-center justify-content-center">
								<div class="col-lg-4 col-md-6">
									<div class="course-box course-box-five course-box-eight">
										<div class="product">
											<div class="product-img trend-course">
												<a href="javascript:void(0);">
													<img class="img-fluid" alt="" src="@/assets/img/course/trending-course-18.jpg" width="600" height="300">
												</a>
											</div>
											<div class="product-content">
												<div class="card-mark">
													<span class="mark">Marketing</span>
													<span class="popular">Popular</span>
												</div>
												<div class="rating rate-star rating-star-five">	
													<span class="average-rating rate-point ">
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														5.0 <b> (2566)</b>
													</span>
													<div class="feature-texts-eight">
														<h5><router-link to="/mentee/mentor-profile">Brittany Garcia</router-link></h5>
														<div class="rating-img">
															<img src="@/assets/img/user/profile-01.jpg" alt="">
														</div>
													</div>
												</div>
												<h3 class="title"><a href="javascript:void(0);">Learn CorelDraw – Interactive Tutorial</a></h3>
												<div class="lesson-student-five">
													<div class="student-five">
														<span><img src="@/assets/img/icon/user-icon-05.svg" alt="">600 Students</span>
													</div>
													<div class="lesson-five">
														<span><img src="@/assets/img/icon/book-content-icon.svg" alt="">65 Lessons</span>
													</div>
												</div>
												<div class="rating rating-star rating-two rating-five align-items-center justify-content-between">	
													<div class="course-btn">
														<span class="line-through">$40.00</span>
														<span>$36.00</span>
													</div>
													<span class="card-arrow">
														<a href="javascript:void(0);"><img src="@/assets/img/icon/white-arrow-up.svg" alt=""></a>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-4 col-md-6">
									<div class="course-box course-box-five course-box-eight">
										<div class="product">
											<div class="product-img trend-course">
												<a href="javascript:void(0);">
													<img class="img-fluid" alt="" src="@/assets/img/course/trending-course-17.jpg" width="600" height="300">
												</a>
											</div>
											<div class="product-content">
												<div class="card-mark">
													<span class="mark">Marketing</span>
													<span class="new">New</span>
												</div>
												<div class="rating rate-star rating-star-five">	
													<span class="average-rating rate-point ">
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														5.0 <b> (2566)</b>
													</span>
													<div class="feature-texts-eight">
														<h5><router-link to="/mentee/mentor-profile">Tyrone Roberts</router-link></h5>
														<div class="rating-img">
															<img src="@/assets/img/user/profile-01.jpg" alt="">
														</div>
													</div>
												</div>
												<h3 class="title"><a href="javascript:void(0);">Interview Question: Why Dont You Have a Degree?</a></h3>
												<div class="lesson-student-five">
													<div class="student-five">
														<span><img src="@/assets/img/icon/user-icon-05.svg" alt="">700 Students</span>
													</div>
													<div class="lesson-five">
														<span><img src="@/assets/img/icon/book-content-icon.svg" alt="">50 Lessons</span>
													</div>
												</div>
												<div class="rating rating-star rating-two rating-five align-items-center justify-content-between">	
													<div class="course-btn">
														<span class="line-through">$40.00</span>
														<span>$35.00</span>
													</div>
													<span class="card-arrow">
														<a href="javascript:void(0);"><img src="@/assets/img/icon/white-arrow-up.svg" alt=""></a>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-4 col-md-6">
									<div class="course-box course-box-five course-box-eight">
										<div class="product">
											<div class="product-img trend-course">
												<a href="javascript:void(0);">
													<img class="img-fluid" alt="" src="@/assets/img/course/trending-course-05.jpg" width="600" height="300">
												</a>
											</div>
											<div class="product-content">
												<div class="card-mark">
													<span class="mark">Marketing</span>
												</div>
												<div class="rating rate-star rating-star-five">	
													<span class="average-rating rate-point ">
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														<i class="fas fa-star"></i>
														5.0 <b> (2566)</b>
													</span>
													<div class="feature-texts-eight">
														<h5><router-link to="/mentee/mentor-profile">Remora Jaine</router-link></h5>
														<div class="rating-img">
															<img src="@/assets/img/user/profile-01.jpg" alt="">
														</div>
													</div>
												</div>
												<h3 class="title"><a href="javascript:void(0);">Learn CorelDraw – Interactive Tutorial</a></h3>
												<div class="lesson-student-five">
													<div class="student-five">
														<span><img src="@/assets/img/icon/user-icon-05.svg" alt="">800 Students</span>
													</div>
													<div class="lesson-five">
														<span><img src="@/assets/img/icon/book-content-icon.svg" alt="">60 Lessons</span>
													</div>
												</div>
												<div class="rating rating-star rating-two rating-five align-items-center justify-content-between">	
													<div class="course-btn">
														<span class="line-through">$40.00</span>
														<span>$33.00</span>
													</div>
													<span class="card-arrow">
														<a href="javascript:void(0);"><img src="@/assets/img/icon/white-arrow-up.svg" alt=""></a>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- Most Trending Courses -->
    </template>