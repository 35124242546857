<template>
    <div class="main-wrapper">
        <layout-headeradmin></layout-headeradmin>
        <layout-sidebar></layout-sidebar>
       <!-- Page Wrapper -->
       <div class="page-wrapper">
        <div class="content container-fluid">
        
            <!-- Page Header -->
            <div class="page-header">
                <div class="row">
                    <div class="col-sm-6">
                        <h3 class="page-title">Professionnels</h3>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/admin/index">Tableau de Bord</router-link></li>
                            <li class="breadcrumb-item active">Professionnels</li>
                        </ul>
                    </div>
                    <div class="col-sm-6">
                        <div class="card invoices-tabs-card">
                            <div class="card-body card-body pt-0 pb-0">
                                <div class="invoices-main-tabs border-0 pb-0">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 col-md-12">
                                    <div class="invoices-settings-btn invoices-settings-btn-one">
                                        <a href="javascript:;" class="btn" data-bs-toggle="modal" data-bs-target="#edit_invoice_report">
                                        <i data-feather="plus-circle"></i> Nouveau
                                        </a>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /Page Header -->
            
            

            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="datatable table table-hover table-center mb-0">
                                    <thead>
                                        <tr>
                                            <th>Nom et prénom</th>
                                            <th>email</th>
                                            <th>Téléphone</th>
                                            <th class="text-center">Type utilisateur</th>
                                            <th class="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                         <tr v-for="item in listUsers" :key="item.id">
                                            <td>
                                                <h2 class="table-avatar">
                                                    <router-link to="/admin/profile" class="avatar avatar-sm me-2"><img class="avatar-img rounded-circle" :src="`https://192.168.1.68:3444/uploads/photos/${item.photo}`" alt="Image"></router-link>
                                                    <router-link to="/admin/profile">{{item.nom + ' ' + item.prenom}} </router-link>
                                                </h2>
                                            </td>
                                            <td><router-link to="/admin/invoice">{{item.email}}</router-link></td>
                                            <td>{{item.telephone}}</td>
                                            <td class="text-center">
                                                <span class="badge badge-pill bg-success inv-badge">{{item.type_utilisateur}}</span>
                                            </td>
                                            <td class="text-right">
                                                <div class="actions">
                                                    <a data-bs-toggle="modal" href="#edit_invoice_report" class="btn btn-sm bg-success-light me-2">
                                                        <i class="fa fa-edit"></i> En attente
                                                    </a>
                                                    <a class="btn btn-sm bg-danger-light" data-bs-toggle="modal" href="#delete_modal">
                                                        <i class="fa fa-trash"></i> Retirer
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>			
            </div>
        </div>			
    </div>
    <!-- /Page Wrapper -->
        
        </div>
        <!-- Edit Details Modal -->
		<div class="modal fade" id="edit_invoice_report" aria-hidden="true" role="dialog">
			<div class="modal-dialog modal-dialog-centered" role="document" >
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Ajouter un professionnel</h5>
						<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<form @submit.prevent="setData">
							<div class="row form-row">
								<div class="col-12 col-sm-6">
									<div class="form-group">
										<label>Nom</label>
										<input type="text" class="form-control" v-model="nom" value="">
									</div>
								</div>
								<div class="col-12 col-sm-6">
									<div class="form-group">
										<label>Prénom</label>
										<input type="text" class="form-control" v-model="prenom" value="">
									</div>
								</div>
								<div class="col-12 col-sm-6">
									<div class="form-group">
										<label>Email</label>
										<input type="email" class="form-control" v-model="email" value="">
									</div>
								</div>
								<div class="col-12 col-sm-6">
									<div class="form-group">
										<label>Date de naissance</label>
										<input type="date"  class="form-control" v-model="datenaissance" value="">
									</div>
								</div>
								<div class="col-12 col-sm-6">
									<div class="form-group">
										<label>Genre</label>
										<select name="genre" id="genre" v-model="genre" class="form-select">
                                            <option value="specialiste">Homme</option>
                                            <option value="administrateur">Femme</option>
                                            <option value="administrateur">Autre</option>
                                        </select>
									</div>
								</div>
                                <div class="col-12 col-sm-6">
									<div class="form-group">
										<label>Spécialité</label>
										<select name="type_utilisateur" id="specialite" v-model="specailite"  class="form-select">
                                            <option value="1">Cardiologue</option>
                                            <option value="2">Pédiatre</option>
                                            <option value="3">Ophtalmologue</option>
                                        </select>
									</div>
								</div>
                                <div class="col-12 col-sm-12">
									<div class="form-group">
										<label>Téléphone</label>
										<input type="text"  class="form-control" v-model="telephone" value="">
									</div>
								</div>
								<div class="col-12 col-sm-12">
									<div class="form-group">
										<label>Photo</label>
										<input type="file" class="form-control">
									</div>
								</div>
							</div>
							<button type="submit" class="btn btn-primary btn-block w-100" >Ajouter</button>
						</form>
					</div>
				</div>
			</div>
		</div>
		<!-- /Edit Details Modal -->

		<!-- Delete Modal -->
		<div class="modal fade" id="delete_modal" aria-hidden="true" role="dialog">
			<div class="modal-dialog modal-dialog-centered" role="document" >
				<div class="modal-content">
					<div class="modal-body">
						<div class="form-content p-2">
							<h4 class="modal-title">Supprimer</h4>
							<p class="mb-4">Voulez-vous vraiment supprimer?</p>
							<button type="button" class="btn btn-primary me-1">Supprimer </button>
							<button type="button" class="btn btn-danger" data-bs-dismiss="modal">Fermer</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- /Delete Modal -->
</template>
<script setup>
    import { ref, onMounted } from 'vue';
    import $ from 'jquery';
	import { API_BASE_URL } from '@/config';

    const data = ref({
        nom:'', prenom: '', genre: '', type_utilisateur: '', datenaissance: ''
    });
    const baseUrl = `${API_BASE_URL}`;
    const listUsers = ref([]);
    const user = JSON.parse(localStorage.getItem('userInfo'))
    // console.log(user.id);
    
    onMounted(() => {
        // Datatable
        if ($('.datatable').length > 0) {
            $('.datatable').DataTable({
            "bFilter": false,
            });
        }

        return {

        }
    })
          
    async function getData() {
        const res = await fetch(`${baseUrl}/users/par-centre/${user.centreid}`);
        const finalRes = await res.json();
        listUsers.value = finalRes;
    }

    async function setData() {
        console.log(data);
        // const res = await fetch(`${baseUrl}/register`, {
        //     method: 'post',
        //     headers: {
        //         "content-type": "application"
        //     },
        //     body: JSON.stringify(data)
        // });
        // if (!res) {
        //     // const message = `Erreur : ${res.status}`
        // }
    }
     
    getData()
</script>
<style>
    .fa-edit {
        font-size: 12px;
    }
    .fa-trash {
        font-size: 12px;
    }
</style>