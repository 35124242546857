<template>
  <!-- Faq -->
  <section class="faq-sec">
    <div class="container">
      <!-- Section Header -->
      <div class="section-header text-center aos" data-aos="fade-up">
        <span>QUESTIONS</span>
        <h2>Frequently Asked Questions</h2>
        <p class="sub-title">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod
        </p>
      </div>
      <!-- /Section Header -->
      <div class="row">
        <div class="col-md-12">
          <div class="faq-card" data-aos="fade-up">
            <div
              class="panel-group"
              id="accordion"
              role="tablist"
              aria-multiselectable="true"
            >
              <div class="faq-tab">
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingOne">
                    <p class="panel-title">
                      <a
                        class="collapsed"
                        data-bs-toggle="collapse"
                        data-bs-parent="#accordion"
                        href="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        Is the regular license the same thing as an editorial
                        license?
                      </a>
                    </p>
                  </div>
                  <div
                    id="collapseOne"
                    class="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordion"
                  >
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum.
                    </p>
                  </div>
                </div>
              </div>
              <div class="faq-tab">
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingTwo">
                    <p class="panel-title">
                      <a
                        class="collapsed"
                        data-bs-toggle="collapse"
                        data-bs-parent="#accordion"
                        href="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Is the regular license the same thing as an editorial
                        license?
                      </a>
                    </p>
                  </div>
                  <div
                    id="collapseTwo"
                    class="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordion"
                  >
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum.
                    </p>
                  </div>
                </div>
              </div>
              <div class="faq-tab">
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingThree">
                    <p class="panel-title">
                      <a
                        class="collapsed"
                        data-bs-toggle="collapse"
                        data-bs-parent="#accordion"
                        href="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Is the regular license the same thing as an editorial
                        license?
                      </a>
                    </p>
                  </div>
                  <div
                    id="collapseThree"
                    class="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordion"
                  >
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum.
                    </p>
                  </div>
                </div>
              </div>
              <div class="faq-tab">
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingFour">
                    <p class="panel-title">
                      <a
                        class="collapsed"
                        data-bs-toggle="collapse"
                        data-bs-parent="#accordion"
                        href="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        Is the regular license the same thing as an editorial
                        license?
                      </a>
                    </p>
                  </div>
                  <div
                    id="collapseFour"
                    class="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordion"
                  >
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Faq -->
</template>
