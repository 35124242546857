<template>
  <!-- Header -->
  <header class="header">
    <div class="header-fixed">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:void(0);">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/" class="navbar-brand logo">
            <img
              src="@/assets/img/website/teleassistance.png"
              class="img-fluid"
              alt="Logo"
            />
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img
                src="@/assets/img/website/teleassistance.png"
                class="img-fluid"
                alt="Logo"
              />
            </router-link>
            <a id="menu_close" class="menu-close" href="javascript:void(0);">
              <i class="fas fa-times"></i>
            </a>
          </div>
        </div>
        <ul class="nav header-navbar-rht">
          <!-- User Menu -->
          <li class="nav-item dropdown has-arrow logged-item">
            <a
              href="javascript:;"
              class="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <span class="user-img">
                <img
                  class="rounded-circle"
                  src="@/assets/img/website/user/user.jpg"
                  width="31"
                  alt="Darren Elder"
                />
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <div class="user-header">
                <div class="avatar avatar-sm">
                  <img
                    src="@/assets/img/website/user/user.jpg"
                    alt="User Image"
                    class="avatar-img rounded-circle"
                  />
                </div>
                <div class="user-text">
                  <h6>{{ prenom }} {{ nom }}</h6>
                  <p class="text-muted mb-0">Professionnel</p>
                </div>
              </div>
              <router-link class="dropdown-item" to="/mentor/index"
                >Tableau de Bord</router-link
              >
              <router-link class="dropdown-item" to="/mentor/profile-settings"
                >Centre d'assistance</router-link
              >
              <router-link class="dropdown-item" to="/mentor/profile-settings"
                >Recherche des centres d'assistance</router-link
              >
              <router-link class="dropdown-item" to="/mentor/profile-settings"
                >Centre d'appel</router-link
              >
              <router-link class="dropdown-item" to="/mentor/profile-settings"
                >Appels vidéos</router-link
              >
              <router-link class="dropdown-item" to="/mentor/profile-settings"
                >Configuration du profil</router-link
              >
              <router-link class="dropdown-item" to="/mentor/profile-settings"
                >Changer le mot de passe</router-link
              >
              <a class="dropdown-item" href="javascript:void(0);" @click="logout">Déconnexion</a>
            </div>
          </li>
          <!-- /User Menu -->
        </ul>
      </nav>
    </div>
  </header>
  <!-- /Header -->
</template>

<script>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from 'vuex';
import { computed } from 'vue';
import axios from "axios";
import NotificationSound from '@/components/breadcrumb/NotificationSound.vue';
import { API_BASE_URL } from '@/config';

export default {
  setup() {
    const store = useStore();

    const prenom = computed(() => store.getters.prenom);
    const nom = computed(() => store.getters.nom);
    const photo = computed(() => store.getters.photo);
    const specialiteid = computed(() => store.getters.specialiteid);
    const specialitenom = computed(() => store.getters.specialitenom);
    const centreid = computed(() => store.getters.centreid);
    const centrenom = computed(() => store.getters.centrenom);
    const genre = computed(() => store.getters.genre);
    const telephone = computed(() => store.getters.telephone);
    const date_naissance = computed(() => store.getters.date_naissance);
    
    const router = useRouter();

    const logout = async () => {
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
          // await axios.post('https://192.168.1.68:3444/logout', { refreshToken });
          await axios.post(`${API_BASE_URL}/logout`, { refreshToken });
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          router.push('/pages/login');
        }
      } catch (err) {
        console.error("Erreur lors de la déconnexion", err);
      }
    };

    onMounted(() => {
      document.querySelector(".header-fixed").insertAdjacentHTML('beforeend', '<div class="sidebar-overlay"></div>');
      document.getElementById("mobile_btn").addEventListener("click", function () {
        document.querySelector("main-wrapper").classList.toggle("slide-nav");
        document.querySelector(".sidebar-overlay").classList.toggle("opened");
        document.documentElement.classList.add("menu-opened");
      });

      document.querySelector(".sidebar-overlay").addEventListener("click", function () {
        document.documentElement.classList.remove("menu-opened");
        this.classList.remove("opened");
        document.querySelector("main-wrapper").classList.remove("slide-nav");
      });

      document.getElementById("menu_close").addEventListener("click", function () {
        document.documentElement.classList.remove("menu-opened");
        document.querySelector(".sidebar-overlay").classList.remove("opened");
        document.querySelector("main-wrapper").classList.remove("slide-nav");
      });

      if (window.innerWidth <= 991) {
        document.querySelectorAll(".main-nav a").forEach(menuItem => {
          menuItem.addEventListener("click", function (e) {
            if (this.parentElement.classList.contains("has-submenu")) {
              e.preventDefault();
            } else {
              document.querySelector(".main-wrapper").classList.remove("slide-nav");
              document.querySelector(".sidebar-overlay").classList.remove("opened");
              document.documentElement.classList.remove("menu-opened");
            }
            if (!this.classList.contains("submenu")) {
              this.closest("ul").querySelectorAll("ul").forEach(ul => ul.style.display = 'none');
              this.closest("ul").querySelectorAll("a").forEach(a => a.classList.remove("submenu"));
              this.nextElementSibling.style.display = 'block';
              this.classList.add("submenu");
            } else if (this.classList.contains("submenu")) {
              this.classList.remove("submenu");
              this.nextElementSibling.style.display = 'none';
            }
          });
        });
      }
    });

    return {
      logout,
      prenom,
      nom,
      photo,
      specialiteid,
      centreid,
      specialitenom,
      centrenom,
      genre,
      telephone,
      date_naissance
    };
  },
};
</script>
