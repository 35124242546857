<template>
  <!-- Sidebar -->
  <div class="sidebar" id="sidebar">
    <div class="sidebar-inner">
      <perfect-scrollbar
        class="scroll-area"
        :settings="settings"
        @ps-scroll-y="scrollHanle"
      >
        <div id="sidebar-menu" class="sidebar-menu">
          <ul>
            <!-- <li class="menu-title">
              <span><i class="fontsize" data-feather="home"></i> Menu</span>
            </li> -->
            <li>
              <router-link to="/admin/index"
                ><span><i style="font-size: 18px"  class="fa fa-tachometer-alt-average"></i></span>
                <span>Tableau de bord</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/affiliation"
                ><span><i style="font-size: 18px" class="fa fa-building-user"></i></span>
                <span>Affiliations</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/utilisateurs"
                ><span><i style="font-size: 18px"  class="fa fa-users"></i></span>
                <span>Professionnels</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/centredappel"
                ><span><i style="font-size: 18px"  class="fa fa-phone"></i></span>
                <span>Centre d'appel</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/profil"
                ><span><i style="font-size: 18px"  class="fa fa-user"></i></span>
                <span>Mon compte</span></router-link
              >
            </li>
          </ul>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
  <!-- /Sidebar -->
</template>
<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { onMounted } from "vue";
import feather from "feather-icons";

export default {
  components: {
    PerfectScrollbar,
  },
  setup() {
    onMounted(() => {
      feather.replace();
      let $wrapper = $(".main-wrapper");

      $("#sidebar-menu a").on("click", function (e) {
        if ($(this).parent().hasClass("submenu")) {
          e.preventDefault();
        } else {
          $wrapper.removeClass("slide-nav");
          $(".sidebar-overlay").removeClass("opened");
          $("html").removeClass("menu-opened");
        }
        if (!$(this).hasClass("subdrop")) {
          $("ul", $(this).parents("ul:first")).slideUp(350);
          $("a", $(this).parents("ul:first")).removeClass("subdrop");
          $(this).next("ul").slideDown(350);
          $(this).addClass("subdrop");
        } else if ($(this).hasClass("subdrop")) {
          $(this).removeClass("subdrop");
          $(this).next("ul").slideUp(350);
        }
      });

      $("#sidebar-menu ul li.submenu a.active")
        .parents("li:last")
        .children("a:first")
        .addClass("active")
        .trigger("click");

      // Mobile menu sidebar overlay

      $("body").append('<div class="sidebar-overlay"></div>');
      $(document).on("click", "#mobile_btn", function () {
        $wrapper.toggleClass("slide-nav");
        $(".sidebar-overlay").toggleClass("opened");
        $("html").addClass("menu-opened");
        return false;
      });

      // Sidebar overlay

      $(".sidebar-overlay").on("click", function () {
        $wrapper.removeClass("slide-nav");
        $(".sidebar-overlay").removeClass("opened");
        $("html").removeClass("menu-opened");
      });
    });
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
  },
  data() {
    return {
      settings: {
        suppressScrollX: true,
      },
      activeClass: "active",
      isLoading: false
    };
    //  isactive : true
  },
  mounted() {
    // Définissez isLoading à false une fois que le header est prêt
    this.isLoading = false;
  },
  methods: {
    scrollHanle(evt) {},
  },
};
</script>
<style>
svg.feather.feather-home.fontsize {
  width: 20px;
  height: 19px;
}
svg.feather.feather-file-text.fontsize {
  width: 20px;
  height: 19px;
}
svg.feather.feather-file.fontsize {
  width: 20px;
  height: 19px;
}

.scroll-area {
  position: relative;
  margin: auto;
  height: calc(100vh - 60px);
  background-color: transparent !important;
}
.sidebar-menu ul li a.active {
  background-color: transparent;
  color: #1879cd;
}
.sidebar-menu ul ul a.active {
  color: #1879cd;
  text-decoration: underline;
  background-color: unset;
}
</style>
