<template>
    			<!-- Mentors Near You -->
			<section class="mentors-near-you">
				<div class="container">
					<!-- Section Header -->
					<div class="section-header text-center aos" data-aos="fade-up">
						<span>POPULAR LOCATIONS</span>
						<h2>Find The Mentors Near You</h2>
						<p class="sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
					</div>
					<!-- /Section Header -->
					<div class="row justify-content-center">
						<div class="col-xl-3 col-md-5">
							<div class="mentor-card" data-aos="fade-up">
								<div class="mentor-card-img">
									<a href="javascript:void(0);"><img src="@/assets/img/city/city-01.jpg" class="img-fluid" alt=""></a>
								</div>
								<div class="find-location">
									<h5><a href="javascript:void(0);">Paris, France</a></h5>
									<div class="mentor-course">
										<div class="total-mentor">
											<img src="@/assets/img/icon/user-circle-icon.svg" alt="">
											<span>14 Mentors</span>
										</div>
										<div class="total-mentor">
											<img src="@/assets/img/icon/book-content-icon.svg	" alt="">
											<span>24 Courses</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-xl-5 col-md-7">
							<div class="mentor-card" data-aos="fade-up">
								<div class="mentor-card-img">
									<a href="javascript:void(0);"><img src="@/assets/img/city/city-03.jpg" class="img-fluid" alt=""></a>
								</div>
								<div class="find-location">
									<h5><a href="javascript:void(0);">London, England</a></h5>
									<div class="mentor-course">
										<div class="total-mentor">
											<img src="@/assets/img/icon/user-circle-icon.svg" alt="">
											<span>17 Mentors</span>
										</div>
										<div class="total-mentor">
											<img src="@/assets/img/icon/book-content-icon.svg	" alt="">
											<span>20 Courses</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-xl-4 col-md-6">
							<div class="mentor-card" data-aos="fade-up">
								<div class="mentor-card-img">
									<a href="javascript:void(0);"><img src="@/assets/img/city/city-02.jpg" class="img-fluid" alt=""></a>
								</div>
								<div class="find-location">
									<h5><a href="javascript:void(0);">Mumbai, India</a></h5>
									<div class="mentor-course">
										<div class="total-mentor">
											<img src="@/assets/img/icon/user-circle-icon.svg" alt="">
											<span>18 Mentors</span>
										</div>
										<div class="total-mentor">
											<img src="@/assets/img/icon/book-content-icon.svg	" alt="">
											<span>22 Courses</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-xl-5 col-md-6">
							<div class="mentor-card" data-aos="fade-up">
								<div class="mentor-card-img">
									<a href="javascript:void(0);"><img src="@/assets/img/city/city-04.jpg" class="img-fluid" alt=""></a>
								</div>
								<div class="find-location">
									<h5><a href="javascript:void(0);">Italy, Vienna</a></h5>
									<div class="mentor-course">
										<div class="total-mentor">
											<img src="@/assets/img/icon/user-circle-icon.svg" alt="">
											<span>12 Mentors</span>
										</div>
										<div class="total-mentor">
											<img src="@/assets/img/icon/book-content-icon.svg	" alt="">
											<span>19 Courses</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-xl-4 col-md-6">
							<div class="mentor-card" data-aos="fade-up">
								<div class="mentor-card-img">
									<a href="javascript:void(0);"><img src="@/assets/img/city/city-05.jpg" class="img-fluid" alt=""></a>
								</div>
								<div class="find-location">
									<h5><a href="javascript:void(0);">Elpo, Mexico</a></h5>
									<div class="mentor-course">
										<div class="total-mentor">
											<img src="@/assets/img/icon/user-circle-icon.svg" alt="">
											<span>22 Mentors</span>
										</div>
										<div class="total-mentor">
											<img src="@/assets/img/icon/book-content-icon.svg	" alt="">
											<span>18 Courses</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-xl-3 col-md-6">
							<div class="mentor-card" data-aos="fade-up">
								<div class="mentor-card-img">
									<a href="javascript:void(0);"><img src="@/assets/img/city/city-06.jpg" class="img-fluid" alt=""></a>
								</div>
								<div class="find-location">
									<h5><a href="javascript:void(0);">Buenos Aires, Atgentina</a></h5>
									<div class="mentor-course">
										<div class="total-mentor">
											<img src="@/assets/img/icon/user-circle-icon.svg" alt="">
											<span>16 Mentors</span>
										</div>
										<div class="total-mentor">
											<img src="@/assets/img/icon/book-content-icon.svg	" alt="">
											<span>21 Courses</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /Mentors Near You -->
    </template>