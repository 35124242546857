<template>
			<!-- <layout-header1></layout-header1> -->
			<!-- Page Content -->
			<div class="content">
				<div class="container-fluid">
					<div class="row">
						<!-- /content-left-->
						<div class="col-xl-6 col-lg-12 map-left">
							<div id="map" class="map-listing"></div>
							<!-- map-->
						</div>
						<!-- /map-right-->
						<div class="col-xl-6 col-lg-12 order-md-last order-sm-last order-last map-right">
							<div class="row align-items-center mb-4">
								<div class="col-md-6 col">
									<h4> Centres trouvé(s)</h4>
								</div>
								<div class="col-md-6 col-auto">
									<div class="view-icons">
										<router-link to="/particulier/accueil" class="grid-view active"><i class="fas fa-th-large"></i></router-link>
										<router-link to="/particulier/listecentre" class="list-view"><i class="fas fa-bars"></i></router-link>
									</div>
									<div class="sort-by d-sm-block d-none">
										<span class="sortby-fliter">
											<vue-select :options="options" />
										</span>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-6 col-md-4 col-xl-6" v-for="item in displayedCentres" :key="item.id">
									<div class="profile-widget">
										<div class="user-avatar">
											<router-link :to="'/particulier/profil/' + item.id">
												<img class="img-fluid fluids" alt="Image du centre d'assistance" :src="getCentreImageUrl(item.logo)">
											</router-link>
											<a href="javascript:void(0)" class="fav-btn">
												<i class="far fa-bookmark"></i>
											</a>
										</div>
										<div class="pro-content">
											<h3 class="title">
												<router-link :to="'/particulier/profil/' + item.id">{{item.nom}}</router-link> 
												<i class="fas fa-check-circle verified"></i>
											</h3>
											<p class="speciality">{{item.typecentre}}</p>
											<ul class="available-info">
												<li>
													<i class="fas fa-globe"></i>{{item.ville + ', ' + item.pays}}
												</li>
												<li>
													<i class="fas fa-map-marker-alt"></i>{{item.adresse}}
												</li>
											</ul>
											<div class="row row-sm">
												<div class="col-6">
													<!-- <router-link :to="'/particulier/profil/' + item.id" class="btn view-btn">Voir la fiche</router-link> -->
												    <router-link to="/calls" class="btn view-btn">Contacter le centre</router-link>
												</div>
												<div class="col-6">
													<!-- <router-link :to="'#'" @click="initiateCall(item.id)" class="btn view-btn">Appeler</router-link> -->
												    <!-- <CallButton /> -->
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="load-more text-center" v-if="displayedCentres.length < centres.length">
								<button class="btn btn-primary btn-sm" @click="loadMore">Voir plus</button>
							</div>
						</div>
					</div>
					<!-- /row-->
				</div>
			</div>
			<!-- /Page Content -->
			<!-- <layout-footer></layout-footer> -->
</template>

<script>
	import { useStore } from 'vuex';
	import { computed, onMounted } from 'vue';
	import { API_BASE_URL } from '@/config';
	let infowindow = null;
	let map, current = 0;
	import L from 'leaflet';
	import 'leaflet/dist/leaflet.css';
	import markerIcon from 'leaflet/dist/images/marker-icon.png';
	import markerShadow from 'leaflet/dist/images/marker-shadow.png';
	
	delete L.Icon.Default.prototype._getIconUrl;
	L.Icon.Default.mergeOptions({
		iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
		iconUrl: require('leaflet/dist/images/marker-icon.png'),
		shadowUrl: require('leaflet/dist/images/marker-shadow.png')
	});

	export default {
		setup() {
			const store = useStore();

			const id = computed(() => store.getters.id);
			const prenom = computed(() => store.getters.prenom);
			const nom = computed(() => store.getters.nom);
			const photo = computed(() => store.getters.photo);
			const specialiteid = computed(() => store.getters.specialiteid);
			const specialitenom = computed(() => store.getters.specialitenom);
			const centreid = computed(() => store.getters.centreid);
			const centrenom = computed(() => store.getters.centrenom);
			const genre = computed(() => store.getters.genre);
			const telephone = computed(() => store.getters.telephone);
			const date_naissance = computed(() => store.getters.date_naissance);
			const uuid = computed(() => store.getters.uuid);
			
			onMounted(() => {
				store.dispatch('initializeUUID');
			});

			return {
				id,
				prenom,
				nom,
				photo,
				specialiteid,
				centreid,
				specialitenom,
				centrenom,
				genre,
				telephone,
				date_naissance,
				uuid
			};
		},
		data() {
			return {
				map: null,
				centres: [],
				displayedCentres: [],
				itemsToShow: 4, // Nombre d'items à voir "Voir plus" click
				slider: null,
				defaultProfileImage: require('@/assets/img/user/1.png'),
				bounds: new google.maps.LatLngBounds(),
				lat: '53.470692',
				lng: '-2.220328',
				options: ["Select", "Rating", "Popular", "Latest", "Free"],
				markers: [],
				isMapInitialized: false
			}
		},
		mounted() {
			this.initMap();
			setTimeout(() => {
				this.getCentre();
			}, 500);
			// this.showmap();
		},
		methods: {
			getCentreImageUrl(profileImage) {
				return `${API_BASE_URL}/uploads/logos/${profileImage}` || this.defaultProfileImage;
			},
			async getCentre() {
				// const res = await fetch("https://192.168.100.14:3444/centres/urgence");
				const res = await fetch(`${API_BASE_URL}/centres/urgence`);
				const finalRes = await res.json();
				this.centres = finalRes.centres;
				this.loadMore(); // Load initial set of centres
				// this.setMarkers(map, finalRes.centres);
				this.setMarkers(this.map, finalRes.centres);
			},
			loadMore() {
				const start = this.displayedCentres.length;
				const end = start + this.itemsToShow;
				this.displayedCentres = this.displayedCentres.concat(this.centres.slice(start, end));
			},
			// setMarkers(map, centres) {
			// 	centres.forEach(centre => {
			// 		const marker = new google.maps.Marker({
			// 			position: { lat: parseFloat(centre.latitude), lng: parseFloat(centre.longitude) },
			// 			map,
			// 			title: centre.nom
			// 		});
			// 		const infowindow = new google.maps.InfoWindow({
			// 			content: `
			// 				<div>
			// 					<h5>${centre.nom}</h5>
			// 					<p>${centre.typecentre}</p>
			// 					<p>${centre.adresse}</p>
			// 					<p>${centre.ville}, ${centre.pays}</p>
			// 				</div>
			// 			`
			// 		});
			// 		marker.addListener('click', () => {
			// 			infowindow.open(map, marker);
			// 		});
			// 		this.bounds.extend(marker.position);
			// 	});
			// 	map.fitBounds(this.bounds);
			// },
			// showmap() {
			// 	const myLatlng = new google.maps.LatLng(this.lat, this.lng);
				// const mapOptions = {
				// 	zoom: 5,
				// 	center: myLatlng,
				// 	mapTypeId: google.maps.MapTypeId.ROADMAP,
				// 	zoomControl: true,
				// 	mapTypeControl: false,
				// 	scaleControl: false,
				// 	streetViewControl: false,
				// 	rotateControl: false,
				// 	fullscreenControl: false,
				// 	styles: [{ featureType: 'poi', stylers: [{ visibility: 'off' }] }]
				// }
			// 	map = new google.maps.Map(document.getElementById('map'), mapOptions);
			// 	infowindow = new google.maps.InfoWindow();
			// },
			initMap() {
				const mapOptions = {
					zoom: 5,
					center: [53.470692, -2.220328],
					zoomControl: true,
					zoomSnap: 0.5,
					zoomDelta: 0.5,
					attributionControl: false,
					scrollWheelZoom: false,
					doubleClickZoom: false,
					dragging: false,
					layers: [],
					maxBounds: L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180)),
					preferCanvas: true,
					zoomControl: true,
					zoomControlPosition: 'topleft',
					styles: [
					{
						featureType: 'poi',
						stylers: [
						{
							visibility: 'off'
						}
						]
					}
					]
				};

				this.map = L.map('map', mapOptions).setView(mapOptions.center, mapOptions.zoom);

				L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
					attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				}).addTo(this.map);
				this.map.on('load', () => {
					this.isMapInitialized = true;
					this.setMarkers(this.map, this.centres);
				});
			},
			setMarkers(map, centres) {
				this.markers = [];

				centres.forEach(centre => {
					const marker = L.marker([parseFloat(centre.latitude), parseFloat(centre.longitude)]).addTo(map);

					marker.bindTooltip(centre.nom, {
					permanent: false,
					direction: 'top'
					});

					marker.bindPopup(`
					<h5>${centre.nom}</h5>
					<p>${centre.typecentre}</p>
					<p>${centre.adresse}</p>
					<p>${centre.ville}, ${centre.pays}</p>
					`);

					this.markers.push(marker);
				});

				if (this.markers.length > 0) {
					this.map.fitBounds(this.markers.map(marker => marker.getLatLng()));
				}
			},
		},
	}
	</script>
	
	<style>
	.fluids {
		height: 250px !important;
	}
	</style>