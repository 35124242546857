import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const config = {
	apiKey: "AIzaSyDDD6iTCrjH9AGrYqrMa3QG1D9psqpNPDo",
	authDomain: "telemed-b4ac9.firebaseapp.com",
	projectId: "telemed-b4ac9",
	storageBucket: "telemed-b4ac9.appspot.com",
	messagingSenderId: "276596297993",
	appId: "1:276596297993:web:cd3316eeb3ba4d6bef83d8"
};

initializeApp(config)

export const firestoreDb = getFirestore()
export const realtimeDb = getDatabase()
export const storage = getStorage()
