<template>
    			<!-- Statistics Section -->
			<section class="section statistics-section-eight">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-12 col-lg-4 col-md-6 aos" data-aos="fade-up">
							<div class="statistics-list-eight">
								<div class="statistics-icon-eight client">
									<i class="fas fa-street-view"></i>
								</div>
								<div class="statistics-content-eight">
									<span>500+</span>
									<h3>Happy Clients</h3>
								</div>
							</div>
						</div>
						<div class="col-12 col-lg-4 col-md-6 aos" data-aos="fade-up">
							<div class="statistics-list-eight">
								<div class="statistics-icon-eight appointment">
									<i class="fas fa-history"></i>
								</div>
								<div class="statistics-content-eight">
									<span>120+</span>
									<h3>Online Appointments</h3>
								</div>
							</div>
						</div>
						<div class="col-12 col-lg-4 col-md-6 aos" data-aos="fade-up">
							<div class="statistics-list-eight">
								<div class="statistics-icon-eight job">
									<i class="fas fa-user-check"></i>
								</div>
								<div class="statistics-content-eight">
									<span>100%</span>
									<h3>Job Satisfaction</h3>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /Statistics Section -->
    </template>