<template>
  <div class="main-wrapper main-wrapper-four">
    <layout-header-four />
    <HomeBannerFour />
    <CategoriesFour />
    <PopularCourseFour />
    <KnowledgeFour />
    <TrendingCourseFour />
    <TestimonialFour />
    <FeaturedInstructorFour />
    <FaqFour />
    <BlogFour />
    <NewCourseFour />
    <layout-footer-four />
  </div>
</template>
