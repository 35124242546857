<template>
    			<!-- New Course subscribe -->
			<section class="new-course great-option">
				<div class="container">
					<div class="course-two-bg">
						<img src="@/assets/img/bg/home-two-bg-07.png" class="img-fluid subscribe-bg" alt="">
					</div>
					<div class="head-join-us">
						<div class="row">
							<div class="col-xl-8" data-aos="fade-up">
								<h2>Find Your Next Great Opportunity!</h2>
							</div>
							<div class="col-xl-4">
								<div class="reg-log-btn" data-aos="fade-up">
									<router-link to="/pages/register" class="reg-btn">Register Now</router-link>
									<router-link to="/pages/login" class="log-btn">Sign In</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /New Course -->
    </template>