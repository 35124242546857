<template>
  <!-- New Course subscribe -->
  <section class="new-course subscribe-course">
    <div class="container">
      <div class="section-header section-head-left aos" data-aos="fade-up">
        <div class="section-head-five">
          <h4><span></span>Join With Us</h4>
          <h2>Join Our Courses & Get More Benefits</h2>
          <p>
            Do you want to move on next step? Choose your most popular leaning
            mentors, it will help you to achieve your professional goals.
          </p>
        </div>
      </div>
      <div class="new-course-five">
        <div class="row">
          <div class="col-lg-6 col-md-12 d-flex align-items-center">
            <div class="join-instructor">
              <div class="ins-img">
                <img src="@/assets/img/mendor/join-ins-01.jpg" alt="" />
              </div>
              <div class="ins-content">
                <h3>
                  <router-link to="/mentee/mentor-search">As An Instructor?</router-link>
                </h3>
                <p>
                  Agent hen an unknown printer took a galley of type and
                  scramble it to make a type specimen book.
                </p>
                <div class="join-btn">
                  <router-link to="/mentee/mentor-search">Join With Us</router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 d-flex align-items-center">
            <div class="join-instructor">
              <div class="ins-img">
                <img src="@/assets/img/mendor/join-ins-02.jpg" alt="" />
              </div>
              <div class="ins-content">
                <h3><router-link to="/mentee/mentor-search">As An Student?</router-link></h3>
                <p>
                  Agent hen an unknown printer took a galley of type and
                  scramble it to make a type specimen book.
                </p>
                <div class="join-btn">
                  <router-link to="/mentee/mentor-search">Join With Us</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- New Course -->
</template>
