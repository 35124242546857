<template>
  <div class="body-home-one">
    <div class="main-wrapper main-wrapper-two">
        <layout-header-two />
          <HomeBannerTwo />
          <CategoriesTwo />
          <TrendingCourseTwo />
          <MentorTwo />
          <SelectionCourseTwo />
          <FeaturedInstructorTwo />
          <JobLocationTwo/>
          <FaqTwo />
          <AboutTwo/>
          <BlogTwo/>
          <NewCourseTwo/>
        <layout-footer-two />
    </div>
    </div>
</template>


