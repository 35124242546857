<template>
  <!-- Top Rating Instructor four -->
  <section class="rating-instructor-four">
    <div class="container">
      <div class="section-header h-four text-center aos" data-aos="fade-up">
        <span>EXPERT</span>
        <h2>Top Rating Instructor</h2>
        <p class="sub-title">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget aenean
          accumsan bibendum gravida maecenas augue elementum et neque.
          Suspendisse imperdiet.
        </p>
      </div>
      <div class="instructor-four-face aos" data-aos="fade-up">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="large-team">
              <div class="student-img">
                <router-link to="/mentee/mentor-profile"
                  ><img src="@/assets/img/user/user-26.jpg" alt=""
                /></router-link>
                <div class="rating-student-four">
                  <div class="expert-student">
                    <ul class="student-view-four">
                      <li><i class="fas fa-users"></i></li>
                      <li>40 Students</li>
                    </ul>
                  </div>
                  <div class="expert-social-icon d-flex align-items-center">
                    <ul class="social-icon-four">
                      <li>
                        <a
                          href="javascript:;"
                          class="social-icon-space d-flex align-items-center justify-content-center"
                          target="_blank"
                          ><i class="fab fa-linkedin-in"></i
                        ></a>
                      </li>
                      <li>
                        <a
                          href="javascript:;"
                          class="social-icon-space d-flex align-items-center justify-content-center"
                          target="_blank"
                          ><i class="fab fa-facebook-f"></i
                        ></a>
                      </li>
                      <li>
                        <a
                          href="javascript:;"
                          class="social-icon-space d-flex align-items-center justify-content-center"
                          target="_blank"
                          ><i class="fab fa-twitter"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="team-name">
                <h4>
                  <router-link to="/mentee/mentor-profile">Jack Wilson</router-link>
                </h4>
                <span class="designation">Developer</span>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="large-team">
              <div class="student-img">
                <router-link to="/mentee/mentor-profile"
                  ><img src="@/assets/img/user/user-27.jpg" alt=""
                /></router-link>
                <div class="rating-student-four">
                  <div class="expert-student">
                    <ul class="student-view-four">
                      <li><i class="fas fa-users"></i></li>
                      <li>30 Students</li>
                    </ul>
                  </div>
                  <div class="expert-social-icon d-flex align-items-center">
                    <ul class="social-icon-four">
                      <li>
                        <a
                          href="javascript:;"
                          class="social-icon-space d-flex align-items-center justify-content-center"
                          target="_blank"
                          ><i class="fab fa-linkedin-in"></i
                        ></a>
                      </li>
                      <li>
                        <a
                          href="javascript:;"
                          class="social-icon-space d-flex align-items-center justify-content-center"
                          target="_blank"
                          ><i class="fab fa-facebook-f"></i
                        ></a>
                      </li>
                      <li>
                        <a
                          href="javascript:;"
                          class="social-icon-space d-flex align-items-center justify-content-center"
                          target="_blank"
                          ><i class="fab fa-twitter"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="team-name">
                <h4>
                  <router-link to="/mentee/mentor-profile">Daziy Millar</router-link>
                </h4>
                <span class="designation">PHP Expert</span>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="large-team">
              <div class="student-img">
                <router-link to="/mentee/mentor-profile"
                  ><img src="@/assets/img/user/user-28.jpg" alt=""
                /></router-link>
                <div class="rating-student-four">
                  <div class="expert-student">
                    <ul class="student-view-four">
                      <li><i class="fas fa-users"></i></li>
                      <li>50 Students</li>
                    </ul>
                  </div>
                  <div class="expert-social-icon d-flex align-items-center">
                    <ul class="social-icon-four">
                      <li>
                        <a
                          href="javascript:;"
                          class="social-icon-space d-flex align-items-center justify-content-center"
                          target="_blank"
                          ><i class="fab fa-linkedin-in"></i
                        ></a>
                      </li>
                      <li>
                        <a
                          href="javascript:;"
                          class="social-icon-space d-flex align-items-center justify-content-center"
                          target="_blank"
                          ><i class="fab fa-facebook-f"></i
                        ></a>
                      </li>
                      <li>
                        <a
                          href="javascript:;"
                          class="social-icon-space d-flex align-items-center justify-content-center"
                          target="_blank"
                          ><i class="fab fa-twitter"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="team-name">
                <h4>
                  <router-link to="/mentee/mentor-profile">James </router-link>
                </h4>
                <span class="designation">Web Developer</span>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="large-team">
              <div class="student-img">
                <img src="@/assets/img/user/user-29.jpg" alt="" />
                <div class="rating-student-four">
                  <div class="expert-student">
                    <ul class="student-view-four">
                      <li><i class="fas fa-users"></i></li>
                      <li>40 Students</li>
                    </ul>
                  </div>
                  <div class="expert-social-icon d-flex align-items-center">
                    <ul class="social-icon-four">
                      <li>
                        <a
                          href="javascript:;"
                          class="social-icon-space d-flex align-items-center justify-content-center"
                          target="_blank"
                          ><i class="fab fa-linkedin-in"></i
                        ></a>
                      </li>
                      <li>
                        <a
                          href="javascript:;"
                          class="social-icon-space d-flex align-items-center justify-content-center"
                          target="_blank"
                          ><i class="fab fa-facebook-f"></i
                        ></a>
                      </li>
                      <li>
                        <a
                          href="javascript:;"
                          class="social-icon-space d-flex align-items-center justify-content-center"
                          target="_blank"
                          ><i class="fab fa-twitter"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="team-name">
                <h4>
                  <router-link to="/mentee/mentor-profile">David Lee</router-link>
                </h4>
                <span class="designation">Developer</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="explore-more text-center aos" data-aos="fade-up">
        <router-link to="/mentee/mentor-profile" class="btn bg-explore"
          >Show All Categories</router-link
        >
      </div>
    </div>
  </section>
  <!-- /Top Rating Instructor four -->
</template>
