<template>
<!-- Categories -->
    <div class="card category-widget">
        <div class="card-header">
            <h4 class="card-title">Blog Categories</h4>
        </div>
        <div class="card-body">
            <ul class="categories">
                <li><a href="javascript:;">HTML <span>(62)</span></a></li>
                <li><a href="javascript:;">Css <span>(27)</span></a></li>
                <li><a href="javascript:;">Java Script <span>(41)</span></a></li>
                <li><a href="javascript:;">Photoshop <span>(16)</span></a></li>
                <li><a href="javascript:;">Wordpress <span>(55)</span></a></li>
                <li><a href="javascript:;">VB <span>(07)</span></a></li>
            </ul>
        </div>
    </div>
<!-- /Categories -->
</template>