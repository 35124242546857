<template>
<section class="section how-it-works">
				<div class="container">
					<div class="section-header text-center aos" data-aos="fade-up">
						<span>Mentoring Flow</span>
						<h2>How does it works ?</h2>
						<p class="sub-title">Are you looking to join online institutions? Now it's very simple, Sign up with mentoring</p>
					</div>
					<div class="row justify-content-center">
						<div class="col-12 col-md-6 col-lg-4">
							<div class="feature-box text-center aos" data-aos="fade-up">					
								<div class="feature-header">
									<div class="feature-icon">
										<span class="circle"></span>
										<i><img src="@/assets/img/icon-1.png" alt=""></i>
									</div>		
									<div class="feature-cont">	
										<div class="feature-text">Sign up</div>
									</div>
								</div>
								<p class="mb-0">Are you looking to join online Learning? Now it's very simple, Now Sign up</p>
							</div>
						</div>
						<div class="col-12 col-md-6 col-lg-4">
							<div class="feature-box feature-box-two text-center aos" data-aos="fade-up">					
								<div class="feature-header">
									<div class="feature-icon">
										<span class="circle"></span>
										<i><img src="@/assets/img/icon-2.png" alt=""></i>
									</div>	
									<div class="feature-cont">
										<div class="feature-text">Collaborate</div>
									</div>
								</div>
								<p class="mb-0">Collaborate on your own timing, by scheduling with mentor booking</p>
							</div>
						</div>
						<div class="col-12 col-md-6 col-lg-4">
							<div class="feature-box feature-box-three text-center aos" data-aos="fade-up">					
								<div class="feature-header">
									<div class="feature-icon">
										<span class="circle"></span>
										<i><img src="@/assets/img/icon-3.png" alt=""></i>
									</div>	
									<div class="feature-cont">
										<div class="feature-text">Improve & Get Back</div>
									</div>
								</div>
								<p class="mb-0">you can gather different skill set, and you can become mentor too</p>
							</div>
						</div>
						
					</div>
				</div>
			</section>
</template>