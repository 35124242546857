<template>
    <!-- Sidebar -->
        <div class="profile-sidebar">
            <div class="user-widget">
                <div class="avatar avatar-xxl">
                  <img
                    src="@/assets/img/website/user/user.jpg"
                    alt="User Image"
                    class="avatar-img rounded-circle"
                  />
                </div><br><br>
                <div class="user-info-cont">
                    <h4 class="usr-name">{{ prenom }} {{ nom }}</h4>
                </div>
            </div>
            <div class="custom-sidebar-nav">
                <ul>
                    <li><router-link to="/mentor/index" v-bind:class="{'active': currentPath == 'mentor-dashboard'}"><i class="fas fa-home"></i>Tableau de bord <span><i class="fas fa-chevron-right"></i></span></router-link></li>
                    <li><router-link to="/professionnels" v-bind:class="{'active': currentPath == 'professionnels'}"><i class="fas fa-users"></i>Professionnels <span><i class="fas fa-chevron-right"></i></span></router-link></li>
                    <li><router-link to="/mentor/centres-assistance" v-bind:class="{'active': currentPath == 'centre-assistance'}"><i class="fas fa-clock"></i>FOSA <span><i class="fas fa-chevron-right"></i></span></router-link></li>
                    <li><router-link to="/mentor/chat" v-bind:class="{'active': currentPath == 'mentor-chat'}"><i class="fas fa-comments" ></i>Messages <span><i class="fas fa-chevron-right"></i></span></router-link></li>
                    <li><router-link to="/mentor/mentor-profile" v-bind:class="{'active': currentPath == 'mentor-profile-settings'}"><i class="fas fa-user-cog"></i>Profil <span><i class="fas fa-chevron-right"></i></span></router-link></li>
                    <!-- <li><router-link to="/mentee/change-password" v-bind:class="{'active': currentPath == 'mentee-change-password'}"><i class="fab fa-blogger-b"></i>Changer le mot de passe <span><i class="fas fa-chevron-right"></i></span></router-link></li> -->
                    <li><router-link to="/pages/login"><i class="fas fa-sign-out-alt"></i>Déconnexion <span><i class="fas fa-chevron-right"></i></span></router-link></li>
                </ul>
            </div>
        </div>
    <!-- /Sidebar -->
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
    computed: {
        currentPath() {
            return this.$route.name;
        }
    },
    setup() {
        const store = useStore();

        const prenom = computed(() => store.getters.prenom);
        const nom = computed(() => store.getters.nom);
        const photo = computed(() => store.getters.photo);
        const specialite = computed(() => store.getters.specialite);
        const centre = computed(() => store.getters.centre);
        const genre = computed(() => store.getters.genre);
        const telephone = computed(() => store.getters.telephone);
        const date_naissance = computed(() => store.getters.date_naissance);
    
        return {
            prenom,
            nom,
            photo,
            specialite,
            centre,
            genre,
            telephone,
            date_naissance
        };
    }
}
</script>