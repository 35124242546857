<template>
  <!-- Path section start -->
  <section class="section path-section-eight">
    <div class="section-header-eight text-center aos" data-aos="fade-up">
      <div class="container">
        <span>Choose the</span>
        <h2>Different All Learning Paths</h2>
        <p class="sub-title">
          Are you looking to join online institutions? Now it's very simple,
          Sign up with mentoring
        </p>
        <div class="sec-dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    <div class="course-sec">
      <div class="container">
        <div class="row justify-content-center">
          <!-- Course Item -->
          <div class="col-12 col-md-4 col-lg-3 aos" data-aos="fade-up">
            <div class="course-item">
              <router-link to="/mentee/mentor-search" class="course-img">
                <div class="image-col-merge">
                  <img src="@/assets/img/course/course-01.jpg" alt="learn" />
                  <div class="course-text">
                    <h5>Digital Marketer</h5>
                    <div class="d-flex justify-content-between">
                      <p>400 Mentors</p>
                      <p>10 Courses</p>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <!-- /Course Item -->

          <!-- Course Item -->
          <div class="col-12 col-md-4 col-lg-3 aos" data-aos="fade-up">
            <div class="course-item">
              <router-link to="/mentee/mentor-search" class="course-img">
                <div class="image-col-merge">
                  <img src="@/assets/img/course/course-02.jpg" alt="learn" />
                  <div class="course-text">
                    <h5>Ui designer</h5>
                    <div class="d-flex justify-content-between">
                      <p>300 Mentors</p>
                      <p>12 Courses</p>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <!-- /Course Item -->

          <!-- Course Item -->
          <div class="col-12 col-md-4 col-lg-3 aos" data-aos="fade-up">
            <div class="course-item">
              <router-link to="/mentee/mentor-search" class="course-img">
                <div class="image-col-merge">
                  <img src="@/assets/img/course/course-03.jpg" alt="learn" />
                  <div class="course-text">
                    <h5>IT Security</h5>
                    <div class="d-flex justify-content-between">
                      <p>200 Mentors</p>
                      <p>20 Courses</p>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <!-- /Course Item -->

          <!-- Course Item -->
          <div class="col-12 col-md-4 col-lg-3 aos" data-aos="fade-up">
            <div class="course-item">
              <router-link to="/mentee/mentor-search" class="course-img">
                <div class="image-col-merge">
                  <img src="@/assets/img/course/course-04.jpg" alt="learn" />
                  <div class="course-text">
                    <h5>Front-End Developer</h5>
                    <div class="d-flex justify-content-between">
                      <p>100 Mentors</p>
                      <p>11 Courses</p>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <!-- /Course Item -->

          <!-- Course Item -->
          <div class="col-12 col-md-4 col-lg-3 aos" data-aos="fade-up">
            <div class="course-item">
              <router-link to="/mentee/mentor-search" class="course-img">
                <div class="image-col-merge">
                  <img src="@/assets/img/course/course-05.jpg" alt="learn" />
                  <div class="course-text">
                    <h5>Web Developer</h5>
                    <div class="d-flex justify-content-between">
                      <p>500 Mentors</p>
                      <p>30 Courses</p>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <!-- /Course Item -->

          <!-- Course Item -->
          <div class="col-12 col-md-4 col-lg-3 aos" data-aos="fade-up">
            <div class="course-item">
              <router-link to="/mentee/mentor-search" class="course-img">
                <div class="image-col-merge">
                  <img src="@/assets/img/course/course-06.jpg" alt="learn" />
                  <div class="course-text">
                    <h5>Administrator</h5>
                    <div class="d-flex justify-content-between">
                      <p>200 Mentors</p>
                      <p>10 Courses</p>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <!-- /Course Item -->

          <!-- Course Item -->
          <div class="col-12 col-md-4 col-lg-3 aos" data-aos="fade-up">
            <div class="course-item">
              <router-link to="/mentee/mentor-search" class="course-img">
                <div class="image-col-merge">
                  <img src="@/assets/img/course/course-07.jpg" alt="learn" />
                  <div class="course-text">
                    <h5>Project Manager</h5>
                    <div class="d-flex justify-content-between">
                      <p>600 Mentors</p>
                      <p>40 Courses</p>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <!-- /Course Item -->

          <!-- Course Item -->
          <div class="col-12 col-md-4 col-lg-3 aos" data-aos="fade-up">
            <div class="course-item">
              <router-link to="/mentee/mentor-search" class="course-img">
                <div class="image-col-merge">
                  <img src="@/assets/img/course/course-08.jpg" alt="learn" />
                  <div class="course-text">
                    <h5>PHP Developer</h5>
                    <div class="d-flex justify-content-between">
                      <p>400 Mentors</p>
                      <p>10 Courses</p>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <!-- /Course Item -->
        </div>
        <div class="view-all text-center aos" data-aos="fade-up">
          <router-link to="/mentee/mentor-search" class="btn btn-primary btn-view"
            >View All</router-link
          >
        </div>
      </div>
    </div>
  </section>
  <!-- Path section end -->
</template>
