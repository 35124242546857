<template>
  <!-- Header -->
  <!-- <LoadingSpinner v-if="isLoading" /> -->
  <div class="header">
    <!-- Logo -->
    <div class="header-left">
      <router-link to="/admin/index" class="logo">
        <img src="@/assets/img/website/teleassistance.png"  class="img-fluid" alt="Logo" />
      </router-link>
      <router-link to="/admin/index" class="logo logo-small">
        <img
          src="@/assets/img/website/faviconteleassistance.png" class="img-fluid"
          alt="Logo"
          style="width:30px; height:30px"
        />
      </router-link>
    </div>
    <!-- /Logo -->
    <a href="javascript:void(0);" id="toggle_btn">
      <i class="fa fa-align-left"></i>
    </a>
    <div class="top-nav-search">
      <!-- <form>
        <input type="text" class="form-control" placeholder="Search here" />
        <button class="btn" type="submit"><i class="fa fa-search"></i></button>
      </form> -->
      <h3 style="position: relative; top: 15px; font-size: 24px; margin: 0 auto; text-transform: uppercase;">Portail Administratif de Téléassistance : <span style="color: #1c5fc6; font-weight: 800 ">{{centrenom}}</span></h3>
    </div>
    <!-- Mobile Menu Toggle -->
    <a class="mobile_btn" id="mobile_btn">
      <i class="fa fa-bars"></i>
    </a>
    <!-- /Mobile Menu Toggle -->
    <!-- Header Right Menu -->
    <ul class="nav user-menu">
      <!-- Notifications -->
      <li class="nav-item dropdown noti-dropdown">
        <a href="javascript:;" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
          <i class="far fa-bell"></i> <span class="badge badge-pill">3</span>
        </a>
        <div class="dropdown-menu notifications">
          <div class="topnav-dropdown-header">
            <span class="notification-title">Notifications</span>
            <a href="javascript:void(0)" class="clear-noti"> Clear All </a>
          </div>
          <div class="noti-content">
            <ul class="notification-list">
              <li class="notification-message">
                <a href="javascript:;">
                  <div class="media d-flex">
                    <span class="avatar avatar-sm flex-shrink-0">
                      <img
                        class="avatar-img rounded-circle"
                        alt="User Image"
                        src="@/assets/img/user/user.jpg"
                      />
                    </span>
                    <div class="media-body flex-grow-1">
                      <p class="noti-details">
                        <span class="noti-title">Jonathan Doe</span> Schedule
                        <span class="noti-title">his appointment</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">4 mins ago</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="notification-message">
                <a href="javascript:;">
                  <div class="media d-flex">
                    <span class="avatar avatar-sm flex-shrink-0">
                      <img
                        class="avatar-img rounded-circle"
                        alt="User Image"
                        src="@/assets/img/user/user1.jpg"
                      />
                    </span>
                    <div class="media-body flex-grow-1">
                      <p class="noti-details">
                        <span class="noti-title">Julie Pennington</span> has
                        booked her appointment to
                        <span class="noti-title">Ruby Perrin</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">6 mins ago</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="notification-message">
                <a href="javascript:;">
                  <div class="media d-flex">
                    <span class="avatar avatar-sm flex-shrink-0">
                      <img
                        class="avatar-img rounded-circle"
                        alt="User Image"
                        src="@/assets/img/user/user2.jpg"
                      />
                    </span>
                    <div class="media-body flex-grow-1">
                      <p class="noti-details">
                        <span class="noti-title">Tyrone Roberts</span> sent a
                        amount of $210 for his
                        <span class="noti-title">appointment</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">8 mins ago</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="notification-message">
                <a href="javascript:;">
                  <div class="media d-flex">
                    <span class="avatar avatar-sm flex-shrink-0">
                      <img
                        class="avatar-img rounded-circle"
                        alt="User Image"
                        src="@/assets/img/user/user4.jpg"
                      />
                    </span>
                    <div class="media-body flex-grow-1">
                      <p class="noti-details">
                        <span class="noti-title">Patricia Manzi</span> send a
                        message <span class="noti-title"> to his Mentee</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">12 mins ago</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div class="topnav-dropdown-footer">
            <a href="javascript:;">View all Notifications</a>
          </div>
        </div>
      </li>
      <!-- /Notifications -->

      <!-- User Menu -->
      <li class="nav-item dropdown has-arrow">
        <a href="javascript:;" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
          <span class="user-img"
            ><img
              class="rounded-circle"
              src="@/assets/img/admin/profiles/avatar-12.jpg"
              width="31"
              alt="Ryan Taylor"
          /></span>
        </a>
        <div class="dropdown-menu">
          <div class="user-header">
            <div class="avatar avatar-sm">
              <img
                src="@/assets/img/admin/profiles/avatar-12.jpg"
                alt="User Image"
                class="avatar-img rounded-circle"
              />
            </div>
            <div class="user-text">
              <h6>{{ prenom }} {{ nom }}</h6>
              <p class="text-muted mb-0">Administrateur</p>
            </div>
          </div>
          <router-link class="dropdown-item" to="/admin/profil"
            >Mon compte</router-link
          >
          <!-- <router-link class="dropdown-item" to="/admin/settings"
            >Paramétrage</router-link
          > -->
          <a class="dropdown-item" href="javascript:void(0);" @click="logout">Déconnexion</a>
        </div>
      </li>
      <!-- /User Menu -->
    </ul>
    <!-- /Header Right Menu -->
  </div>
  <!-- /Header -->
</template>
<script>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from 'vuex';
import { computed } from 'vue';
import axios from "axios";
import { API_BASE_URL } from '@/config';
// import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  // components: {
  //   LoadingSpinner
  // },
  data() {
    return {
      isLoading: false
    }
  },
  mounted() {
    // Définissez isLoading à false une fois que le header est prêt
    this.isLoading = false;
  },
  setup() {
    
    const store = useStore();

    const prenom = computed(() => store.getters.prenom);
    const nom = computed(() => store.getters.nom);
    const photo = computed(() => store.getters.photo);
    const specialiteid = computed(() => store.getters.specialiteid);
    const specialitenom = computed(() => store.getters.specialitenom);
    const centreid = computed(() => store.getters.centreid);
    const centrenom = computed(() => store.getters.centrenom);
    const genre = computed(() => store.getters.genre);
    const telephone = computed(() => store.getters.telephone);
    const date_naissance = computed(() => store.getters.date_naissance);
    
    const router = useRouter();

    const logout = async () => {
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
          // await axios.post(`${TELEMED_BASE_URL}/logout`, { refreshToken });
          await axios.post(`${API_BASE_URL}/logout`, { refreshToken });
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          router.push('/pages/login').then(() => {
            window.location.reload();
          });
        }
      } catch (err) {
        console.error("Erreur lors de la déconnexion", err);
      }
    };
    
    return {
      logout,
      prenom,
      nom,
      photo,
      specialiteid,
      centreid,
      specialitenom,
      centrenom,
      genre,
      telephone,
      date_naissance
    };

  }
};
</script>
<style scoped>
.loading-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  background-color: #f5f5f5;
}

.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>