<template>
  <!-- Faq -->
  <section class="faq-four-sec">
    <div class="container">
      <div class="section-header h-three aos" data-aos="fade-up">
        <h2>Have Many Questions?</h2>
        <div class="heading-bar">
          <span></span>
          <span></span>
        </div>
        <p class="sub-title">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget aenean
          accumsan bibendum
        </p>
      </div>
      <div class="faq-tab-list">
        <ul class="nav nav-pills mb-3" id="pills-tab-two" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="pills-general-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-general"
              type="button"
              role="tab"
              aria-controls="pills-general"
              aria-selected="true"
            >
              General
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-mentor-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-mentor"
              type="button"
              role="tab"
              aria-controls="pills-mentor"
              aria-selected="false"
              tabindex="-1"
            >
              Mentor
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-mentee-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-mentee"
              type="button"
              role="tab"
              aria-controls="pills-mentee"
              aria-selected="false"
            >
              Mentees
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-booking-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-booking"
              type="button"
              role="tab"
              aria-controls="pills-booking"
              aria-selected="false"
              tabindex="-1"
            >
              Booking
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-download-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-download"
              type="button"
              role="tab"
              aria-controls="pills-download"
              aria-selected="false"
              tabindex="-1"
            >
              Download
            </button>
          </li>
        </ul>
      </div>
      <div class="tab-content" id="pills-tabContent-two">
        <div
          class="tab-pane fade active show"
          id="pills-general"
          role="tabpanel"
          aria-labelledby="pills-general-tab"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="faq-card">
                <div
                  class="panel-group"
                  id="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="headingOne">
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion"
                            href="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseOne"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordion"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="headingTwo">
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion"
                            href="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseTwo"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordion"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="headingThree">
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion"
                            href="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseThree"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordion"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="headingFour">
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion"
                            href="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseFour"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordion"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="pills-mentor"
          role="tabpanel"
          aria-labelledby="pills-mentor-tab"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="faq-card">
                <div
                  class="panel-group"
                  id="accordion-two"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="headingOne-two">
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion-two"
                            href="#collapseOne-two"
                            aria-expanded="false"
                            aria-controls="collapseOne-two"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseOne-two"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingOne-two"
                        data-bs-parent="#accordion-two"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="headingTwo-two">
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion-two"
                            href="#collapseTwo-two"
                            aria-expanded="false"
                            aria-controls="collapseTwo-two"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseTwo-two"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo-two"
                        data-bs-parent="#accordion-two"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div
                        class="panel-heading"
                        role="tab"
                        id="headingThree-two"
                      >
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion-two"
                            href="#collapseThree-two"
                            aria-expanded="false"
                            aria-controls="collapseThree-two"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseThree-two"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingThree-two"
                        data-bs-parent="#accordion-two"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div
                        class="panel-heading"
                        role="tab"
                        id="headingFour-two"
                      >
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion-two"
                            href="#collapseFour-two"
                            aria-expanded="false"
                            aria-controls="collapseFour-two"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseFour-two"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingFour-two"
                        data-bs-parent="#accordion-two"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="pills-mentee"
          role="tabpanel"
          aria-labelledby="pills-mentee-tab"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="faq-card">
                <div
                  class="panel-group"
                  id="accordion-three"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div
                        class="panel-heading"
                        role="tab"
                        id="headingOne-three"
                      >
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion-three"
                            href="#collapseOne-three"
                            aria-expanded="false"
                            aria-controls="collapseOne-three"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseOne-three"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingOne-three"
                        data-bs-parent="#accordion-three"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div
                        class="panel-heading"
                        role="tab"
                        id="headingTwo-three"
                      >
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion-three"
                            href="#collapseTwo-three"
                            aria-expanded="false"
                            aria-controls="collapseTwo-three"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseTwo-three"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo-three"
                        data-bs-parent="#accordion-three"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div
                        class="panel-heading"
                        role="tab"
                        id="headingThree-three"
                      >
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion-three"
                            href="#collapseThree-three"
                            aria-expanded="false"
                            aria-controls="collapseThree-three"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseThree-three"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingThree-three"
                        data-bs-parent="#accordion-three"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div
                        class="panel-heading"
                        role="tab"
                        id="headingFour-three"
                      >
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion-three"
                            href="#collapseFour-three"
                            aria-expanded="false"
                            aria-controls="collapseFour-three"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseFour-three"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingFour-three"
                        data-bs-parent="#accordion-three"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="pills-booking"
          role="tabpanel"
          aria-labelledby="pills-booking-tab"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="faq-card">
                <div
                  class="panel-group"
                  id="accordion-four"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div
                        class="panel-heading"
                        role="tab"
                        id="headingOne-four"
                      >
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion-four"
                            href="#collapseOne-four"
                            aria-expanded="false"
                            aria-controls="collapseOne-four"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseOne-four"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingOne-four"
                        data-bs-parent="#accordion-four"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div
                        class="panel-heading"
                        role="tab"
                        id="headingTwo-four"
                      >
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion-four"
                            href="#collapseTwo-four"
                            aria-expanded="false"
                            aria-controls="collapseTwo-four"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseTwo-four"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo-four"
                        data-bs-parent="#accordion-four"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div
                        class="panel-heading"
                        role="tab"
                        id="headingThree-four"
                      >
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion-four"
                            href="#collapseThree-four"
                            aria-expanded="false"
                            aria-controls="collapseThree-four"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseThree-four"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingThree-four"
                        data-bs-parent="#accordion-four"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div
                        class="panel-heading"
                        role="tab"
                        id="headingFour-four"
                      >
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion-four"
                            href="#collapseFour-four"
                            aria-expanded="false"
                            aria-controls="collapseFour-four"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseFour-four"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingFour-four"
                        data-bs-parent="#accordion-four"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="pills-download"
          role="tabpanel"
          aria-labelledby="pills-download-tab"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="faq-card">
                <div
                  class="panel-group"
                  id="accordion-five"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div
                        class="panel-heading"
                        role="tab"
                        id="headingOne-five"
                      >
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion-five"
                            href="#collapseOne-five"
                            aria-expanded="false"
                            aria-controls="collapseOne-five"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseOne-five"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingOne-five"
                        data-bs-parent="#accordion-five"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div
                        class="panel-heading"
                        role="tab"
                        id="headingTwo-five"
                      >
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion-five"
                            href="#collapseTwo-five"
                            aria-expanded="false"
                            aria-controls="collapseTwo-five"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseTwo-five"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo-five"
                        data-bs-parent="#accordion-five"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div
                        class="panel-heading"
                        role="tab"
                        id="headingThree-five"
                      >
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion-five"
                            href="#collapseThree-five"
                            aria-expanded="false"
                            aria-controls="collapseThree-five"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseThree-five"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingThree-five"
                        data-bs-parent="#accordion-five"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="faq-tab">
                    <div class="panel panel-default">
                      <div
                        class="panel-heading"
                        role="tab"
                        id="headingFour-five"
                      >
                        <p class="panel-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion-five"
                            href="#collapseFour-five"
                            aria-expanded="false"
                            aria-controls="collapseFour-five"
                          >
                            Is the regular license the same thing as an
                            editorial license?
                          </a>
                        </p>
                      </div>
                      <div
                        id="collapseFour-five"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingFour-five"
                        data-bs-parent="#accordion-five"
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Faq -->
  <template></template>
</template>
