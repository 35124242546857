<template>
  <div class="main-wrapper">
    <layout-header1></layout-header1>
    <breadcrumb6></breadcrumb6>
    <!-- Page Content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <div class="blog-view">
              <div class="blog blog-single-post">
                <div class="blog-image">
                  <a href="javascript:void(0);"
                    ><img
                      alt=""
                      src="@/assets/img/website/blog/blog-01.jpg"
                      class="img-fluid"
                  /></a>
                </div>
                <h3 class="blog-title">
                  Lorem Ipsum is simply dummy text of the printing
                </h3>
                <div class="blog-info clearfix">
                  <div class="post-left">
                    <ul>
                      <li>
                        <div class="post-author">
                          <router-link to="/mentee/mentor-profile"
                            ><img
                              src="@/assets/img/website/user/user.jpg"
                              alt="Post Author"
                            />
                            <span>Marvin Downey</span></router-link
                          >
                        </div>
                      </li>
                      <li><i class="far fa-calendar"></i>4 Dec 2019</li>
                      <li><i class="far fa-comments"></i>12 Comments</li>
                      <li><i class="fa fa-tags"></i>HTML</li>
                    </ul>
                  </div>
                </div>
                <div class="blog-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo. Nemo
                    enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                    aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                    qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                    velit, sed quia non numquam eius modi tempora incidunt ut
                    labore et dolore magnam aliquam quaerat voluptatem. Ut enim
                    ad minima veniam, quis nostrum exercitationem ullam corporis
                    suscipit laboriosam, nisi ut aliquid ex ea commodi
                    consequatur? Quis autem vel eum iure reprehenderit qui in ea
                    voluptate velit esse quam nihil molestiae consequatur, vel
                    illum qui dolorem eum fugiat quo voluptas nulla pariatur?
                  </p>
                  <p>
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis praesentium voluptatum deleniti atque
                    corrupti quos dolores et quas molestias excepturi sint
                    occaecati cupiditate non provident, similique sunt in culpa
                    qui officia deserunt mollitia animi, id est laborum et
                    dolorum fuga. Et harum quidem rerum facilis est et expedita
                    distinctio. Nam libero tempore, cum soluta nobis est
                    eligendi optio cumque nihil impedit quo minus id quod maxime
                    placeat facere possimus, omnis voluptas assumenda est, omnis
                    dolor repellendus. Temporibus autem quibusdam et aut
                    officiis debitis aut rerum necessitatibus saepe eveniet ut
                    et voluptates repudiandae sint et molestiae non recusandae.
                    Itaque earum rerum hic tenetur a sapiente delectus, ut aut
                    reiciendis voluptatibus maiores alias consequatur aut
                    perferendis doloribus asperiores repellat.
                  </p>
                </div>
              </div>

              <div class="card blog-share clearfix">
                <div class="card-header">
                  <h4 class="card-title">Share the post</h4>
                </div>
                <div class="card-body">
                  <ul class="social-share">
                    <li>
                      <a href="javascript:;" title="Facebook"
                        ><i class="fab fa-facebook"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" title="Twitter"
                        ><i class="fab fa-twitter"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" title="Linkedin"
                        ><i class="fab fa-linkedin"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" title="Google Plus"
                        ><i class="fab fa-google-plus"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" title="Youtube"
                        ><i class="fab fa-youtube"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card author-widget clearfix">
                <div class="card-header">
                  <h4 class="card-title">About Author</h4>
                </div>
                <div class="card-body">
                  <div class="about-author">
                    <div class="about-author-img">
                      <div class="author-img-wrap">
                        <router-link to="/mentee/mentor-profile"
                          ><img
                            class="img-fluid rounded-circle"
                            alt=""
                            src="@/assets/img/website/user/user1.jpg"
                        /></router-link>
                      </div>
                    </div>
                    <div class="author-details">
                      <router-link
                        to="/mentee/mentor-profile"
                        class="blog-author-name"
                        >Darren Elder</router-link
                      >
                      <p class="mb-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card blog-comments clearfix">
                <div class="card-header">
                  <h4 class="card-title">Comments (12)</h4>
                </div>
                <div class="card-body pb-0">
                  <ul class="comments-list">
                    <li>
                      <div class="comment">
                        <div class="comment-author">
                          <img
                            class="avatar"
                            alt=""
                            src="@/assets/img/website/user/user4.jpg"
                          />
                        </div>
                        <div class="comment-block">
                          <span class="comment-by">
                            <span class="blog-author-name"
                              >Michelle Fairfax</span
                            >
                          </span>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nam viverra euismod odio, gravida pellentesque
                            urna varius vitae, gravida pellentesque urna varius
                            vitae. Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit.
                          </p>
                          <p class="blog-date">Dec 6, 2017</p>
                          <a class="comment-btn" href="javascript:;">
                            <i class="fas fa-reply"></i> Reply
                          </a>
                        </div>
                      </div>
                      <ul class="comments-list reply">
                        <li>
                          <div class="comment">
                            <div class="comment-author">
                              <img
                                class="avatar"
                                alt=""
                                src="@/assets/img/website/user/user5.jpg"
                              />
                            </div>
                            <div class="comment-block">
                              <span class="comment-by">
                                <span class="blog-author-name">Gina Moore</span>
                              </span>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Nam viverra euismod odio,
                                gravida pellentesque urna varius vitae, gravida
                                pellentesque urna varius vitae.
                              </p>
                              <p class="blog-date">Dec 6, 2017</p>
                              <a class="comment-btn" href="javascript:;">
                                <i class="fas fa-reply"></i> Reply
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="comment">
                            <div class="comment-author">
                              <img
                                class="avatar"
                                alt=""
                                src="@/assets/img/website/user/user3.jpg"
                              />
                            </div>
                            <div class="comment-block">
                              <span class="comment-by">
                                <span class="blog-author-name">Carl Kelly</span>
                              </span>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Nam viverra euismod odio,
                                gravida pellentesque urna varius vitae, gravida
                                pellentesque urna varius vitae.
                              </p>
                              <p class="blog-date">December 7, 2017</p>
                              <a class="comment-btn" href="javascript:;">
                                <i class="fas fa-reply"></i> Reply
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div class="comment">
                        <div class="comment-author">
                          <img
                            class="avatar"
                            alt=""
                            src="@/assets/img/website/user/user6.jpg"
                          />
                        </div>
                        <div class="comment-block">
                          <span class="comment-by">
                            <span class="blog-author-name">Elsie Gilley</span>
                          </span>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                          </p>
                          <p class="blog-date">December 11, 2017</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="comment">
                        <div class="comment-author">
                          <img
                            class="avatar"
                            alt=""
                            src="@/assets/img/website/user/user7.jpg"
                          />
                        </div>
                        <div class="comment-block">
                          <span class="comment-by">
                            <span class="blog-author-name">Joan Gardner</span>
                          </span>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                          </p>
                          <p class="blog-date">December 13, 2017</p>
                          <a class="comment-btn" href="javascript:;">
                            <i class="fas fa-reply"></i> Reply
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="card new-comment clearfix">
                <div class="card-header">
                  <h4 class="card-title">Leave Comment</h4>
                </div>
                <div class="card-body">
                  <form>
                    <div class="form-group">
                      <label>Name <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" />
                    </div>
                    <div class="form-group">
                      <label
                        >Your Email Address
                        <span class="text-danger">*</span></label
                      >
                      <input type="email" class="form-control" />
                    </div>
                    <div class="form-group">
                      <label>Comments</label>
                      <textarea rows="4" class="form-control"></textarea>
                    </div>
                    <div class="submit-section">
                      <button class="btn btn-primary submit-btn" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <!-- Blog Sidebar -->
          <div class="col-lg-4 col-md-12 sidebar-right theiaStickySidebar">
            <div class="stickysidebar">
              <search-bar />
              <latest-posts />
              <blog-categories />
              <tags />
            </div>
          </div>
          <!-- /Blog Sidebar -->
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <layout-footer></layout-footer>
  </div>
</template>
