<template>
  <!-- Say testimonial Two -->
  <section class="testimonial-two student-say-about testimonial-section">
    <div class="container">
      <div class="section-header h-three aos" data-aos="fade-up">
        <h2>Testimonials & Reviews</h2>
        <div class="heading-bar">
          <span></span>
          <span></span>
        </div>
        <p class="sub-title">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget aenean
          accumsan bibendum
        </p>
      </div>
      <div class="row justify-content-center">
        <div
          class="col-lg-4 col-md-6 aos"
          data-aos="fade-up"
          data-bs-interval="5000"
        >
          <div class="customer-review">
            <div class="review-card">
              <div class="rating">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
              </div>
              <p>
                “ I believe in lifelong learning and Skola is a great place to
                learn from experts. I've learned a lot and recommend it to all
                my friends “
              </p>
              <div class="customer">
                <img src="@/assets/img/user/user30.jpg" alt="" />
              </div>
            </div>
            <div class="cus-name">
              <a href="javascript:void(0);">Madison</a>
              <span>Developer</span>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-6 aos"
          data-aos="fade-up"
          data-bs-interval="5000"
        >
          <div class="customer-review">
            <div class="review-card">
              <div class="rating">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
              </div>
              <p>
                “ I believe in lifelong learning and Skola is a great place to
                learn from experts. I've learned a lot and recommend it to all
                my friends “
              </p>
              <div class="customer">
                <img src="@/assets/img/user/user12.jpg" alt="" />
              </div>
            </div>
            <div class="cus-name">
              <a href="javascript:void(0);">Alexander</a>
              <span>Designer</span>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-6 aos"
          data-aos="fade-up"
          data-bs-interval="5000"
        >
          <div class="customer-review">
            <div class="review-card">
              <div class="rating">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
              </div>
              <p>
                “ I believe in lifelong learning and Skola is a great place to
                learn from experts. I've learned a lot and recommend it to all
                my friends “
              </p>
              <div class="customer">
                <img src="@/assets/img/user/user13.jpg" alt="" />
              </div>
            </div>
            <div class="cus-name">
              <a href="javascript:void(0);">Barrett Valentine</a>
              <span>Marketer</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Say testimonial Two -->
</template>
