<template>
  <!-- /Faq -->
  <section class="faq-eight-sec">
    <div class="container">
      <!-- Section Header -->
      <div class="section-header-eight text-center aos" data-aos="fade-up">
        <span>MOST VIEWED</span>
        <h2>Frequently Asked Questions</h2>
        <p class="sub-title">
          Are you looking to join online institutions? Now it's very simple,
          Sign up with mentoring
        </p>
        <div class="sec-dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <!-- /Section Header -->
      <div class="row">
        <div class="col-lg-6">
          <div class="faq-box-eight">
            <div class="faq-head">
              <p>
                <span>?</span>Is the regular license the same thing as an
                editorial license?
              </p>
            </div>
            <div class="faq-body">
              <p>
                Do you want to move on next step? Choose your most popular
                leaning mentors, it will help you to achieve your professional
                goals.
              </p>
            </div>
          </div>
          <div class="faq-box-eight">
            <div class="faq-head">
              <p>
                <span>?</span>Is the regular license the same thing as an
                editorial license?
              </p>
            </div>
            <div class="faq-body">
              <p>
                Do you want to move on next step? Choose your most popular
                leaning mentors, it will help you to achieve your professional
                goals.
              </p>
            </div>
          </div>
          <div class="faq-box-eight border-0">
            <div class="faq-head">
              <p>
                <span>?</span>Is the regular license the same thing as an
                editorial license?
              </p>
            </div>
            <div class="faq-body">
              <p>
                Do you want to move on next step? Choose your most popular
                leaning mentors, it will help you to achieve your professional
                goals.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="faq-box-eight">
            <div class="faq-head">
              <p>
                <span>?</span>Is the regular license the same thing as an
                editorial license?
              </p>
            </div>
            <div class="faq-body">
              <p>
                Do you want to move on next step? Choose your most popular
                leaning mentors, it will help you to achieve your professional
                goals.
              </p>
            </div>
          </div>
          <div class="faq-box-eight">
            <div class="faq-head">
              <p>
                <span>?</span>Is the regular license the same thing as an
                editorial license?
              </p>
            </div>
            <div class="faq-body">
              <p>
                Do you want to move on next step? Choose your most popular
                leaning mentors, it will help you to achieve your professional
                goals.
              </p>
            </div>
          </div>
          <div class="faq-box-eight border-0">
            <div class="faq-head">
              <p>
                <span>?</span>Is the regular license the same thing as an
                editorial license?
              </p>
            </div>
            <div class="faq-body">
              <p>
                Do you want to move on next step? Choose your most popular
                leaning mentors, it will help you to achieve your professional
                goals.
              </p>
            </div>
          </div>
        </div>
        <div
          class="view-all text-center aos aos-init aos-animate"
          data-aos="fade-up"
        >
          <a href="javascript:void(0);" class="btn btn-primary btn-view"
            >View All</a
          >
        </div>
      </div>
    </div>
  </section>
  <!-- /Faq -->
</template>
