<template>
  <!-- Path section start -->
  <section class="section path-section">
    <div class="section-header text-center aos" data-aos="fade-up">
      <div class="container">
        <span>Choose the</span>
        <h2>Different All Learning Paths</h2>
        <p class="sub-title">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt
        </p>
      </div>
    </div>
    <div class="learning-path-col">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-4 col-lg-3">
            <div class="large-col aos" data-aos="fade-up">
              <router-link to="mentee/mentor-search" class="large-col-image">
                <div class="image-col-merge">
                  <img src="@/assets/img/path-img1.jpg" alt="" />
                  <div class="text-col">
                    <h5>Digital Marketer</h5>
                    <span>40 Mentors</span>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-md-4 col-lg-3">
            <div class="large-col aos" data-aos="fade-up">
              <router-link to="mentee/mentor-search" class="large-col-image">
                <div class="image-col-merge">
                  <img src="@/assets/img/path-img2.jpg" alt="" />
                  <div class="text-col">
                    <h5>Ui designer</h5>
                    <span>30 Mentors</span>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-md-4 col-lg-3">
            <div class="large-col aos" data-aos="fade-up">
              <router-link to="mentee/mentor-search" class="large-col-image">
                <div class="image-col-merge">
                  <img src="@/assets/img/path-img3.jpg" alt="" />
                  <div class="text-col">
                    <h5>IT Security</h5>
                    <span>35 Mentors</span>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-md-4 col-lg-3">
            <div class="large-col aos" data-aos="fade-up">
              <router-link to="mentee/mentor-search" class="large-col-image">
                <div class="image-col-merge">
                  <img src="@/assets/img/path-img4.jpg" alt="" />
                  <div class="text-col">
                    <h5>Front-End Developer</h5>
                    <span>40 Mentors</span>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-md-4 col-lg-3">
            <div class="large-col aos" data-aos="fade-up">
              <router-link to="mentee/mentor-search" class="large-col-image">
                <div class="image-col-merge">
                  <img src="@/assets/img/path-img5.jpg" alt="" />
                  <div class="text-col">
                    <h5>Web Developer</h5>
                    <span>45 Mentors</span>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-md-4 col-lg-3">
            <div class="large-col aos" data-aos="fade-up">
              <router-link to="mentee/mentor-search" class="large-col-image">
                <div class="image-col-merge">
                  <img src="@/assets/img/path-img6.jpg" alt="" />
                  <div class="text-col">
                    <h5>Administrator</h5>
                    <span>30 Mentors</span>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-md-4 col-lg-3">
            <div class="large-col aos" data-aos="fade-up">
              <router-link to="mentee/mentor-search" class="large-col-image">
                <div class="image-col-merge">
                  <img src="@/assets/img/path-img7.jpg" alt="" />
                  <div class="text-col">
                    <h5>Project Manager</h5>
                    <span>40 Mentors</span>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-md-4 col-lg-3">
            <div class="large-col aos" data-aos="fade-up">
              <router-link to="mentee/mentor-search" class="large-col-image">
                <div class="image-col-merge">
                  <img src="@/assets/img/path-img8.jpg" alt="" />
                  <div class="text-col">
                    <h5>PHP Developer</h5>
                    <span>50 Mentors</span>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="view-all text-center aos" data-aos="fade-up">
          <router-link to="mentee/mentor-search" class="btn btn-primary view-all"
            >View All</router-link
          >
        </div>
      </div>
    </div>
  </section>
  <!-- Path section end -->
</template>
