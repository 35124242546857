<template>
	<div class="map-page">
		<div class="main-wrapper">
            <layout-header />
                <h2>Appels entrants</h2>
                <div v-if="incomingCalls.length === 0">
                    <p>Aucun appel entrant pour le moment.</p>
                </div>
                <div v-else>
                    <div v-for="call in incomingCalls" :key="call.callerId">
                    <p>Appel de: {{ call.callerId }}</p>
                    <button @click="answerCall(call.callerId)">Répondre</button>
                    </div>
                </div>
            <layout-footer />
		</div>
	</div>
  </template>
  
  <script>
import { ref } from 'vue';
import { io } from 'socket.io-client';
import { useRouter } from 'vue-router';
import { v4 as uuidv4 } from 'uuid';

export default {
  setup() {
    const incomingCalls = ref([]);
    const socket = io('http://192.168.1.66:3000');
    const router = useRouter();
    const userId = uuidv4(); // Générer un identifiant unique pour l'utilisateur

    socket.on('initiateCall', (data) => {
      if (data.callerId !== userId) {
        incomingCalls.value.push(data);
      }
    });

    const answerCall = (callerId) => {
      socket.emit('answerCall', { callerId, responderId: userId });
      router.push({ name: 'VideoCall' });
    };

    return {
      incomingCalls,
      answerCall,
    };
  },
};
</script>
  
  <style scoped>
  div {
    padding: 20px;
  }
  </style>
  