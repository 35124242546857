<template>
	<div class="map-page">
		<div class="main-wrapper">
			<layout-header1></layout-header1>
			<!-- Page Content -->
			<div class="content">
				<div class="container-fluid">
					<div class="row">
						<div class="col-xl-6 col-lg-12 order-md-last order-sm-last order-last map-left">
							<div class="row align-items-center mb-4">
								<div class="col-md-6 col">
									<h4>Video Call</h4>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-6 col-md-4 col-xl-6">
									<div class="videocall-page">
										<h2>Vidéoconférence</h2>
										<video id="localVideo" autoplay muted></video>
										<video id="remoteVideo" autoplay></video>
										<button @click="joinCall">Rejoindre l'appel</button>
									</div>
								</div>
							</div>
						</div>
						<!-- /content-left-->
						<div class="col-xl-6 col-lg-12 map-right">
							<!-- map-->
						</div>
						<!-- /map-right-->
					</div>
					<!-- /row-->
				</div>
			</div>
			<!-- /Page Content -->
			<layout-footer></layout-footer>
		</div>
	</div>
</template>

<script>
	import io from 'socket.io-client';

	export default {
		data() {
			return {
				salonId: this.$route.params.salonId,
				socket: null,
				localStream: null
			};
		},
		mounted() {
			this.startCamera();
			this.initSocket();
		},
		methods: {
			startCamera() {
				navigator.mediaDevices.getUserMedia({ video: true, audio: true })
					.then(stream => {
						this.localStream = stream;
						const localVideo = document.getElementById('localVideo');
						localVideo.srcObject = stream;
					})
					.catch(error => {
						console.error("Error accessing camera:", error);
					});
			},
			initSocket() {
				this.socket = io('http://localhost:3000');
				
				this.socket.on('connect', () => {
					console.log('Connected to WebSocket server');
				});

				this.socket.on('offer', (offer) => {
					this.handleOffer(offer);
				});

				this.socket.on('answer', (answer) => {
					this.handleAnswer(answer);
				});

				this.socket.on('candidate', (candidate) => {
					this.handleCandidate(candidate);
				});
			},
			async joinCall() {
				console.log(`Joining call with ID: ${this.salonId}`);
				
				const pc = this.createPeerConnection();

				this.localStream.getTracks().forEach(track => {
					pc.addTrack(track, this.localStream);
				});

				const offer = await pc.createOffer();
				await pc.setLocalDescription(offer);

				this.socket.emit('join', { salonId: this.salonId, offer });
			},
			createPeerConnection() {
				const pc = new RTCPeerConnection({
					iceServers: [
						{ urls: 'stun:stun.l.google.com:19302' },
						{ urls: 'stun:stun1.l.google.com:19302' }
					]
				});

				pc.onicecandidate = (event) => {
					if (event.candidate) {
						this.socket.emit('candidate', { candidate: event.candidate });
					}
				};

				pc.ontrack = (event) => {
					const remoteVideo = document.getElementById('remoteVideo');
					if (remoteVideo.srcObject !== event.streams[0]) {
						remoteVideo.srcObject = event.streams[0];
					}
				};

				return pc;
			},
			handleOffer(offer) {
				const pc = this.createPeerConnection();

				pc.setRemoteDescription(new RTCSessionDescription(offer))
					.then(() => {
						return pc.createAnswer();
					})
					.then(answer => {
						return pc.setLocalDescription(answer);
					})
					.then(() => {
						this.socket.emit('answer', { answer });
					})
					.catch(error => {
						console.error('Error handling offer:', error);
					});
			},
			handleAnswer(answer) {
				const pc = this.createPeerConnection();
				pc.setRemoteDescription(new RTCSessionDescription(answer))
					.catch(error => {
						console.error('Error setting remote description:', error);
					});
			},
			handleCandidate(candidate) {
				const pc = this.createPeerConnection();
				pc.addIceCandidate(new RTCIceCandidate(candidate))
					.catch(error => {
						console.error('Error adding received ICE candidate:', error);
					});
			}
		}
	}
</script>

<style>
	.videocall-page {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100vh;
	}
	#localVideo, #remoteVideo {
		width: 80%;
		height: auto;
		border: 1px solid #ccc;
		margin-bottom: 20px;
	}
</style>