<template>
  <!-- Home Banner -->
  <section class="section section-search">
    <div class="container">
      <div class="banner-wrapper m-auto text-center aos" data-aos="fade-up">
        <div class="banner-header banner-header-one">
          <!-- <h1>Besoin d'aide en cas <span>d'urgence ?</span> </h1> -->
          <h1>Télé-assistance <span>Médicale !</span> </h1>
          <p>
          Vous avez besoin d'une intervention d'urgence ? Que ce soit les pompiers,
           les urgences médicales ou tout autre service d'urgence, notre plateforme 
           vous met en relation rapidement avec les professionnels compétents pour vous venir en aide.
          </p>
        </div>
        <!-- Search -->
         <!-- <div class="search-box"> 
          <form action="mentee/mentor-search">
            <div class="form-group search-location">
              <input
                type="text"
                class="form-control"
                placeholder="Recherchez dans une ville"
              />
            </div>
            <div class="form-group search-info">
              <input
                type="text"
                class="form-control"
                placeholder="Search School, Online educational centers, etc"
              />
            </div>
            <button type="submit" class="btn btn-primary search-btn">
              <i><img src="@/assets/img/search-submit.png" alt="" /></i>
              <span>Search</span>
            </button>
          </form>
        </div>  -->
        <!-- /Search -->
      </div>
    </div>
  </section>
  <!-- /Home Banner -->
</template>
