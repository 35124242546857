<template>
<div class="account-page">
    <div class="main-wrapper">
        <!-- Page Content -->
			<div class="bg-pattern-style" style="background: url('https://192.168.1.69:8080/img/banner-img1.5c910a04.jpg') no-repeat; background-size: cover;">
				<div class="content">
							
					<!-- Login Tab Content -->
					<div class="account-content">
						<div class="account-box">
              <router-link to="/" class="navbar-brand logo">
                <img style="margin-top: 20px; margin-bottom: 20px; width: 200px"
                src="@/assets/img/website/teleassistance.png"
                class=""
                alt="Logo"
                />
              </router-link>
							<div class="login-right">
								<div class="login-header">
									<h3>Login <span>Téléassistance</span></h3>
									<p class="text-muted">Accéder à la plateforme</p>
								</div> 
								<form @submit.prevent="login">
									<div class="form-group">
										<label class="form-control-label">Email</label>
										<input type="email" class="form-control" v-model="email">
									</div>
									<div class="form-group">
										<label class="form-control-label">Mot de passe</label>
										<div class="pass-group">
											<input type="password" class="form-control pass-input" v-model="password">
											<span class="fas fa-eye toggle-password" ></span>
										</div>
									</div>
									<div class="text-end">
										<router-link class="forgot-link" to="/pages/forgot-password">Mot de passe oublié ?</router-link>
									</div>
									<!-- <button class="btn btn-primary login-btn" type="button" @click="$router.push('/mentor/index')">Login</button> -->
									<button class="btn btn-primary login-btn" type="submit">Login</button>
									<div class="text-center dont-have">Pas de compte ? <router-link to="/pages/register">S'enregistrer</router-link></div>
								</form>
							</div>
						</div>
					</div>
					<!-- /Login Tab Content -->

				</div>

			</div>		
			<!-- /Page Content -->
    </div>
</div>
</template>
<script>
import { API_BASE_URL } from '@/config';
import axios from 'axios';  // Importer l'instance Axios configurée

export default {
  data() {
    return {
      email: '',
      password: '',
      showPassword: false
    }
  },
  computed: {
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password';
    },
    passwordToggleIcon() {
      return this.showPassword ? 'fa-eye-slash' : 'fa-eye';
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async login() {
      try {
        // Récupérer les informations de l'utilisateur
        const response = await axios.post(`${API_BASE_URL}/login`, {
          email: this.email,
          password: this.password
        });

        const { accessToken, refreshToken, user } = response.data;
		
		// Stocker les jetons et les informations de l'utilisateur dans le localStorage
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('userInfo', JSON.stringify(user));

        // Stocker les jetons et les informations de l'utilisateur
        this.$store.commit('setAccessToken', accessToken);
        this.$store.commit('setRefreshToken', refreshToken);
        this.$store.commit('setUserInfo', user);

        // Rediriger l'utilisateur en fonction de son type
        switch (user.type_utilisateur) {
          case 'particulier':
            this.$router.push('/particulier/accueil');
            break;
          case 'specialiste':
            this.$router.push('/mentor/index');
            break;
          case 'administrateur':
            // this.$nextTick(() => {
            //   this.$router.go(0); // Recharger la page d'administration
            // });
            // this.$router.push('/admin/index');
            // window.open('/admin/index', '_blank'); // Ouvre la page d'administration dans un nouvel onglet
            // break;
            // this.isLoading = true;
            if (this.$refs.admin) {
                this.$refs.admin.isLoading = true;
              }
            this.$nextTick(() => {
              this.$router.push('/admin/index').then(() => {
                // Recharger la page d'administration après la navigation
                window.location.reload();
              });
            });
            break;
          default:
            // L'utilisateur super-administrateur n'a pas le droit de se connecter via ce formulaire
            throw new Error('Accès refusé');
        }
      } catch (error) {
        console.error(error);
        // Afficher un message d'erreur à l'utilisateur
        alert(error.response?.data?.message || error.message);
      }
    }
  }
}
</script>
