<template>
	<div class="map-page">
		<div class="main-wrapper">
			<layout-header1></layout-header1>
			<!-- Page Content -->
			<div class="content">
				<div class="container-fluid">
					<div class="row">
						<div class="col-xl-6 col-lg-12 order-md-last order-sm-last order-last map-left">
							<div class="row align-items-center mb-4">
								<div class="col-md-6 col">
									<h4> Centres trouvé(s)</h4>
								</div>
								<div class="col-md-6 col-auto">
									<div class="view-icons">
										<router-link to="/particulier/accueil" class="grid-view active"><i class="fas fa-th-large"></i></router-link>
										<router-link to="/particulier/listecentre" class="list-view"><i class="fas fa-bars"></i></router-link>
									</div>
									<div class="sort-by d-sm-block d-none">
										<span class="sortby-fliter">
											<vue-select :options="options" />
										</span>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-6 col-md-4 col-xl-6" v-for="item in displayedCentres" :key="item.id">
									<div class="profile-widget">
										<div class="user-avatar">
											<router-link :to="'/particulier/profil/' + item.id">
												<img class="img-fluid fluids" alt="Image du centre d'assistance" :src="`http://localhost:3000/uploads/logos/${item.logo}`">
											</router-link>
											<a href="javascript:void(0)" class="fav-btn">
												<i class="far fa-bookmark"></i>
											</a>
										</div>
										<div class="pro-content">
											<h3 class="title">
												<router-link :to="'/particulier/profil/' + item.id">{{item.nom}}</router-link> 
												<i class="fas fa-check-circle verified"></i>
											</h3>
											<p class="speciality">{{item.typecentre}}</p>
											<ul class="available-info">
												<li>
													<i class="fas fa-globe"></i>{{item.ville + ', ' + item.pays}}
												</li>
												<li>
													<i class="fas fa-map-marker-alt"></i>{{item.adresse}}
												</li>
											</ul>
											<div class="row row-sm">
												<div class="col-6">
													<router-link :to="'/particulier/profil/' + item.id" class="btn view-btn">Voir la fiche</router-link>
												</div>
												<div class="col-6">
													<button @click="initiateCall(item.id)" class="btn book-btn">Appeler</button>
                                                </div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="load-more text-center" v-if="displayedCentres.length < centres.length">
								<button class="btn btn-primary btn-sm" @click="loadMore">Voir plus</button>
							</div>
						</div>
						<!-- /content-left-->
						<div class="col-xl-6 col-lg-12 map-right">
							<div id="map" class="map-listing"></div>
							<!-- map-->
						</div>
						<!-- /map-right-->
					</div>
					<!-- /row-->
				</div>
			</div>
			<!-- /Page Content -->
			<layout-footer></layout-footer>
		</div>
	</div>
	</template>
	
	<script>
	import L from 'leaflet';
	import { v4 as uuidv4 } from 'uuid';
	import io from 'socket.io-client';
	import { useStore } from 'vuex';
	import { computed } from 'vue';
import { API_BASE_URL } from '@/config';

	let infowindow = null;
	let map, current = 0;
	const icons = {
	  'default': require('@/assets/img/website/marker.png')
	};
	export default {
  setup() {
    const store = useStore();

    const id = computed(() => store.getters.id);
    const prenom = computed(() => store.getters.prenom);
    const nom = computed(() => store.getters.nom);
    const photo = computed(() => store.getters.photo);
    const specialiteid = computed(() => store.getters.specialiteid);
    const specialitenom = computed(() => store.getters.specialitenom);
    const centreid = computed(() => store.getters.centreid);
    const centrenom = computed(() => store.getters.centrenom);
    const genre = computed(() => store.getters.genre);
    const telephone = computed(() => store.getters.telephone);
    const date_naissance = computed(() => store.getters.date_naissance);
    
    return {
      id,
      prenom,
      nom,
      photo,
      specialiteid,
      centreid,
      specialitenom,
      centrenom,
      genre,
      telephone,
      date_naissance
    };
  },
		data() {
			return {
				socket: null,
				centres: [],
				displayedCentres: [],
				itemsToShow: 4, // Nombre d'items à voir "Voir plus" click
				slider: null,
				defaultProfileImage: require('@/assets/img/user/1.png'),
				bounds: new google.maps.LatLngBounds(),
				lat: '53.470692',
				lng: '-2.220328',
				options: ["Select", "Rating", "Popular", "Latest", "Free"]
			}
		},
		mounted() {
			this.showmap();
			this.getCentre();
			this.socket = io(API_BASE_URL); // Connect to your server
			this.socket.on('connect', () => {
			console.log('Connected to server');
			});
		},
		beforeDestroy() {
			if (this.socket) {
			this.socket.disconnect();
			}
		},
		methods: {
			getProfileImageUrl(profileImage) {
				return `${API_BASE_URL}/uploads/logos/${profileImage}` || this.defaultProfileImage;
			},
			async getCentre() {
				const res = await fetch(`${API_BASE_URL}/centres/urgence`);
				const finalRes = await res.json();
				this.centres = finalRes.centres;
				this.loadMore(); // Load initial set of centres
				this.setMarkers(map, finalRes.centres);
			},
			loadMore() {
				const start = this.displayedCentres.length;
				const end = start + this.itemsToShow;
				this.displayedCentres = this.displayedCentres.concat(this.centres.slice(start, end));
			},
			setMarkers(map, centres) {
				centres.forEach(centre => {
					const marker = new google.maps.Marker({
						position: { lat: parseFloat(centre.latitude), lng: parseFloat(centre.longitude) },
						map,
						title: centre.nom
					});
					const infowindow = new google.maps.InfoWindow({
						content: `
							<div>
								<h5>${centre.nom}</h5>
								<p>${centre.typecentre}</p>
								<p>${centre.adresse}</p>
								<p>${centre.ville}, ${centre.pays}</p>
							</div>
						`
					});
					marker.addListener('click', () => {
						infowindow.open(map, marker);
					});
					this.bounds.extend(marker.position);
				});
				map.fitBounds(this.bounds);
			},
			showmap() {
				const myLatlng = new google.maps.LatLng(this.lat, this.lng);
				const mapOptions = {
					zoom: 5,
					center: myLatlng,
					mapTypeId: google.maps.MapTypeId.ROADMAP,
					zoomControl: true,
					mapTypeControl: false,
					scaleControl: false,
					streetViewControl: false,
					rotateControl: false,
					fullscreenControl: false,
					styles: [{ featureType: 'poi', stylers: [{ visibility: 'off' }] }]
				}
				map = new google.maps.Map(document.getElementById('map'), mapOptions);
				infowindow = new google.maps.InfoWindow();
			},
			async initiateCall(centreId) {
				try {
					// Récupérer les coordonnées de localisation
					const position = await new Promise((resolve, reject) => {
					navigator.geolocation.getCurrentPosition(resolve, reject);
					});
					
					const latitude = position.coords.latitude;
					const longitude = position.coords.longitude;

					// Send call initiation request to the server
					const response = await fetch(`${API_BASE_URL}/appel-particulier-centre-assistance`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json"
					},
					body: JSON.stringify({
						particulierId: this.id,  
						centreAssistanceId: centreId,
						dateDebut: new Date(),
						dateFin: null,
						nomEnregistrement: "Appel_" + uuidv4(),
						latitude: latitude,
						longitude: longitude,
					})
					});
					const data = await response.json();
					if (response.ok) {
					// Redirect to call page
					this.$router.push(`/particulier/call/${data.salonAppelId}`);
					
					// Emit event to notify other users about the new call
					this.socket.emit('nouvelAppel', { appelId: data.appelId, salonAppelId: data.salonAppelId });
					} else {
					console.error(data.message);
					}
				} catch (error) {
					console.error("Failed to initiate call:", error);
				}
			}
		},
	}
	</script>
	
	<style>
	.fluids {
		height: 250px !important;
	}
	</style>
	