import { createStore } from 'vuex';

const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  ID: 'id',
  PRENOM: 'prenom',
  NOM: 'nom',
  PHOTO: 'photo',
  SPECIALITE_ID: 'specialiteid',
  SPECIALITE_NOM: 'specialitenom',
  CENTREID: 'centreid',
  CENTRENOM: 'centrenom',
  GENRE: 'genre',
  TELEPHONE: 'telephone',
  DATE_NAISSANCE: 'date_naissance',
  UUID: 'uuid'
};

function getLocalStorageItem(key) {
  return localStorage.getItem(key) || '';
}

function loadInitialState() {
  return {
    accessToken: getLocalStorageItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN),
    refreshToken: getLocalStorageItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN),
    userInfo: {
      id: getLocalStorageItem(LOCAL_STORAGE_KEYS.ID),
      prenom: getLocalStorageItem(LOCAL_STORAGE_KEYS.PRENOM),
      nom: getLocalStorageItem(LOCAL_STORAGE_KEYS.NOM),
      photo: getLocalStorageItem(LOCAL_STORAGE_KEYS.PHOTO),
      specialiteid: getLocalStorageItem(LOCAL_STORAGE_KEYS.SPECIALITE_ID),
      specialitenom: getLocalStorageItem(LOCAL_STORAGE_KEYS.SPECIALITE_NOM),
      centreid: getLocalStorageItem(LOCAL_STORAGE_KEYS.CENTREID),
      centrenom: getLocalStorageItem(LOCAL_STORAGE_KEYS.CENTRENOM),
      genre: getLocalStorageItem(LOCAL_STORAGE_KEYS.GENRE),
      telephone: getLocalStorageItem(LOCAL_STORAGE_KEYS.TELEPHONE),
      date_naissance: getLocalStorageItem(LOCAL_STORAGE_KEYS.DATE_NAISSANCE),
      uuid: getLocalStorageItem(LOCAL_STORAGE_KEYS.UUID)
    },
    incomingCall: false,
    callerSignal: null,
    callerId: null,
  };
}

function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const store = createStore({
  state: loadInitialState(),
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
      localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, token);
    },
    setRefreshToken(state, token) {
      state.refreshToken = token;
      localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, token);
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      Object.entries(userInfo).forEach(([key, value]) => {
        const localStorageKey = LOCAL_STORAGE_KEYS[key.toUpperCase()];
        if (localStorageKey) {
          localStorage.setItem(localStorageKey, value);
        }
      });
    },
    setUUID(state, uuid) {
      state.userInfo.uuid = uuid;
      localStorage.setItem(LOCAL_STORAGE_KEYS.UUID, uuid);
    },
    setIncomingCall(state, payload) {
      state.incomingCall = payload.incomingCall;
      state.callerSignal = payload.callerSignal;
      state.callerId = payload.callerId;
    },
    clearIncomingCall(state) {
      state.incomingCall = false;
      state.callerSignal = null;
      state.callerId = null;
    },
  },
  actions: {
    initializeUUID({ commit, state }) {
      if (!state.userInfo.uuid) {
        const uuid = generateUUID();
        commit('setUUID', uuid);
      }
    },
    handleIncomingCall({ commit }, data) {
      commit('setIncomingCall', {
        incomingCall: true,
        callerSignal: data.signal,
        callerId: data.from,
      });
    },
    clearIncomingCall({ commit }) {
      commit('clearIncomingCall');
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.accessToken;
    },
    id(state) {
      return state.userInfo.id;
    },
    prenom(state) {
      return state.userInfo.prenom;
    },
    nom(state) {
      return state.userInfo.nom;
    },
    photo(state) {
      return state.userInfo.photo;
    },
    specialiteid(state) {
      return state.userInfo.specialiteid;
    },
    specialitenom(state) {
      return state.userInfo.specialitenom;
    },
    centreid(state) {
      return state.userInfo.centreid;
    },
    centrenom(state) {
      return state.userInfo.centrenom;
    },
    genre(state) {
      return state.userInfo.genre;
    },
    telephone(state) {
      return state.userInfo.telephone;
    },
    date_naissance(state) {
      return state.userInfo.date_naissance;
    },
    uuid(state) {
      return state.userInfo.uuid;
    }
  }
});



export default store;
