<template>
    			<!-- join us mentor -->
			<section class="join-mentor">
				<div class="container">
					<div class="course-two-bg">
						<img src="@/assets/img/bg/home-two-bg-07.png" class="img-fluid join-mentor-bgone" alt="">
						<img src="@/assets/img/bg/home-two-bg-08.png" class="img-fluid join-mentor-bgtwo" alt="">
					</div>
					<div class="row align-items-center">
						<div class="col-lg-6">
							<div class="join-mentor-img aos " data-aos="fade-up">
								<img src="@/assets/img/join.png" class="img-fluid" alt="">
							</div>
						</div>
						<div class="col-lg-6 ">
							<div class="head-join-us aos " data-aos="fade-up">
								<div class="join-mentor-details">
									<h2>Build in-demand tech skills in half the time</h2>
									<p>Do you want to move on next step? Choose your most popular leaning mentors, it will help you to achieve your professional goals.</p>
								</div>
								<div class="reg-log-btn">
									<router-link to="/pages/register" class="reg-btn">Register Now</router-link>
									<router-link to="/pages/login" class="log-btn">Sign In</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /join us mentor -->
    </template>