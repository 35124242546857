<template>
    <div id="app">
      <h1>Appel vidéo avec annotations en réalité augmentée</h1>
      <div v-if="showCallWindow">
        <div class="video-container">
          <video ref="localVideo" autoplay></video>
          <video ref="remoteVideo" autoplay></video>
        </div>
        <div class="toolbar">
          <div class="call-actions">
            <button @click="startCall">Démarrer l'appel</button>
            <button @click="endCall">Raccrocher</button>
          </div>
          <div class="annotation-tools">
            <button @click="toggleAnnotationMode">{{ annotationMode ? 'Finir annotation' : 'Annoter' }}</button>
            <div class="annotation-options" v-if="annotationMode">
              <input type="color" v-model="annotationColor">
              <input type="range" v-model="annotationSize" min="1" max="20">
              <button @click="clearAnnotations">Effacer</button>
            </div>
          </div>
          <div class="participant-actions">
            <button @click="toggleCameraControl" v-if="isSupportingCameraControl">
              {{ cameraControlActive ? 'Désactiver contrôle caméra' : 'Activer contrôle caméra' }}
            </button>
            <button @click="toggleMicrophone" v-if="isSupportingMicrophone">
              {{ microphoneActive ? 'Couper micro' : 'Activer micro' }}
            </button>
          </div>
        </div>
        <div class="use-cases">
          <h2>Cas d'utilisation</h2>
          <ul>
            <li>Assistance médicale lors d'opérations chirurgicales</li>
            <li>Premiers soins en urgence par les ambulanciers</li>
            <li>Assistance aux particuliers par les sapeurs-pompiers</li>
            <li>Maintenance à distance sur des sites industriels</li>
          </ul>
        </div>
      </div>
      <div v-else>
        <button @click="showCallWindow = true">Démarrer un appel vidéo</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'App',
    data() {
      return {
        showCallWindow: false,
        localStream: null,
        remoteStream: null,
        peerConnection: null,
        annotationMode: false,
        annotationColor: '#ff0000',
        annotationSize: 5,
        isSupportingCameraControl: true,
        cameraControlActive: false,
        isSupportingMicrophone: true,
        microphoneActive: true
      }
    },
    methods: {
      async startCall() {
        // Récupérer le flux vidéo local
        this.localStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        this.$refs.localVideo.srcObject = this.localStream;
  
        // Créer une connexion peer-to-peer
        this.peerConnection = new RTCPeerConnection();
        this.localStream.getTracks().forEach(track => {
          this.peerConnection.addTrack(track, this.localStream);
        });
  
        // Envoyer l'offre SDP
        const offer = await this.peerConnection.createOffer();
        await this.peerConnection.setLocalDescription(offer);
  
        // Recevoir le flux distant
        this.peerConnection.ontrack = event => {
          this.$refs.remoteVideo.srcObject = event.streams[0];
          this.remoteStream = event.streams[0];
        };
      },
      async endCall() {
        // Fermer la connexion
        this.peerConnection.close();
        this.localStream.getTracks().forEach(track => track.stop());
        this.remoteStream.getTracks().forEach(track => track.stop());
        this.showCallWindow = false;
      },
      toggleAnnotationMode() {
        this.annotationMode = !this.annotationMode;
      },
      clearAnnotations() {
        // Effacer les annotations
      },
      toggleCameraControl() {
        this.cameraControlActive = !this.cameraControlActive;
        // Activer/désactiver le contrôle de la caméra distante
      },
      toggleMicrophone() {
        this.microphoneActive = !this.microphoneActive;
        // Activer/couper le micro local
      }
    }
  }
  </script>
  
  <style>
  /* Styles similaires à l'exemple précédent */
  </style>