<template>
    			<!-- Home Banner -->
			<section class="section section-search-eight">
				<div class="container">
					<div class="banner-wrapper-eight m-auto text-center">
						<div class="banner-header aos" data-aos="fade-up">
							<h1>Search Teacher in <span>Mentoring</span> Appointment</h1>
							<p>Discover the best Mentors & institutions the city nearest to you.</p>
						</div>
                         
						<!-- Search -->
						<div class="search-box-eight aos" data-aos="fade-up">
							<form action="/mentee/mentor-search">
								<div class="form-search">
									<div class="form-inner">
										<div class="form-group search-location-eight">
											<i class="material-icons">my_location</i>
											<select class="form-control select">
												<option>Location</option>
												<option>Japan</option>
												<option>France</option>
											</select>
										</div>
										<div class="form-group search-info-eight">
											<i class="material-icons">location_city</i>
											<input type="text" class="form-control" placeholder="Search School, Online educational centers, etc">
										</div>
										<button type="submit" class="btn search-btn-eight mt-0">Search <i class="fas fa-long-arrow-alt-right"></i></button>
									</div>
								</div>
							</form>
						</div>
						<!-- /Search -->
						
					</div>
				</div>
			</section>
			<!-- /Home Banner -->
    </template>