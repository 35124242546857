<template>
    <!-- <LoadingSpinner v-if="isLoading" /> -->
    <div class="loading-overlay" v-if="isLoading" ref="admin">
        <div class="loading-spinner">
        <div class="spinner"></div>
        <p>Chargement...</p>
        </div>
    </div>
    <div class="main-wrapper" v-else>
        <layout-headeradmin></layout-headeradmin>
        <layout-sidebar></layout-sidebar>
        <div class="page-wrapper">

            <div class="content container-fluid">

                <!-- Page Header -->
                <div class="page-header">
                    <div class="row">
                        <div class="col-sm-12">
                            <h3 class="page-title">Bonjour  <span style="color: #1c5fc6;">{{ prenom }} </span> </h3>
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item active">Tableau de bord</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- /Page Header -->

                <div class="row">
                    <div class="col-xl-3 col-sm-6 col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="dash-widget-header">
                                    <span class="dash-widget-icon text-primary border-primary">
                                        <i class="fa fa-users fa-fade"></i>
                                    </span>
                                    <div class="dash-count">
                                        <h3>1</h3>
                                    </div>
                                </div>
                                <div class="dash-widget-info">
                                    <h6 class="text-muted">Professionnels</h6>
                                    <div class="progress progress-sm">
                                        <div class="progress-bar bg-primary w-50"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="dash-widget-header">
                                    <span class="dash-widget-icon text-success border-success">
                                        <!-- <i class="fa fa-building-circle-arrow-right fa-beat-fade"></i> -->
                                        <i class="fa fa-hospital-symbol fa-beat-fade"></i>
                                    </span>
                                    <div class="dash-count">
                                        <h3>1</h3>
                                    </div>
                                </div>
                                <div class="dash-widget-info">

                                    <h6 class="text-muted">Centres Affiliés</h6>
                                    <div class="progress progress-sm">
                                        <div class="progress-bar bg-success w-50"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="dash-widget-header">
                                    <span class="dash-widget-icon text-danger border-danger">
                                        <i class="fa fa-people-roof fa-flip"></i>
                                    </span>
                                    <div class="dash-count">
                                        <h3>0</h3>
                                    </div>
                                </div>
                                <div class="dash-widget-info">

                                    <h6 class="text-muted">Salon(s) créé(s) aujourd'hui</h6>
                                    <div class="progress progress-sm">
                                        <div class="progress-bar bg-danger w-50"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="dash-widget-header">
                                    <span class="dash-widget-icon text-warning border-warning">
                                        <i class="fa fa-people-line fa-bounce"></i>
                                    </span>
                                    <div class="dash-count">
                                        <h3>0</h3>
                                    </div>
                                </div>
                                <div class="dash-widget-info">

                                    <h6 class="text-muted">Connecté(s)</h6>
                                    <div class="progress progress-sm">
                                        <div class="progress-bar bg-warning w-50"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 d-flex">

                        <!-- Recent Orders -->
                        <div class="card card-table flex-fill">
                            <div class="card-header">
                                <h4 class="card-title">Professionnel(s) connecté(s)</h4>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-hover table-center mb-0">
                                        <thead>
                                            <tr>
                                                <th>Identité</th>
                                                <th>Spécialité</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in mentorlist" :key="item.id">
                                                <td>
                                                    <h2 class="table-avatar">
                                                        <router-link to="/admin/profile" class="avatar avatar-sm me-2"><img
                                                                class="avatar-img rounded-circle"
                                                                :src="require(`@/assets/img/admin/profiles/${item.image}`)"
                                                                alt="User Image"></router-link>
                                                        <router-link to="/admin/profile">{{ item.name }}</router-link>
                                                    </h2>
                                                </td>
                                                <td>{{ item.course }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <!-- /Recent Orders -->

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
   
<script>
import mentorlist from '@/assets/json/admin/AdminIndex/mentorlist.json'
import menteelist from '@/assets/json/admin/AdminIndex/menteelist.json'
import bookinglist from '@/assets/json/admin/AdminIndex/bookinglist.json'
// import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { computed, onMounted } from 'vue';
import { useStore } from "vuex";

export default {
    // components: {
    //     LoadingSpinner
    // },
    data() {
        return {
            mentorlist: mentorlist,
            menteelist: menteelist,
            bookinglist: bookinglist,
            isLoading: true
        }
    },
    mounted() {
        // Définir isLoading à false après 3 secondes
        setTimeout(() => {
            this.isLoading = false;
        }, 300);
    },
    watch: {
        $route(to, from) {
            // Définir isLoading à false lorsque la route change
            this.isLoading = false;
        }
    },
    setup() {
        const store = useStore();
        const prenom = computed(() => store.getters.prenom);

        onMounted(() => {
            
            $(document).on('click', '#toggle_btn', function () {
                if ($('body').hasClass('mini-sidebar')) {
                    $('body').removeClass('mini-sidebar');
                    $('.subdrop + ul').slideDown();
                } else {
                    $('body').addClass('mini-sidebar');
                    $('.subdrop + ul').slideUp();
                }
                return false;
            });
            $(document).on('mouseover', function (e) {
                e.stopPropagation();
                if ($('body').hasClass('mini-sidebar') && $('#toggle_btn').is(':visible')) {
                    var targ = $(e.target).closest('.sidebar').length;
                    if (targ) {
                        $('body').addClass('expand-menu');
                        $('.subdrop + ul').slideDown();
                    } else {
                        $('body').removeClass('expand-menu');
                        $('.subdrop + ul').slideUp();
                    }
                    return false;
                }
            });
        })


        return {
            prenom
        }
    },
}   
</script>
<style scoped>
.fa-users {
    font-size: 20px;
}

.fa-credit-card {
    font-size: 20px;
}

.fa-money {
    font-size: 20px;
}

.fa-folder {
    font-size: 20px;
}

.fa-star {
    font-size: 20px;
}

.menteee {
    font-size: 12px;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>