<template>
  <!-- Share Your Knowledge -->
  <section class="share-knowledge-three p-0">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 d-flex align-items-center">
          <div class="knowledge-img-three aos" data-aos="fade-up">
            <img src="@/assets/img/choose-mentor.png" class="img-fluid" alt="" />
          </div>
        </div>
        <div class="col-lg-7 d-flex align-items-center justify-content-center">
          <div class="join-us-all aos" data-aos="fade-up">
            <div class="join-us-heading">
              <span>WHY CHOOSE MENTORING</span>
              <h2>Experts in local & international Mentors From Industries</h2>
              <p>
                Agent hen an unknown printer took a galley of type and scramble
                it to make a type specimen book. It has survived not only five
                centuries, but also the leap into electronic.
              </p>
            </div>
            <div class="join-two-face">
              <ul>
                <li>
                  <img src="@/assets/img/icon/tick-icon.svg" alt="" />Outstanding
                  Mentos
                </li>
                <li>
                  <img src="@/assets/img/icon/tick-icon.svg" alt="" />More than
                  200+ Locations
                </li>
                <li>
                  <img src="@/assets/img/icon/tick-icon.svg" alt="" />Specialised
                  Courses
                </li>
                <li>
                  <img
                    src="@/assets/img/icon/tick-icon.svg"
                    alt=""
                  />Market-leading research
                </li>
              </ul>
              <div class="explore-more">
                <router-link to="/mentee/mentor-search" class="btn bg-explore"
                  >Read More<i class="fas fa-arrow-right"></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Share Your Knowledge -->
</template>
