<template>
    <div class="map-page">
        <div class="main-wrapper">
            <layout-header />
			<div class="content">
				<div class="container-fluid">
					<div class="row">
						<div class="col-xl-12 col-lg-12 order-md-last order-sm-last order-last">
							
                            <video ref="localVideo" autoplay></video>
                            <video ref="remoteVideo" autoplay></video>
                            <a-scene embedded arjs>
                                <a-entity camera></a-entity>
                            </a-scene>
                        </div>
                    </div>
                </div>
            </div>
            <layout-footer />
        </div>
    </div>
</template>
  
<script>
import io from 'socket.io-client';
import Peer from 'peerjs';

const socket = io('http://192.168.1.69:3000');

export default {
  props: ['callerId'],
  data() {
    return {
      peer: null,
      localStream: null
    };
  },
  mounted() {
    this.initCall();
  },
  methods: {
    initCall() {
      navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
        this.localStream = stream;
        this.$refs.localVideo.srcObject = stream;

        this.peer = new Peer({
          host: '192.168.1.69',
          port: 3000,
          path: '/peerjs'
        });

        this.peer.on('open', id => {
          console.log('Peer connection open with ID:', id);
          if (!this.callerId) {
            this.callerId = id;
            socket.emit('initiateCall', { callerId: id });
          } else {
            socket.emit('answerCall', { callerId: this.callerId, responderId: id });
          }
        });

        this.peer.on('call', call => {
          console.log('Receiving call:', call);
          call.answer(this.localStream);
          call.on('stream', remoteStream => {
            console.log('Received remote stream');
            this.$refs.remoteVideo.srcObject = remoteStream;
          });
        });

        socket.on('initiateCall', data => {
          console.log('initiateCall event received:', data);
          if (data.callerId !== this.callerId) {
            const call = this.peer.call(data.callerId, this.localStream);
            call.on('stream', remoteStream => {
              console.log('Received remote stream');
              this.$refs.remoteVideo.srcObject = remoteStream;
            });
          }
        });

        socket.on('answerCall', data => {
          console.log('answerCall event received:', data);
          const call = this.peer.call(data.responderId, this.localStream);
          call.on('stream', remoteStream => {
            console.log('Received remote stream');
            this.$refs.remoteVideo.srcObject = remoteStream;
          });
        });
      }).catch(error => {
        console.error('Error accessing media devices:', error);
      });
    }
  }
};
</script>

<style scoped>
  video {
    width: 85%;
    margin: 5%;
  }
  </style>
  