<template>
  <!-- Profile Section -->
  <section class="section profile-section">
    <div class="section-header-eight text-center aos" data-aos="fade-up">
      <div class="container">
        <span>MOST VIEWED</span>
        <h2>Featured Profile of this week</h2>
        <p class="sub-title">
          Are you looking to join online institutions? Now it's very simple,
          Sign up with mentoring
        </p>
        <div class="sec-dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <!-- Profile Item -->
        <div class="col-12 col-xl-3 col-lg-6 aos" data-aos="fade-up">
          <div class="profile-list">
            <div class="profile-detail">
              <div class="profile-img-eight">
                <img
                  class="img-fluid"
                  alt=""
                  src="@/assets/img/mendor/mendor-01.jpg"
                />
              </div>
              <div class="profile-content">
                <h4>Donna Yancey</h4>
                <p>UNIX, Trigonometry</p>
              </div>
            </div>
            <div class="profile-rating">
              <div class="mendor-rating">
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star"></i>
              </div>
              <div class="price-box">$500 <span>/ hr</span></div>
            </div>
          </div>
        </div>
        <!-- /Profile Item -->

        <!-- Profile Item -->
        <div class="col-12 col-xl-3 col-lg-6 aos" data-aos="fade-up">
          <div class="profile-list">
            <div class="profile-detail">
              <div class="profile-img-eight">
                <img
                  class="img-fluid"
                  alt=""
                  src="@/assets/img/mendor/mendor-04.jpg"
                />
              </div>
              <div class="profile-content">
                <h4>Betty Hairston</h4>
                <p>Computer Programming</p>
              </div>
            </div>
            <div class="profile-rating">
              <div class="mendor-rating">
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star"></i>
              </div>
              <div class="price-box">$200 <span>/ hr</span></div>
            </div>
          </div>
        </div>
        <!-- /Profile Item -->

        <!-- Profile Item -->
        <div class="col-12 col-xl-3 col-lg-6 aos" data-aos="fade-up">
          <div class="profile-list">
            <div class="profile-detail">
              <div class="profile-img-eight">
                <img
                  class="img-fluid"
                  alt=""
                  src="@/assets/img/mendor/mendor-03.jpg"
                />
              </div>
              <div class="profile-content">
                <h4>Jose Anderson</h4>
                <p>ASP,Computer Gaming</p>
              </div>
            </div>
            <div class="profile-rating">
              <div class="mendor-rating">
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star"></i>
              </div>
              <div class="price-box">$300 <span>/ hr</span></div>
            </div>
          </div>
        </div>
        <!-- /Profile Item -->

        <!-- Profile Item -->
        <div class="col-12 col-xl-3 col-lg-6 aos" data-aos="fade-up">
          <div class="profile-list">
            <div class="profile-detail">
              <div class="profile-img-eight">
                <img
                  class="img-fluid"
                  alt=""
                  src="@/assets/img/mendor/mendor-02.jpg"
                />
              </div>
              <div class="profile-content">
                <h4>James Amen</h4>
                <p>Digital Marketer</p>
              </div>
            </div>
            <div class="profile-rating">
              <div class="mendor-rating">
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star"></i>
              </div>
              <div class="price-box">$400 <span>/ hr</span></div>
            </div>
          </div>
        </div>
        <!-- /Profile Item -->
      </div>
    </div>
  </section>
  <!-- /Profile Section -->
</template>
