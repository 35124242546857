<template>
  <!-- New Course join -->
  <section class="new-course new-course-three">
    <div class="container">
      <div class="become-mentor">
        <div class="row">
          <div class="col-md-12">
            <div class="every-new-course aos" data-aos="fade-up">
              <div class="section-header">
                <h2>Become a Popular Mentor</h2>
                <p>We only work with the best Courses around the globe.</p>
              </div>
              <div class="mentor-btns">
                <div class="log-btn">
                  <router-link to="/pages/login"
                    >Start Login<img
                      src="@/assets/img/icon/login-circle.svg"
                      alt=""
                      class="ms-2"
                  /></router-link>
                </div>
                <div class="register-btn">
                  <router-link to="/pages/register"
                    >Register Now<i class="fas fa-user ms-2"></i
                  ></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- New Course -->
</template>
