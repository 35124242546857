<template>
	<div class="main-wrapper">
		<layout-headeradmin></layout-headeradmin>
		<layout-sidebar></layout-sidebar>
		<!-- Page Wrapper -->
		<div class="page-wrapper">
			<div class="content container-fluid">
				<div class="page-header">
					<div class="row">
						<div class="col-sm-6">
							<h3 class="page-title">Settings</h3>
							<ul class="breadcrumb">
								<li class="breadcrumb-item"><router-link to="/admin/index">Dashboard</router-link></li>
								<li class="breadcrumb-item"><router-link to="/admin/settings">Settings</router-link></li>
								<li class="breadcrumb-item active">Social Links</li>
							</ul>
						</div>
					</div>
				</div>
				<!-- Settings Menu -->
				<settings></settings>
				<!-- Settings Menu -->

				<div class="row">
					<div class="col-lg-6 col-md-8">
						<div class="card">
							<div class="card-header">
								<h5 class="card-title">Social Link Settings</h5>
							</div>
							<div class="card-body pt-0">

								<form>
									<div class="settings-form">
										<div class="links-info" v-for="item in event" :key="item.id">
											<div class="row form-row links-cont">
												<div class="col-12 col-md-11">
													<div class="form-group form-placeholder d-flex">
														<button class="btn social-icon">
															<i :class="item.icon"></i>
														</button>
														<input type="text" class="form-control"
														:placeholder="item.placeholder">
													</div>
												</div>
												<div class="col-12 col-md-1">
													<a href="javascript:;" class="btn trash" @click="deleteEvent(event)">
														<i class="feather-trash-2"></i>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div class="form-group">
										<a href="javascript:void(0);" class="btn add-links" @click="add()">
											<i class="fa fa-plus me-1"></i> Add More
										</a>
									</div>
									<div class="form-group mb-0">
										<div class="settings-btns">
											<button type="submit" class="btn btn-orange me-1">Submit</button>
											<button type="submit" class="btn btn-grey">Cancel</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<!-- /Settings -->

			</div>
		</div>
		<!-- /Page Wrapper -->
	</div>
</template>

<script>
const event = [
	{
		id: 1,
		icon: "feather-facebook",
		placeholder: "https://www.facebook.com",
	},
	{
		id: 2,
		icon: "feather-twitter",
		placeholder: "https://www.twitter.com",
	},
	{
		id: 3,
		icon: "feather-youtube",
		placeholder: "https://www.youtube.com",
	},
	{
		id: 4,
		icon: "feather-linkedin",
		placeholder: "https://www.linkedin.com",
	},
];
export default {
	data() {
		return {
			title: "Dashboard",
			text: "Settings",
			event,
		};
	},
	methods: {
		deleteEvent: function (event) {
			this.event.splice(this.event.indexOf(event), 1);
		},
		add() {
			this.event.push({
				id: 5,
				icon: "feather-github",
				placeholder: "Social Link",
			});
		},
	},
};
</script>