<template>
    <div class="main-wrapper">
        <layout-headeradmin></layout-headeradmin>
        <layout-sidebar></layout-sidebar>
       <!-- Page Wrapper -->
       <div class="page-wrapper">
        <div class="content container-fluid">
        
            <!-- Page Header -->
            <div class="page-header">
                <div class="row">
                    <div class="col-sm-6">
                        <h3 class="page-title">Affiliations</h3>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/admin/index">Tableau de Bord</router-link></li>
                            <li class="breadcrumb-item active">Affiliations</li>
                        </ul>
                    </div>
                    <div class="col-sm-6">
                        <div class="card invoices-tabs-card">
                            <div class="card-body card-body pt-0 pb-0">
                                <div class="invoices-main-tabs border-0 pb-0">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="invoices-settings-btn invoices-settings-btn-one">
                                            <a href="#" class="btn" @click.prevent="toggleAffiliationForm">
                                                <i :class="showForm ? 'feather-x-circle' : 'feather-plus-circle'" style="font-size: 20px"></i>
                                                &nbsp;&nbsp;<span>{{ buttonText }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /Page Header -->
            <div class="row" v-if="successMessage">
                <div class="col-sm-12">
                    <div class="alert alert-success">{{ successMessage }}</div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <transition name="fade">
                        <div class="card affiliation-form"  v-if="showForm">
                            <div class="card-header">
                                <h5 class="modal-title">Affilier un centre</h5>
                                <p class="card-text">
                                    Réaliser l'affiliation avec d'autres centres 
                                </p>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                        <div class="col-sm">
                                            <form class="needs-validation" novalidate @submit.prevent="affiliateCenter">
                                                <div class="row form-row">
                                                    <div class="form-group row">
                                                        <label class="col-form-label col-md-2">Centres</label>
                                                        <div class="col-md-10 mb-3">
                                                            <input type="text" class="form-control" placeholder="Rechercher un centre" v-model="searchTerm" @input="filterCenters"  />
                                                            <select class="form-control mt-2" v-model="selectedCenter" >
                                                                <option v-for="center in filteredCenters" :key="center.id" :value="center.id" ref="centerSelect" @focus="openSelect" @blur="closeSelect">{{ center.nom }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button class="btn btn-primary" type="submit">Valider</button>
                                            </form>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </transition>
                    <div class="card">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="datatable table table-hover table-center mb-0">
                                    <thead>
                                        <tr>
                                            <th>Centres</th>
                                            <th>Date d'affiliation</th>
                                            <th class="text-center">Statut</th>
                                            <th class="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="affiliation in affiliations" :key="affiliation.affiliationId">
                                            <td>{{ affiliation.affiliatedCentre.nom }}</td>
                                            <td>{{ affiliation.date_affiliation }}</td>
                                            <td class="text-center">
                                                <span :class="{'badge badge-pill inv-badge': true, 'bg-success': affiliation.statut === 1, 'bg-primary': affiliation.statut === 0}" >
                                                    {{ affiliation.statut === 1 ? 'Affilié' : 'En attente' }}
                                                </span>
                                            </td>
                                            <td class="text-center">
                                                <span class="text-center">
                                                    <form @submit.prevent="submitUpdate(affiliation)">
                                                        <div class="status-toggle" v-if="affiliation.demandeur != centreid">
                                                            <input type="hidden" :value="affiliation.affiliationId" name="affiliationId">
                                                            <button type="submit" class="btn btn-primary">Valider</button>
                                                        </div>
                                                    </form>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>			
            </div>
        </div>			
    </div>
    <!-- /Page Wrapper -->
        
        </div>
        <!-- Edit Details Modal -->
		<div class="modal fade" id="edit_invoice_report" aria-hidden="true" role="dialog">
			<div class="modal-dialog modal-dialog-centered" role="document" >
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Affilier un centre</h5>
						<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<form>
							<button type="submit" class="btn btn-primary btn-block w-100">Valider</button>
						</form>
					</div>
				</div>
			</div>
		</div>
		<!-- /Edit Details Modal -->

		<!-- Delete Modal -->
		<div class="modal fade" id="delete_modal" aria-hidden="true" role="dialog">
			<div class="modal-dialog modal-dialog-centered" role="document" >
				<div class="modal-content">
					<div class="modal-body">
						<div class="form-content p-2">
							<h4 class="modal-title">Delete</h4>
							<p class="mb-4">Are you sure want to delete?</p>
							<button type="button" class="btn btn-primary me-1">Delete </button>
							<button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- /Delete Modal -->
</template>
<script>
    import { useStore } from 'vuex';
	import { computed, onMounted } from 'vue';
    import transactionlist from '@/assets/json/admin/Transaction/transactionlist.json'
    import $ from 'jquery';
    import axios from 'axios'
    import 'select2/dist/css/select2.css';
    import 'select2';
	import { API_BASE_URL } from '@/config';
    
    export default {
        setup() {
			const store = useStore();

            const id = computed(() => store.getters.id);
            const prenom = computed(() => store.getters.prenom);
            const nom = computed(() => store.getters.nom);
            const photo = computed(() => store.getters.photo);
            const specialiteid = computed(() => store.getters.specialiteid);
            const specialitenom = computed(() => store.getters.specialitenom);
            const centreid = computed(() => store.getters.centreid);
            const centrenom = computed(() => store.getters.centrenom);
            const genre = computed(() => store.getters.genre);
            const telephone = computed(() => store.getters.telephone);
            const date_naissance = computed(() => store.getters.date_naissance);
            
            onMounted(() => {
                // Datatable
                if ($('.datatable').length > 0) {
                    $('.datatable').DataTable({
                    "bFilter": false,
                    });
                }
            });

            return {
				id,
				prenom,
				nom,
				photo,
				specialiteid,
				centreid,
				specialitenom,
				centrenom,
				genre,
				telephone,
				date_naissance,
			};
        },
        data() {
            return {
                showForm: false,
                centers: [],
                affiliations: [],
                filteredCenters: [],
                selectedCenter: null,
                searchTerm: '',
                successMessage: '',
                buttonText: 'Nouveau'
            }
        },
        methods: {
            toggleAffiliationForm() {
                this.showForm = !this.showForm;
                this.updateButtonState();
            },
            updateButtonState() {
                this.buttonText = this.showForm ? 'Fermer' : 'Nouveau';
            },
            fetchCenters() {
                axios.get(`${API_BASE_URL}/centres`)
                .then(response => {
                    this.centers = response.data.centres;
                    this.filteredCenters = this.centers;
                })
                .catch(error => {
                    console.error(error)
                })
            },
            filterCenters() {
                const searchTermLower = this.searchTerm.toLowerCase();
                this.filteredCenters = this.centers.filter(center => center.nom.toLowerCase().includes(searchTermLower));
                if (this.filteredCenters.length > 0) {
                    this.selectedCenter = this.filteredCenters[0].id;
                } else {
                    this.selectedCenter = null;
                }
            },
            openSelect() {
                this.$refs.centerSelect.size = 5;
            },
            closeSelect() {
                this.$refs.centerSelect.size = 1;
            },
            affiliateCenter() {
                const data = {
                    centre_1_id: this.centerid, 
                    centre_2_id: this.selectedCenter.id,
                    date_affiliation: new Date().toISOString() 
                };

                axios.post(`${API_BASE_URL}/affiliations`, data)
                    .then(response => {
                    this.successMessage = response.data.message
                    this.selectedCenter = null
                    this.searchTerm = '';
                    this.showForm = false;
                    this.fetchCenters(); // Rafraîchir la liste des centres
                })
                .catch(error => {
                    console.error(error)
                })
            },
            fetchAffiliations() {
                axios.get(`${API_BASE_URL}/affiliations/${this.centreid}`)
                    .then(response => {
                    this.affiliations = response.data.affiliations
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération des affiliations:', error);
                });
            },
            async submitUpdate(affiliation) {
                try {
                    const newStatut =  1; // Inverse le statut actuel
                    await axios.put(`${API_BASE_URL}/affiliations/${affiliation.affiliationId}/update-status`, { statut: newStatut });
                    this.fetchAffiliations(); // Rafraîchir les affiliations après la mise à jour du statut
                } catch (error) {
                    console.error('Erreur lors de la mise à jour du statut:', error);
                }
            },
        },
        watch: {
            affiliations(newAffiliations) {
                this.$nextTick(() => {
                    if ($('.datatable').length > 0) {
                        $('.datatable').DataTable({
                            destroy: true,
                            data: newAffiliations,
                            columns: [
                                { data: 'affiliatedCentre.nom' },
                                { data: 'date_affiliation' },
                                {
                                    data: 'statut',
                                    className: 'text-center',
                                    render: data => {
                                        const badgeClass = data === 1 ? 'bg-success' : 'bg-primary';
                                        const badgeText = data === 1 ? 'Affilié' : 'En attente';
                                        return `<span class="badge badge-pill inv-badge ${badgeClass}">${badgeText}</span>`;
                                    }
                                },
                                {
                                    data: null,
                                    className: 'text-center',
                                    render: (data, type, row) => {
                                        if (row.demandeur != this.centreid) {
                                            return row.statut === 0 ? 
                                                `<form @submit.prevent="submitUpdate(row)">
                                                    <div class="status-toggle">
                                                        <input type="hidden" :value="row.affiliationId" name="row.affiliationId">
                                                        <button type="submit" class="btn btn-primary">Valider</button>
                                                    </div>
                                                </form>` : '';
                                        }
                                        return '';
                                    }
                                }
                            ],
                            "bFilter": false,
                            language: {
                                sProcessing: "Traitement en cours...",
                                sSearch: "Rechercher&nbsp;:",
                                sLengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
                                sInfo: "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
                                sInfoEmpty: "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
                                sInfoFiltered: "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
                                sInfoPostFix: "",
                                sLoadingRecords: "Chargement en cours...",
                                sZeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
                                sEmptyTable: "Aucune donnée disponible dans le tableau",
                                oPaginate: {
                                    sFirst: "Premier",
                                    sPrevious: "Pr&eacute;c&eacute;dent",
                                    sNext: "Suivant",
                                    sLast: "Dernier"
                                },
                                oAria: {
                                    sSortAscending: ": activer pour trier la colonne par ordre croissant",
                                    sSortDescending: ": activer pour trier la colonne par ordre d&eacute;croissant"
                                }
                            }
                        });
                    }
                });
            }
        },
        mounted() {
            this.fetchCenters();
            this.fetchAffiliations();
        },
    }
</script>
<style scoped>
    .fa-edit {
        font-size: 12px;
    }
    .fa-trash {
        font-size: 12px;
    }
    .feather {
        margin-right: 5px;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    }

    .fade-enter {
        opacity: 0;
        transform: translateY(-10px);
        transition-delay: 0.2s; /* Delay before the transition starts */
    }

    .fade-enter-to {
        opacity: 1;
        transform: translateY(0);
    }

    .fade-leave-active {
        transition-delay: 0s; /* No delay for the leave transition */
    }

    .fade-leave-to {
        opacity: 0;
        transition: opacity 0.5s ease-in;
        transform: translateY(-10px);
    }
</style>