<template>
    <div class="map-page">
        <div class="main-wrapper">
            <layout-header1></layout-header1>
            <!-- Page Content -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-7 col-lg-12 order-md-last order-sm-last order-last map-left">
                            <div class="row align-items-center mb-4">
                                <div class="col-md-6 col">
                                    <h4> Centres trouvés</h4>
                                </div>
                                <div class="col-md-6 col-auto">
                                    <div class="view-icons">
                                        <router-link to="/particulier/accueil" class="grid-view"><i class="fas fa-th-large"></i></router-link>
                                        <router-link to="/particulier/listecentre" class="list-view active"><i class="fas fa-bars"></i></router-link>
                                    </div>
                                    <div class="sort-by d-sm-block d-none">
                                        <span class="sortby-fliter">
                                            <vue-select :options="options" />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <!-- Centre Widget -->
                            <div class="card" v-for="centre in displayedCentres" :key="centre.id">
                                <div class="card-body">
                                    <div class="mentor-widget">
                                        <div class="user-info-left">
                                            <div class="mentor-img">
                                                <router-link :to="'/particulier/profil/' + centre.id">
                                                    <img :src="`http://localhost:3000/uploads/logos/${ centre.logo }`" class="img-fluid" alt="Centre Image">
                                                </router-link>
                                            </div>
                                            <div class="user-info-cont">
                                                <h4 class="usr-name"><router-link :to="'/particulier/profil/' + centre.id">{{ centre.nom }}</router-link></h4>
                                                <p class="mentor-type">{{ centre.typecentre }}</p>
                                                <div class="mentor-details">
                                                    <!-- <p class="user-location"><i class="fas fa-map-marker-alt"></i> {{ centre.ville }}, {{ centre.pays }}</p> -->
                                                    <p class="user-address"><i class="fas fa-map-marker-alt"></i> {{ centre.adresse }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="user-info-right">
                                            <div class="user-infos">
                                                <ul>
                                                    <li><i class="fas fa-globe"></i> {{ centre.ville }}, {{ centre.pays }}</li>
                                                    <!-- <li><i class="far fa-money-bill-alt"></i> Contactez pour les tarifs</li> -->
                                                </ul>
                                            </div>
                                            <div class="mentor-booking">
                                                <router-link class="apt-btn" :to="'/booking/' + centre.id">Appeler</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /Centre Widget -->

                            <div class="load-more text-center" v-if="displayedCentres.length < centres.length">
                                <button class="btn btn-primary btn-sm" @click="loadMore">Voir plus</button>
                            </div>
                        </div>
                        <!-- /content-left-->
                        <div class="col-xl-5 col-lg-12 map-right">
                            <div id="map" class="map-listing"></div>
                            <!-- map-->
                        </div>
                        <!-- /map-right-->
                    </div>
                    <!-- /row-->
                </div>
            </div>
            <!-- /Page Content -->
            <layout-footer></layout-footer>
        </div>
    </div>
</template>

<script>
import L from 'leaflet';
let infowindow = null;
let map, current = 0;
const icons = {
  'default': require('@/assets/img/website/marker.png')
};

export default {
    data() {
        return {
            centres: [],
            displayedCentres: [],
            itemsToShow: 4, // Number of items to show initially and per "Voir plus" click
            slider: null,
            bounds: new google.maps.LatLngBounds(),
            lat: '53.470692',
            lng: '-2.220328',
            options: ["Select", "Rating", "Popular", "Latest", "Free"]
        }
    },
    mounted() {
        this.showmap();
        this.getCentre();
    },
    methods: {
        async getCentre() {
            const res = await fetch("http://localhost:3000/centres/urgence");
            const finalRes = await res.json();
            this.centres = finalRes.centres;
            this.loadMore(); // Load initial set of centres
            this.setMarkers(map, finalRes.centres);
        },
        loadMore() {
            const start = this.displayedCentres.length;
            const end = start + this.itemsToShow;
            this.displayedCentres = this.displayedCentres.concat(this.centres.slice(start, end));
        },
        setMarkers(map, centres) {
            centres.forEach(centre => {
                const marker = new google.maps.Marker({
                    position: { lat: parseFloat(centre.latitude), lng: parseFloat(centre.longitude) },
                    map,
                    title: centre.nom
                });
                const infowindow = new google.maps.InfoWindow({
                    content: `
                        <div>
                            <h5>${centre.nom}</h5>
                            <p>${centre.typecentre}</p>
                            <p>${centre.adresse}</p>
                            <p>${centre.ville}, ${centre.pays}</p>
                        </div>
                    `
                });
                marker.addListener('click', () => {
                    infowindow.open(map, marker);
                });
                this.bounds.extend(marker.position);
            });
            map.fitBounds(this.bounds);
        },
        showmap() {
            const myLatlng = new google.maps.LatLng(this.lat, this.lng);
            const mapOptions = {
                zoom: 5,
                center: myLatlng,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                styles: [{ featureType: 'poi', stylers: [{ visibility: 'off' }] }]
            }
            map = new google.maps.Map(document.getElementById('map'), mapOptions);
            infowindow = new google.maps.InfoWindow();
        },
    },
}
</script>

<style>
.fluids {
    height: 250px !important;
}
</style>
