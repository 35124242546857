<template>
  <div class="account-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="bg-pattern-style bg-pattern-style-register">
        <div class="content">
          <!-- Register Content -->
          <div class="account-content">
            <div class="account-box">
              <router-link to="/" class="navbar-brand logo">
                <img style="margin-top: 20px; margin-bottom: 20px; width: 200px"
                src="@/assets/img/website/teleassistance.png"
                class=""
                alt="Logo"
                />
              </router-link>
              <div class="login-right">
                <div class="login-header">
                  <h3><span>Inscription</span> Teleassistance</h3>
                  <p class="text-muted">inscrivez-vous pour continuer</p>
                </div>

                <!-- Register Form -->
                <form @submit.prevent="register">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label">Nom</label>
                        <input
                          id="first-name"
                          type="text"
                          class="form-control"
                          v-model="form.nom" 
                          required
                          autofocus=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label">Prénom</label>
                        <input
                          id="last-name"
                          type="text"
                          class="form-control"
                          v-model="form.prenom"
                          required
                          name="last_name"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-control-label">Email</label>
                    <input id="email" type="email" class="form-control" v-model="form.email" required />
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label">Specialité</label>
                        <select class="form-control" v-model="form.specialite_id">
                          <option v-for="specialite in specialites" :key="specialite.id" :value="specialite.id">{{ specialite.nom }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label">Centre de santé</label>
                        <select class="form-control" v-model="form.centre_id">
                          <option v-for="centre in centres" :key="centre.id" :value="centre.id">{{ centre.nom }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label" >Mot de passe</label>
                        <input
                          id="password"
                          type="password"
                          class="form-control"
                          v-model="form.password" required
                          name="password"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label"
                          >Confirmation mot de passe</label
                        >
                        <input
                          id="password-confirm"
                          type="password"
                          class="form-control"
                          v-model="form.confirmPassword"
                          name="password_confirmation"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-check form-check-xs custom-checkbox">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="agreeCheckboxUser"
                        id="agree_checkbox_user"
                      />
                      <label class="form-check-label" for="agree_checkbox_user"
                        >J’accepte les conditions </label
                      >
                      <a tabindex="-1" href="javascript:void(0);"
                        > de la politique de confidentialité</a
                      >
                      &amp;
                      <a tabindex="-1" href="javascript:void(0);"> de Teleassistance.</a>
                    </div>
                  </div>
                  <button
                    class="btn btn-primary login-btn"
                    type="submit"
                  >
                    Créer un compte
                  </button>
                  <div class="account-footer text-center mt-3">
                    Vous avez déjà un compte ?
                    <router-link class="forgot-link mb-0" to="/pages/login"
                      >Connectez-vous</router-link
                    >
                  </div>
                </form>
                <!-- /Register Form -->
              </div>
            </div>
          </div>
          <!-- /Register Content -->
        </div>
      </div>
      <!-- /Page Content -->
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>

<script>
import { API_BASE_URL } from '@/config';
import { useRouter } from 'vue-router';

export default {
  data() {
    return {
      form: {
        nom: '',
        prenom: '',
        email: '',
        password: '',
        confirmPassword: '',
        type_utilisateur: 'specialiste',
        specialite_id: null,
        centre_id: null,
        date_naissance: '',
        genre: 'masculin',
        telephone: '',
        photo: null
      },
      specialites: [],
      centres: []
    };
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  created() {
    this.fetchSpecialites();
    this.fetchCentres();
  },
  methods: {
    handleFileUpload(event) {
      this.form.photo = event.target.files[0];
    },
    async fetchSpecialites() {
      try {
        const response = await fetch(`${API_BASE_URL}/specialites`);
        const data = await response.json();
        this.specialites = data.specialites        ;
        console.log(this.specialites);
        
      } catch (error) {
        console.error('Erreur lors de la récupération des spécialités:', error);
      }
    },
    async fetchCentres() {
      try {
        const response = await fetch(`${API_BASE_URL}/centres`);
        const data = await response.json();
        this.centres = data.centres;
      } catch (error) {
        console.error('Erreur lors de la récupération des centres:', error);
      }
    },
    async register() {
      if (this.form.password !== this.form.confirmPassword) {
        alert("Les mots de passe ne correspondent pas.");
        return;
      }

      const formData = new FormData();
      for (const key in this.form) {
        formData.append(key, this.form[key]);
      }

      try {
        const response = await fetch(`${API_BASE_URL}/register`, {
          method: 'POST',
          body: formData
        });
        const data = await response.json();
        if (response.status === 201) {
          this.router.push('/pages/login'); // Rediriger vers la page de connexion
        } else {
          alert("Erreur: " + data.message);
        }
      } catch (error) {
        console.error("Erreur lors de l'inscription:", error);
      }
    }
  }
};
</script>
