<template>
  <div class="main-wrapper">
    <layout-header1></layout-header1>
    <breadcrumb1></breadcrumb1>
    <!-- Page Content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <!-- Sidebar -->
          <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
            <div class="stickysidebar">
              <sidebar></sidebar>
            </div>
          </div>
          <!-- /Sidebar -->

          <!-- Booking summary -->
          <div class="col-md-7 col-lg-8 col-xl-9">
            <h3 class="pb-3">
              <router-link
                to="/mentee/mentor-profile"
                class="avatar avatar-sm me-2"
                ><img
                  class="avatar-img rounded-circle"
                  :src="getCentreImageUrl(centre.logo)"
                  alt="User Image"
              /></router-link>
              <router-link to="/mentee/mentor-profile"
                >{{ centre.nom}}</router-link
              >
            </h3>
            <!-- Mentee List Tab -->
            <div class="tab-pane show active" id="mentee-list">
              <div class="card card-table">
                <div class="card-body">
                  <div v-if="isLoading">Chargement...</div>
                  <div v-else-if="error">{{ error }}</div>
                  <div class="table-responsive" v-else>
                    <table class="table table-hover table-center mb-0" >
                      <thead>
                        <tr>
                          <th>Professionnels</th>
                          <th>Centres</th>
                          <th class="text-center">Statut</th>
                          <th class="text-center">ACTION</th>
                        </tr>
                      </thead>
                      <tbody v-if="items.length">
                        <tr v-for="item in items" :key="item.id">
                          <td>
                            <h2 class="table-avatar">
                              <router-link
                                to="/mentee/mentor-profile"
                                class="avatar avatar-sm me-2"
                                ><img
                                  class="avatar-img rounded-circle"
                                  :src="getProfileImageUrl(item.photo)"
                                  alt="User Image"
                              /></router-link>
                              <router-link to="/mentee/mentor-profile"
                                >{{ item.nom
                                }}<span>{{ item.email }}</span></router-link
                              >
                            </h2>
                          </td>
                          <td>{{ item.email }}</td>
                          <td class="text-center">
                            <span class="pending">
                              En ligne
                            </span>
                          </td>
                          <td class="text-center">
                            <router-link
                              :to="{ name: 'appel-video', params: { callerId: userId, calleeId: item.id }}"
                              class="btn btn-sm bg-info-light my-2"
                              ><i class="fas fa-phone-alt"></i> </router-link
                            >
                            <router-link to="/mentor/chat" class="btn btn-sm bg-info-light">
                              <i class="fas fa-envelope"></i>
                            </router-link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Mentee List Tab -->
          </div>
          <!-- /Booking summary -->
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <layout-footer></layout-footer>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { API_BASE_URL } from '@/config';

export default {
  setup() {
    const store = useStore();

    const userId = computed(() => store.getters.id);
    const centreid = computed(() => store.getters.centreid);
    
    return {
      userId,
      centreid,
    };
  },
  data() {
    return {
      centre: [],
      items: [],
      isLoading: true,
      error: null,
      defaultProfileImage: require('@/assets/img/user/1.png')
    };
  },
  created() {
    this.fetchData();
    this.getCentre();
    console.log(this.$route.params.id);
  },
  methods: {
    getProfileImageUrl(profileImage) {
      return `${API_BASE_URL}/uploads/photos/${profileImage}` || this.defaultProfileImage;
    },
    getCentreImageUrl(profileImage) {
      return `${API_BASE_URL}/uploads/logos/${profileImage}` || this.defaultProfileImage;
    },
    async getCentre() {
      try {
        const response = await fetch(`${API_BASE_URL}/centres/${this.$route.params.id}`);
        if (!response.ok) {
          throw new Error('La réponse du réseau n’était pas correcte');
        }
        const data = await response.json();
        this.centre = data.centre;
        console.log(this.centre);
      } catch (error) {
        console.error('Il y a eu un problème avec l’opération de récupération :', error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchData() {
      try {
        const response = await fetch(`${API_BASE_URL}/users/par-centre/${this.$route.params.id}`);
        if (!response.ok) {
          throw new Error('La réponse du réseau n’était pas correcte');
        }
        const data = await response.json();
        this.items = data;
        console.log(this.items);
      } catch (error) {
        console.error('Il y a eu un problème avec l’opération de récupération :', error);
      } finally {
        this.isLoading = false;
      }
    }
  },
};
</script>
