import { createRouter, createWebHistory } from 'vue-router';
import store from '../store'; // Importer le store Vuex
/** For  Website**/
import Index from '../views/pages/website/index/Index'
import Index2 from '../views/pages/website/index-2/Index2'
import Index3 from '../views/pages/website/index-3/Index3'
import Index4 from '../views/pages/website/index-4/Index4'
import Index5 from '../views/pages/website/index-5/Index5'
import Index6 from '../views/pages/website/index-6/Index6'
import Index7 from '../views/pages/website/index-7/Index7'
import Index8 from '../views/pages/website/index-8/Index8'
import newvideo from '../views/pages/website/pages/Newvideo'
import MentorDashboard from '../views/pages/website/mentor/Index'
import BookingSummary from '../views/pages/website/mentor/BookingSummary'
import ScheduleTimings from '../views/pages/website/mentor/ScheduleTimings'
import MentorChat from '../views/pages/website/mentor/App'
import MenteeList from '../views/pages/website/mentor/MenteeList'
import MenteeProfile from '../views/pages/website/mentor/MenteeProfile'
import MentorBlog from '../views/pages/website/mentor/Blog'
import MentorBlogDetails from '../views/pages/website/mentor/BlogDetails'
import MentorAddBlog from '../views/pages/website/mentor/AddBlog'
import MentorEditBlog from '../views/pages/website/mentor/EditBlog'
import Invoices from '../views/pages/website/mentor/Invoices'
import InvoiceView from '../views/pages/website/mentor/InvoiceView'
import MentorProfileSettings from '../views/pages/website/mentor/ProfileSettings'
import Reviews from '../views/pages/website/mentor/Reviews'
import MentorRegister from '../views/pages/website/mentor/Register'
import SearchMentor from '../views/pages/website/mentee/SearchMentor'
import MentorProfile from '../views/pages/website/mentee/MentorProfile'
import Bookings from '../views/pages/website/mentee/Bookings'
import Checkout from '../views/pages/website/mentee/Checkout'
import BookingSuccess from '../views/pages/website/mentee/BookingSuccess'
import MenteeDashboard from '../views/pages/website/mentee/Index'
import Favourites from '../views/pages/website/mentee/Favourites'
import MenteeChat from '../views/pages/website/mentee/Chat'
import MenteeProfileSettings from '../views/pages/website/mentee/ProfileSettings'
import ChangePassword from '../views/pages/website/mentee/ChangePassword'
import MapGrid from '../views/pages/website/mentee/MapGrid'
import MapList from '../views/pages/website/mentee/MapList'
import Call from '../views/pages/website/mentee/Call.vue';
import CallInterface from '../views/pages/website/index/CallInterface.vue';
import IncomingCalls from '../views/pages/website/index/IncomingCalls.vue';
import MenteeRegister from '../views/pages/website/mentee/Register'
import Bloglist from '../views/pages/website/blog/BlogList'
import Bloggrid from '../views/pages/website/blog/BlogGrid'
import VoiceCall from '../views/pages/website/pages/Voicecall'
import VideoCall from '../views/pages/website/pages/Videocall'
import MyVideoCall from '../views/pages/website/pages/video-call'
import ReceiveCall from '../views/pages/website/pages/Receivecall'
import Component from '../views/pages/website/pages/Components'
import WebsiteBlankpage from '../views/pages/website/pages/BlankPage'
import WebSiteLogin from '../views/pages/website/pages/Login'
import RegisterPage from '../views/pages/website/pages/Register'
import WebsiteForgotPassword from '../views/pages/website/pages/ForgotPassword'
import Booking from '../views/pages/website/Booking'
import Appointments from '../views/pages/website/mentor/Appointments'

/**
 * Code ajouté par Geordel
 * Partie concernant website
 */
import Professionnels from '../views/pages/website/mentor/Professionnels'
import CentreAssistanceList from '../views/pages/website/mentor/CentreAssistanceList'
import ProfessionnelList from '../views/pages/website/mentor/ProfessionnelList'
import Meet from '../views/pages/website/pages/Meet'

/** For  Admin**/
import Indexadmin from '../views/pages/admin/index'
import Mentor from '../views/pages/admin/mentor'
import Mentee from '../views/pages/admin/mentee'
import Bookinglist from '../views/pages/admin/bookinglist'
import Categories from '../views/pages/admin/categories'
import Affiliationlist from '../views/pages/admin/add-affiliation'
import Transactionlist from '../views/pages/admin/transactionslist'
import Userlist from '../views/pages/admin/user-list'
import Settings from '../views/pages/admin/settings'
import Invoicereport from '../views/pages/admin/invoicereport'
import Profile from '../views/pages/admin/profile'
import Blog from '../views/pages/admin/blog'
import Blogdetails from '../views/pages/admin/blogdetails'
import Addblog from '../views/pages/admin/addblog'
import Editblog from '../views/pages/admin/editblog'
import Login from '../views/pages/admin/login'
import Register from '../views/pages/admin/register'
import Forgotpassword from '../views/pages/admin/forgotpassword'
import Lockscreen from '../views/pages/admin/lockscreen'
import Error404 from '../views/pages/admin/error404'
import Error500 from '../views/pages/admin/error500'
import Blankpage from '../views/pages/admin/blankpage'
import Components from '../views/pages/admin/components'
import Formbasicinputs from '../views/pages/admin/formbasicinputs'
import Forminputgroups from '../views/pages/admin/forminputgroups'
import Formhorizontal from '../views/pages/admin/formhorizontal'
import Formvertical from '../views/pages/admin/formvertical'
import Formmask from '../views/pages/admin/formmask'
import Formvalidation from '../views/pages/admin/formvalidation'
import Tablesbasic from '../views/pages/admin/tablesbasic'
import Datatables from '../views/pages/admin/datatables'
import Invoice from '../views/pages/admin/invoice'
import Localizationdetails from '../views/pages/admin/localization-details'
import Paymentsettings from '../views/pages/admin/payment-settings'
import Emailsettings from '../views/pages/admin/email-settings'
import Socialsettings from '../views/pages/admin/social-settings'
import Sociallinks from '../views/pages/admin/social-links'
import Seosettings from '../views/pages/admin/seo-settings'
import Otherssettings from '../views/pages/admin/others-settings'
import InvoicesReport from '../views/pages/admin/invoices'
import InvoiceGrid from '../views/pages/admin/invoice-grid'
import AddInvoice from '../views/pages/admin/add-invoice'
import EditInvoice from '../views/pages/admin/edit-invoice'
import ViewInvoice from '../views/pages/admin/view-invoice'
import InvoicesSettings from '../views/pages/admin/invoices-settings'
import InvoiceItems from '../views/pages/admin/invoice-items'
import InvoiceCategory from '../views/pages/admin/invoice-category'
import InvoiceDraft from '../views/pages/admin/invoices-draft'
import InvoiceCancelled from '../views/pages/admin/invoices-cancelled'
import InvoicePaid from '../views/pages/admin/invoices-paid'
import InvoiceRecurring from '../views/pages/admin/invoices-recurring'
import InvoiceOverdue from '../views/pages/admin/invoices-overdue'
import BankSettings from '../views/pages/admin/bank-settings'
import TaxSettings from '../views/pages/admin/tax-settings'

// Fonction pour vérifier si l'utilisateur est authentifié
const isAuthenticated = () => store.getters.isAuthenticated;

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/index',
    name: 'dashboard',
    component: Index
  },
  {
    path: '/index-2',
    name: 'index-2',
    component: Index2
  },
  {
    path: '/index-3',
    name: 'index-3',
    component: Index3
  },
  {
    path: '/index-4',
    name: 'index-4',
    component: Index4
  },
  {
    path: '/index-5',
    name: 'index-5',
    component: Index5
  },
  {
    path: '/index-6',
    name: 'index-6',
    component: Index6
  },
  {
    path: '/index-7',
    name: 'index-7',
    component: Index7
  },
  {
    path: '/index-8',
    name: 'index-8',
    component: Index8
  },
  {
    path: '/newvideo',
    name: 'newvideo',
    component: newvideo
  },
  {
    path: '/mentor/index',
    name: 'mentor-dashboard',
    component: MentorDashboard,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mentor/mentee-list',
    name: 'mentor-mentee-list',
    component: MenteeList,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mentor/mentee-profile',
    name: 'mentor-mentee-profile',
    component: MenteeProfile,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mentor/my-bookings',
    name: 'mentor-bookings',
    component: BookingSummary,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mentor/centres-assistance',
    name: 'centre-assistance',
    component: CentreAssistanceList,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mentor/professionnels/:id',
    name: 'centre-professionnels',
    component: ProfessionnelList,
    props: true,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mentor/schedule-timings',
    name: 'mentor-schedule-timings',
    component: ScheduleTimings,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mentor/chat',
    name: 'mentor-chat',
    component: MentorChat,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    }
  },
  {
    path: '/chat/:roomId',
    name: 'chat',
    component: MentorChat,
    props: route => ({ roomId: route.params.roomId }),
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    }
  },
  {
    path: '/mentor/blog',
    name: 'mentor-blog',
    component: MentorBlog
  },
  {
    path: '/mentor/blog-details',
    name: 'mentor-blog-details',
    component: MentorBlogDetails
  },
  {
    path: '/mentor/add-blog',
    name: 'add-blog',
    component: MentorAddBlog
  },
  {
    path: '/mentor/invoices',
    name: 'mentor-invoices',
    component: Invoices
  },
  {
    path: '/mentor/invoice-view',
    name: 'mentor-invoice-view',
    component: InvoiceView
  },
  {
    path: '/mentor/edit-blog',
    name: 'edit-blog',
    component: MentorEditBlog
  },
  {
    path: '/mentor/profile-settings',
    name: 'mentor-profile-settings',
    component: MentorProfileSettings,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mentor/reviews',
    name: 'mentor-reviews',
    component: Reviews
  },
  {
    path: '/mentor/register',
    name: 'mentor-register',
    component: MentorRegister
  },
  {
    path: '/mentee/mentor-search',
    name: 'mentor-search-results',
    component: SearchMentor,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/blog/blog-list',
    name: 'blog-list',
    component: Bloglist
  },
  {
    path: '/blog/blog-grid',
    name: 'blog-grid',
    component: Bloggrid
  },
  {
    path: '/pages/login',
    name: 'website-login',
    component: WebSiteLogin
  },
  {
    path: '/pages/voice-call',
    name: 'voice-call',
    component: VoiceCall,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pages/video-call',
    name: 'video-call',
    component: VideoCall,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Receivecall/:roomName',
    name: 'Receivecall',
    component: ReceiveCall,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  }
  ,
  {
    path: '/appeler/:callerId/:calleeId',
    name: 'appel-video',
    component: MyVideoCall,
  },
  {
    path: '/pages/components',
    name: 'page-components',
    component: Component
  },
  {
    path: '/pages/blank-page',
    name: 'blank-page',
    component: WebsiteBlankpage
  },

  {
    path: '/pages/register',
    name: 'register-page',
    component: RegisterPage
  },
  {
    path: '/pages/forgot-password',
    name: 'website-forgot-password',
    component: WebsiteForgotPassword
  },
  {
    path: '/booking',
    name: 'booking',
    component: Booking
  },
  {
    path: '/mentor/appointments',
    name: 'mentor-appointments',
    component: Appointments,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mentee/mentor-profile',
    name: 'mentee-mentor-profile',
    component: MentorProfile,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mentee/bookings',
    name: 'mentee-bookings',
    component: Bookings
  },
  {
    path: '/mentee/checkout',
    name: 'mentee-checkout',
    component: Checkout,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mentee/booking-success',
    name: 'booking-success',
    component: BookingSuccess,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mentee/index',
    name: 'mentee-dashboard',
    component: MenteeDashboard,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mentee/favourites',
    name: 'mentee-favourites',
    component: Favourites,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mentee/chat',
    name: 'mentee-chat',
    component: MenteeChat,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mentee/profile-settings',
    name: 'mentee-profile-settings',
    component: MenteeProfileSettings,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mentee/change-password',
    name: 'change-password',
    component: ChangePassword,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/calls',
    name: 'CallInterface',
    component: CallInterface,
  },
  {
    path: '/incoming-calls',
    name: 'IncomingCalls',
    component: IncomingCalls,
  },
  {
    path: '/particulier/accueil',
    name: 'map-grid',
    component: MapGrid,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/particulier/listecentre',
    name: 'map-list',
    component: MapList,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/particulier/call/:salonId',
    name: 'call',
    component: Call,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); 
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pages/videocall',
    name: 'call',
    component: VideoCall,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); 
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mentee/register',
    name: 'mentee-register',
    component: MenteeRegister,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/index',
    name: 'admin',
    component: Indexadmin,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/mentor',
    name: 'mentor',
    component: Mentor,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/mentee',
    name: 'mentee',
    component: Mentee,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/bookinglist',
    name: 'bookinglists',
    component: Bookinglist,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/categories',
    name: 'categoriess',
    component: Categories,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/centredappel',
    name: 'transactionlists',
    component: Transactionlist,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/affiliation',
    name: 'Affiliation',
    component: Affiliationlist,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/utilisateurs',
    name: 'UserList',
    component: Userlist,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/settings',
    name: 'settings',
    component: Settings,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/invoicereport',
    name: 'invoicereport',
    component: Invoicereport,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/profil',
    name: 'profile',
    component: Profile,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/blog',
    name: 'blog',
    component: Blog,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/blogdetails',
    name: 'blogdetails',
    component: Blogdetails,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/addblog',
    name: 'addblog',
    component: Addblog,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/editblog',
    name: 'editblog',
    component: Editblog,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/login',
    name: 'login',
    component: Login
  },
  {
    path: '/admin/register',
    name: 'register',
    component: Register
  },
  {
    path: '/admin/forgotpassword',
    name: 'forgotpassword',
    component: Forgotpassword
  },
  {
    path: '/admin/lockscreen',
    name: 'lockscreen',
    component: Lockscreen
  },
  {
    path: '/admin/error404',
    name: 'error404',
    component: Error404
  },
  {
    path: '/admin/error500',
    name: 'error500',
    component: Error500
  },
  {
    path: '/admin/blankpage',
    name: 'blankpage',
    component: Blankpage
  },
  {
    path: '/admin/components',
    name: 'components',
    component: Components
  },
  {
    path: '/admin/formbasicinputs',
    name: 'formbasicinputs',
    component: Formbasicinputs
  },
  {
    path: '/admin/forminputgroups',
    name: 'forminputgroups',
    component: Forminputgroups
  },
  {
    path: '/admin/formhorizontal',
    name: 'formhorizontal',
    component: Formhorizontal
  },
  {
    path: '/admin/formvertical',
    name: 'formvertical',
    component: Formvertical
  },
  {
    path: '/admin/formmask',
    name: 'formmask',
    component: Formmask
  },
  {
    path: '/admin/formvalidation',
    name: 'formvalidation',
    component: Formvalidation
  },
  {
    path: '/admin/tablesbasic',
    name: 'tablesbasic',
    component: Tablesbasic
  },
  {
    path: '/admin/datatables',
    name: 'datatables',
    component: Datatables
  },
  {
    path: '/admin/invoice',
    name: 'invoice',
    component: Invoice
  },
  {
    path: '/admin/localization-details',
    name: 'localization-details',
    component: Localizationdetails
  },
  {
    path: '/admin/payment-settings',
    name: 'payment-settings',
    component: Paymentsettings
  },
  {
    path: '/admin/email-settings',
    name: 'email-settings',
    component: Emailsettings
  },
  {
    path: '/admin/social-settings',
    name: 'social-settings',
    component: Socialsettings
  },
  {
    path: '/admin/social-links',
    name: 'social-links',
    component: Sociallinks
  },
  {
    path: '/admin/seo-settings',
    name: 'seo-settings',
    component: Seosettings
  },
  {
    path: '/admin/others-settings',
    name: 'others-settings',
    component: Otherssettings
  },
  {
    path: '/admin/invoices',
    name: 'invoices',
    component: InvoicesReport
  },
  {
    path: '/admin/invoice-grid',
    name: 'invoice-grid',
    component: InvoiceGrid
  },
  {
    path: '/admin/add-invoice',
    name: 'add-invoice',
    component: AddInvoice
  },
  {
    path: '/admin/edit-invoice',
    name: 'edit-invoice',
    component: EditInvoice
  },
  {
    path: '/admin/view-invoice',
    name: 'view-invoice',
    component: ViewInvoice
  },
  {
    path: '/admin/invoices-settings',
    name: 'invoices-settings',
    component: InvoicesSettings
  },
  {
    path: '/admin/invoice-items',
    name: 'invoice-items',
    component: InvoiceItems
  },
  {
    path: '/admin/invoice-category',
    name: 'invoice-category',
    component: InvoiceCategory
  },
  {
    path: '/admin/invoices-draft',
    name: 'invoices-draft',
    component: InvoiceDraft
  },
  {
    path: '/admin/invoices-cancelled',
    name: 'invoices-cancelled',
    component: InvoiceCancelled
  },
  {
    path: '/admin/invoices-paid',
    name: 'invoices-paid',
    component: InvoicePaid
  },
  {
    path: '/admin/invoices-recurring',
    name: 'invoices-recurring',
    component: InvoiceRecurring
  },
  {
    path: '/admin/invoices-overdue',
    name: 'invoices-overdue',
    component: InvoiceOverdue
  },
  {
    path: '/admin/bank-settings',
    name: 'bank-settings',
    component: BankSettings
  },
  {
    path: '/admin/tax-settings',
    name: 'tax-settings',
    component: TaxSettings
  },
  
  /**
   * Code ajouter par Geordel 
   */
  {
    path: '/professionnels',
    name: 'professionnels',
    component: Professionnels,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/meet',
    name: 'meet',
    component: Meet,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/pages/login'); // Rediriger vers la page de connexion si non authentifié
      }
    },
    meta: {
      requiresAuth: true
    }
  },
];

export const router = createRouter({
  history: createWebHistory('/'),
  linkActiveClass: 'active',
  routes,
});

router.beforeEach((to, from, next) => {
  // Scroll to the top of the page
  window.scrollTo({ top: 0, behavior: 'smooth' });
  // Vérifier si la route nécessite l'authentification
  if (to.matched.some(route => route.meta.requiresAuth) && !isAuthenticated()) {
    // Rediriger vers la page de connexion si l'utilisateur n'est pas authentifié
    next('/pages/login');
  } else {
    // Continuer avec la navigation
    next();
  }
});